import { ColumnProps } from '@components/Column'
import Modal, {
  InputWithButton,
  MainHeader,
  ModalButton,
  Option,
  OptionHeader,
  Toast
} from '@components/Modal'
import { useApp } from '@contexts/AppContextProvider'
import { useAuth } from '@contexts/AuthContext'
import { useSnoowrap } from '@contexts/SnoowrapContext'
import useDebounce from '@hooks/debounce'
import { useEffect, useState } from 'react'
import { Activity, BookOpen, Globe, Search, User } from 'react-feather'
import styled from 'styled-components'
import { v4 as uuidV4 } from 'uuid'

const SubredditSuggestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 8px;
`

const StyledModalButton = styled(ModalButton)`
  margin-top: 40px;
`

export enum ColumnType {
  Subreddit = 'Subreddit',
  Search = 'Search',
  User = 'User',
  All = 'All',
  Popular = 'Popular'
}

const getPlaceholder = (columnType: ColumnType) => {
  switch (columnType) {
    case ColumnType.Subreddit:
      return 'Subreddit'
    case ColumnType.Search:
      return 'Search Query'
    case ColumnType.User:
      return 'Username'
    case ColumnType.All:
    case ColumnType.Popular:
      return 'Subreddit'
  }
}

const getSecondaryPlaceholder = (columnType: ColumnType) => {
  switch (columnType) {
    case ColumnType.Subreddit:
      return 'i.e. pics'
    case ColumnType.Search:
      return 'i.e. giant pizzas'
    case ColumnType.User:
      return 'i.e. bob'
  }
}

const getDefaultSubreddit = (columnType: ColumnType) => {
  switch (columnType) {
    case ColumnType.All:
      return '/r/all'
    case ColumnType.Popular:
      return '/r/popular'
  }
  return ''
}

const getIsSubredditFieldDisabled = (columnType: ColumnType) => {
  switch (columnType) {
    case ColumnType.All:
    case ColumnType.Popular:
      return true
  }
  return false
}

const ColumnTypeGrid = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
`

const ColumnTypeItem = styled.div`
  background: ${props => props.theme.modal.modalButtonBackgroundColor};
  color: ${props => props.theme.global.textColor};
  border-radius: 8px;
  padding: 20px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6b6f74;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.modal.modalButtonHoverBackgroundColor};;
  }

  & > svg {
    margin-bottom: 16px;
  }
`

interface ColumnTypePicker {
  setColumnType: (type: ColumnType) => void
  setTextValue: (text: string) => void
}

function ColumnTypePicker({ setColumnType, setTextValue }: ColumnTypePicker) {
  const onClick = (type: ColumnType) => () => {
    setColumnType(type)
    setTextValue(getDefaultSubreddit(type))
  }

  return (
    <ColumnTypeGrid>
      <ColumnTypeItem onClick={onClick(ColumnType.All)}>
        <Globe color="#4981c2" size={64} />
        /r/all
      </ColumnTypeItem>
      <ColumnTypeItem onClick={onClick(ColumnType.Popular)}>
        <Activity color="#FF8b60" size={64} />
        /r/popular
      </ColumnTypeItem>
      <ColumnTypeItem onClick={onClick(ColumnType.Subreddit)}>
        <BookOpen color="#3BCB56" size={64} />
        subreddit
      </ColumnTypeItem>
      <ColumnTypeItem onClick={onClick(ColumnType.Search)}>
        <Search size={64} />
        search
      </ColumnTypeItem>
      <ColumnTypeItem onClick={onClick(ColumnType.User)}>
        <User color="#DEBE22" size={64} />
        user
      </ColumnTypeItem>
    </ColumnTypeGrid>
  )
}

function AddColumn() {
  const app = useApp()
  const snoowrap = useSnoowrap()
  const auth = useAuth()

  const onClickBackground = () => {
    app.showAddColumn(false)
  }

  const [columnType, setColumnType] = useState<ColumnType | null>()
  const [textValue, setTextValue] = useState('')
  const [columnTitle, setColumnTitle] = useState('')
  const [subreddits, setSubreddits] = useState<string[]>([])
  const [selectedSubreddits, setSelectedSubreddits] = useState<string[]>([])
  const debouncedText = useDebounce(textValue, 500)

  const resetState = () => {
    setColumnType(null)
    setTextValue('')
    setColumnTitle('')
    setSubreddits([])
    setSelectedSubreddits([])
  }

  const canAdd = (() => {
    switch (columnType) {
      case ColumnType.Subreddit:
        return selectedSubreddits.length
      case ColumnType.Search:
      case ColumnType.User:
      case ColumnType.Popular:
      case ColumnType.All:
        return textValue.length
    }
  })()

  const onAddColumn = async () => {
    if (!canAdd) return
    // create base column, every column needs an id
    const column: ColumnProps = {
      id: uuidV4(),
      customTitle: columnTitle.trim() !== '' ? columnTitle : undefined
    }
    switch (columnType) {
      case ColumnType.Subreddit:
        if (selectedSubreddits.length > 1) {
          auth.columnManager.add({ subreddits: selectedSubreddits, ...column })
        } else if (selectedSubreddits.length === 1) {
          if (
            window.confirm(
              `Would you like to subscribe to ${selectedSubreddits[0]} as well?`
            )
          ) {
            snoowrap
              .getClient()
              ?.getSubreddit(selectedSubreddits[0])
              .subscribe()
          }
          auth.columnManager.add({
            subreddit: selectedSubreddits[0],
            ...column
          })
        }
        break
      case ColumnType.Search:
        auth.columnManager.add({ searchQuery: textValue, ...column })
        break
      case ColumnType.User:
        auth.columnManager.add({ user: textValue, ...column })
        break
      case ColumnType.All:
      case ColumnType.Popular:
        auth.columnManager.add({
          subreddit: textValue,
          ...column
        })
    }
    app.showAddColumn(false)
    resetState()
  }

  const onClickSubreddit = (subreddit: string) => () => {
    if (selectedSubreddits.includes(subreddit)) {
      setSelectedSubreddits(
        selectedSubreddits.filter(
          selectedSubreddit => selectedSubreddit !== subreddit
        )
      )
    } else {
      setSelectedSubreddits([...selectedSubreddits, subreddit])
    }
  }

  useEffect(() => {
    if (columnType === ColumnType.Subreddit && app.isAddingColumn) {
      snoowrap
        .getClient()
        ?.searchSubredditNames({ query: debouncedText })
        .then(setSubreddits)
    }
  }, [app.isAddingColumn, debouncedText, columnType, snoowrap])

  if (!columnType) {
    return (
      <Modal onClick={onClickBackground} show={app.isAddingColumn}>
        <MainHeader>Add Column</MainHeader>
        <ColumnTypePicker
          setColumnType={setColumnType}
          setTextValue={setTextValue}
        />
      </Modal>
    )
  }

  return (
    <Modal onClick={onClickBackground} show={app.isAddingColumn}>
      <MainHeader>Add Column</MainHeader>
      <Option>
        <OptionHeader>{getPlaceholder(columnType)}</OptionHeader>
        <InputWithButton>
          <input
            value={textValue}
            onChange={e => setTextValue(e.target.value.replace('/r/', ''))}
            placeholder={getSecondaryPlaceholder(columnType)}
            disabled={getIsSubredditFieldDisabled(columnType)}
          />
        </InputWithButton>
      </Option>
      {columnType === ColumnType.Subreddit && (
        <SubredditSuggestionContainer>
          {subreddits.map(subreddit => (
            <Toast
              compact
              interactive
              active={selectedSubreddits.includes(subreddit)}
              onClick={onClickSubreddit(subreddit)}
            >
              {subreddit}
            </Toast>
          ))}
        </SubredditSuggestionContainer>
      )}
      <Option>
        <OptionHeader>Custom Title (Optional)</OptionHeader>
        <InputWithButton>
          <input
            value={columnTitle}
            onChange={e => setColumnTitle(e.target.value)}
            placeholder={textValue}
          />
        </InputWithButton>
      </Option>
      <StyledModalButton disabled={!canAdd} onClick={onAddColumn}>
        Add Column
      </StyledModalButton>
    </Modal>
  )
}

export default AddColumn
