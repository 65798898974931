interface Events {
   startLogin: string
   finishLogin: string
   loginFailed: string,
   startAddAccount: string
   finishAddAccount: string
   failedAddAccount: string
   hitRateLimit: string
   subbedToReditrFromNUX: string
}

const events: Events = {
    startLogin: 'QD4OPXNL',
    finishLogin: '6JRTKLCD',
    loginFailed: 'QTFSKAA9',
    startAddAccount: 'QNQPAJ3S',
    finishAddAccount: 'I9C2NN7H',
    failedAddAccount: '6QNPIYE9',
    hitRateLimit: 'Z7CMTQRO',
    subbedToReditrFromNUX: 'SLZSMMSR'
}

export default events