export function numberShortener(num: number, digits: number = 0): string {
  var si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ]
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  var i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

export function relativeDate(date: Date): string {
  const delta = Math.floor((Date.now() - date.getTime()) / 1000)
  if (delta < 60) return "just now"
  if (delta < 3600) return `${Math.floor(delta / 60)}m ago`
  if (delta < 86400) return `${Math.floor(delta / 3600)}h ago`
  if (delta < 31536000) return `${Math.floor(delta / 86400)}d ago`
  return `${Math.floor(delta / 31536000)}y ago`
}

export function replaceRedditLinksWithReditr(text: string): string {
  return text
    // strip reddit links
    .replace(
      /<a([^>]+)href=('|")(http|https):\/\/(www\.|np\.|old\.|beta\.|new\.|)reddit\.com/g,
      '<a$1href=$2'
    )
    // make external links open new windows and internal links use the app
    .replace(
      /<a([^>]+)href=(?<q>'|")([^"^']+)\k<q>/g,
      (_, space, quote, url) => {
        const external = url.startsWith('http')
        return (
          `<a${space}href=${quote}${url}${quote}` +
          (external
            ? ' target="_blank" rel="noopener noreferrer"'
            : 'target="_parent"')
        )
      }
    )
}