import { useAuth } from '@contexts/AuthContext'
import { createContext, useContext, useEffect, useState } from 'react'
import Snoowrap from 'snoowrap'
import { useBanner, BannerType } from './BannerContext'
import * as Fathom from 'fathom-client'
import events from '@analytics/events'

export enum RedditStatus {
  up,
  down
}

interface SnoowrapClientContext {
  getClient: () => Snoowrap | null
  setClient: (refreshKey: string) => Snoowrap | null
  clear: () => void
  status: RedditStatus
  setRedditStatus: (status: RedditStatus) => void
}

const SnoowrapContext = createContext<SnoowrapClientContext>(
  {} as SnoowrapClientContext
)

const guestDeviceId = Date.now() + ':rdr:' + Math.floor(Math.random() * 10000)
const appCredentials = {
  userAgent: 'web:reditr-web:dev-local',
  clientId: 'P3OV-qUrp57Z8A',
  clientSecret: 'oZbplkCqnRj2mxVAWcwFEniatl8'
}

function SnoowrapContextBuilder(refreshKey: string): SnoowrapClientContext {
  const [status, setStatus] = useState(RedditStatus.up)
  const [fetchedClient, setFetchedClient] = useState<Boolean>(false)
  const [client, setClient] = useState<Snoowrap | null>(null)
  const banner = useBanner()

  const setSnoowrapClient = (refreshToken: string) => {
    if (refreshToken === '') {
      Snoowrap.fromApplicationOnlyAuth({
        ...appCredentials,
        deviceId: guestDeviceId,
        permanent: false,
        grantType: 'https://oauth.reddit.com/grants/installed_client'
      } as any).then(setClient)
      return null
    } else {
      const newClient = new Snoowrap({
        refreshToken,
        ...appCredentials
      })
      newClient.config({ debug: true, warnings: true })
      setClient(newClient)
      return newClient
    }
  }

  if (!fetchedClient) {
    setSnoowrapClient(refreshKey)
    setFetchedClient(true)
  }

  const clear = () => {
    setClient(null)
  }

  const getClient = (): Snoowrap | null => {
    if (client && client.ratelimitRemaining === 0) {
      const now = Date.now()
      const difference = client.ratelimitExpiration - now
      banner.showBanner({
        message: `You've been rate limited. Try again in a ${Math.trunc(difference / 1000)} seconds.`,
        type: BannerType.error
      })
      Fathom.trackGoal(events.hitRateLimit, 0)
    }
    return client
  }

  const setRedditStatus = (status: RedditStatus) => {
    setStatus(status)
  }

  return {
    getClient,
    setClient: setSnoowrapClient,
    clear,
    status,
    setRedditStatus
  }
}

export function useSnoowrap(): SnoowrapClientContext {
  return useContext(SnoowrapContext)
}

export function SnoowrapProvider({ children }: ReactChildrenProps) {
  const auth = useAuth()
  const snoowrapContext = SnoowrapContextBuilder(auth.currentUser.refreshKey)

  useEffect(() => {
    const client = snoowrapContext.getClient()
    if (!client || auth.currentUser.redditAccount) return undefined
    // reddit health check
    client
      .getMe()
      .then(account => {
        auth.userManager.setRedditAccount(account)
        // snoowrapContext.setRedditStatus(RedditStatus.up)
      })
      .catch(() => {
        // snoowrapContext.setRedditStatus(RedditStatus.down)
      })
  }, [auth, snoowrapContext])
  return (
    <SnoowrapContext.Provider value={snoowrapContext}>
      {children}
    </SnoowrapContext.Provider>
  )
}
