import { Column } from '@components/Column'
import { useAuth } from '@contexts/AuthContext'
import GoToColumnModal from '@modals/GoToColumn'
import { useVirtualizer } from '@tanstack/react-virtual'
import { addHotkey } from '@utilities/hotkeys'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

const gutterSize = 15

const Container = styled.div`
  height: 100vh;
  overflow-y: hidden;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: ${gutterSize}px;
  right: 0;
  bottom: 0;
`

const GoToColumnButton = styled.button`
  position: fixed;
  bottom: 24px;
  left: calc(60px + 50%);
  transform: translate(-50%, -50%);
  opacity: 0.8;
  transition: opacity ease-in-out 0.2s;
  font-size: 14px;
  padding: 8px 24px;
  background: ${props => props.theme.global.inputBackgroundColor};
  border-radius: 20px;
  border: 1px solid ${props => props.theme.global.inputBorderColor};
  box-shadow: rgba(0, 0, 0, 0.07) 0px 4px 6px;
  color: ${props => props.theme.global.textColor};
  cursor: pointer;
  font-weight: 600;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 480px) {
    left: 50%;
  }
`

function Home() {
  const auth = useAuth()

  const [showGoToColumn, setShowGoToColumn] = useState(false)
  const [scrollToColumnIndex, setScrollToColumnIndex] = useState<number | null>(
    null
  )

  const parentRef = useRef<HTMLDivElement>(null)

  const columns = auth.currentUser.columns ?? []

  const columnVirtualizer = useVirtualizer({
    horizontal: true,
    count: columns.length,
    getScrollElement: () => parentRef.current,
    estimateSize: useCallback(
      () => gutterSize + auth.currentUser.settings.columnWidth,
      [auth.currentUser.settings.columnWidth]
    ),
    overscan: 2
  })

  useEffect(() => {
    addHotkey('cmd+j,ctrl+j', () => {
      setShowGoToColumn(true)
    })
  }, [])

  // if we're told to scroll to a cell, do it after we lay things out.
  useLayoutEffect(() => {
    // alert(scrollToColumnIndex)
    if (scrollToColumnIndex === undefined || scrollToColumnIndex === null)
      return
    columnVirtualizer.scrollToIndex(scrollToColumnIndex, { align: 'start' })
  }, [scrollToColumnIndex])

  return (
    <Container ref={parentRef}>
      <div
        style={{
          width: `${columnVirtualizer.getTotalSize()}px`,
          height: '100%',
          position: 'relative'
        }}
      >
        <Helmet>
          <title>Reditr</title>
        </Helmet>
        {columns &&
          columnVirtualizer.getVirtualItems().map(virtualColumn => {
            const columnProps = columns[virtualColumn.index]
            return (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: `${virtualColumn.size}px`,
                  transform: `translateX(${virtualColumn.start}px)`
                }}
                key={columnProps.id}
              >
                <Column {...columnProps} />
              </div>
            )
          })}
      </div>
      <GoToColumnModal
        show={showGoToColumn}
        onDismiss={() => setShowGoToColumn(false)}
        onSelectColumn={index => setScrollToColumnIndex(index)}
      />
      <GoToColumnButton onClick={() => setShowGoToColumn(true)}>
        Go to...
      </GoToColumnButton>
    </Container>
  )
}

export default Home
