const notificationsKey = ['notifications']
const notificationCountKey = ['notification-count']
const subredditsKey = ['subreddits']

const getSubredditInfoKey = (subreddit: string) => {
  return [
    subreddit ? subreddit.toLowerCase().replace(/^[\/|]r\//, '') : null,
    'info'
  ]
}

const getColumnSubmissionsKey = (title: string, sort: string) => {
  return [
    'column',
    title ? title.toLowerCase() : null,
    'submissions',
    sort ? sort.toLowerCase() : null
  ]
}

const getSubmissionKey = (id: string, sort: string | null = null) => {
  return ['submission', id.toLowerCase(), sort?.toLowerCase()]
}

const getCommentKey = (id: string) => {
  return ['comment', id.toLowerCase()]
}

export {
  notificationsKey,
  notificationCountKey,
  subredditsKey,
  getSubredditInfoKey,
  getColumnSubmissionsKey,
  getSubmissionKey,
  getCommentKey
}
