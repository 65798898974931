import events from '@analytics/events'
import OAuth from '@api/oauth'
import { ColumnProps } from '@components/Column'
import { DefaultAppSettings, useAuth } from '@contexts/AuthContext'
import { useSnoowrap } from '@contexts/SnoowrapContext'
import logo from '@images/logo.png'
import * as Fathom from 'fathom-client'
import { useEffect, useState } from 'react'
import { Check, Loader, Wind } from 'react-feather'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { RedditUser } from 'snoowrap'
import styled, { keyframes } from 'styled-components'
import { v4 as uuidV4 } from 'uuid'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #3f4245;
`

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  & > h1 {
    color: #ffffff;
    font-size: 72px;
    font-weight: bold;
  }
`

const Logo = styled.img`
  width: 240px;
  margin-right: 20px;
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const LoginButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  padding: 15px 30px;
  background-color: #e33e5c;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  color: #ffffff;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.15);
    transition: all 0.3s;
  }

  &:disabled {
    opacity: 0.65;
    cursor: not-allowed;

    & > svg {
      animation: ${rotate} 2s linear infinite;
    }
  }

  & > svg {
    font-weight: bold;
    font-size: 18px;
    margin-left: 6px;
  }
`

enum LoginState {
  start,
  loggingIn,
  success,
  failed
}

function Login() {
  const history = useHistory()
  const snoowrap = useSnoowrap()
  const auth = useAuth()

  const [loginStatus, setLoginStatus] = useState(LoginState.start)
  const [buttonText, setButtonText] = useState('Login with Reddit')

  // if already logged in you can't login again
  useEffect(() => {
    if (auth.userManager.isAuthed()) {
      history.push('/')
    }
  })

  const startOAuth = async () => {
    if (loginStatus !== LoginState.start && loginStatus !== LoginState.failed) {
      return
    }

    const oauthWindow = window.open('', 'oauthwindow', 'menubar=1,resizable=1')
    if (!oauthWindow) {
      return
    }

    Fathom.trackGoal(events.startLogin, 0)

    setLoginStatus(LoginState.loggingIn)
    setButtonText('Waiting...')
    const { refreshkey, name } = await OAuth.start(oauthWindow)
    if (refreshkey != null && name) {
      setLoginStatus(LoginState.success)
      setButtonText('Success!')

      Fathom.trackGoal(events.finishLogin, 0)

      // TODO: Move this to some app warmup helper
      const client = snoowrap.setClient(refreshkey)

      // we need to wait a second so that we don't get rate limited
      setTimeout(async () => {
        // get account subreddits to populate subreddits
        const subreddits = client!.getSubscriptions().fetchAll()
        const me = await new Promise(resolve => {
          client!
            .getUser(name)
            .fetch()
            .then(user => {
              resolve(user)
            })
        })

        const columns: ColumnProps[] = await subreddits.map(subreddit => {
          return { id: uuidV4(), subreddit: subreddit.display_name }
        })

        await auth.userManager.addUser({
          refreshKey: refreshkey,
          settings: DefaultAppSettings,
          columns,
          hasSeenNUX: false,
          redditAccount: me as RedditUser
        })

        // redirect
        history.push('/')
      }, 1000)
    } else {
      setLoginStatus(LoginState.failed)
      setButtonText('Failed to login. Try again.')
      Fathom.trackGoal(events.loginFailed, 0)
    }
  }

  const showRedditIcon =
    loginStatus === LoginState.start || loginStatus === LoginState.failed
  const showLoadingIcon = loginStatus === LoginState.loggingIn
  const showSuccessIcon = loginStatus === LoginState.success

  return (
    <Container>
      <Header>
        <Logo alt="Reditr Logo, represents an alien spaceship" src={logo} />
        <h1>Reditr</h1>
      </Header>
      <LoginButton disabled={showLoadingIcon} onClick={startOAuth}>
        <span>
          <Trans>{buttonText}</Trans>
        </span>
        {/* Find something else for this */}
        {showRedditIcon && <Wind size={16} />}
        {showLoadingIcon && <Loader size={16} />}
        {showSuccessIcon && <Check size={16} />}
      </LoginButton>
    </Container>
  )
}

export default Login
