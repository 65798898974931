import styled from 'styled-components'
import { Trans } from 'react-i18next'

import { RedditStatus, useSnoowrap } from '@contexts/SnoowrapContext'

interface RedditBannerProps {
  show: boolean
}

const RedditDownBanner = styled.div<RedditBannerProps>`
  display: ${props => (props.show ? 'block' : 'none')};
  background-color: red;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  padding: 16px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-weight: bold;
  z-index: 9999;
`

export default function RedditBanner() {
  const snoowrap = useSnoowrap()
  return (
    <RedditDownBanner show={snoowrap.status === RedditStatus.down}>
      <Trans>
        Reddit is down or experiencing issues. Keep refreshing it'll help.
      </Trans>
    </RedditDownBanner>
  )
}
