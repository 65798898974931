import { VirtualList, VirtualListHandle } from '@components/VirtualList'
import { useSnoowrap } from '@contexts/SnoowrapContext'
import { notificationCountKey, notificationsKey } from '@queries/index'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useRef } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router-dom'
import { Comment, Listing, PrivateMessage } from 'snoowrap'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  right: 0;
  background-color: ${props => props.theme.global.backgroundColor};
  padding: 64px 16px;
  box-sizing: border-box;
  overflow: auto;
  z-index: 999999;
`

const Content = styled.div`
  width: clamp(40%, 90%, 1000px);
  height: 100%;
  overflow: hidden;
  background: ${props => props.theme.notificationCenter.backgroundColor};
  border: 1px solid ${props => props.theme.notificationCenter.borderColor};
  color: ${props => props.theme.notificationCenter.textColor};
  border-radius: 6px;
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
`

const Header = styled.h1`
  border-bottom: 1px solid
    ${props => props.theme.notificationCenter.borderColor};
  margin: 0;
  padding: 16px;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);
`

const StyledVirtualList = styled(VirtualList)`
  margin: 0;
  overflow: auto;
  overflow-x: hidden;
`

const NotificationRow = styled.div`
  display: flex;
  border-bottom: 1px solid
    ${props => props.theme.notificationCenter.borderColor};
  padding: 8px;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  align-items: center;

  &:hover {
    background-color: ${props =>
      props.theme.notificationCenter.rowHighlightColor};
  }
`

const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

interface UnreadMarkerProps {
  active: boolean
}

const UnreadMarker = styled.div<UnreadMarkerProps>`
  background-color: #2d87e2;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  opacity: ${props => (props.active ? 1 : 0)};
  margin: 0 16px 0 8px;
  flex-shrink: 0;
`

const Subject = styled.div`
  font-weight: bold;
`

const Body = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

function LoadingRow() {
  return (
    <NotificationRow>
      <UnreadMarker active={false} />
      <NotificationContent>
        <Subject>
          <Skeleton width="100px" />
        </Subject>
        <Body>
          <Skeleton width="300px" />
        </Body>
      </NotificationContent>
    </NotificationRow>
  )
}

function NotificationCenter() {
  const snoowrap = useSnoowrap()
  const history = useHistory()
  const queryClient = useQueryClient()

  let listing: Listing<PrivateMessage | Comment> | undefined
  const { isLoading, fetchNextPage } = useInfiniteQuery(
    notificationsKey,
    ({ pageParam }) => {
      // paginate if we already have a listing
      if (pageParam && listing) {
        return listing.fetchMore({ amount: 25 })
      } else {
        return snoowrap.getClient()!.getInbox()
      }
    },
    {
      getNextPageParam: lastPage => {
        // set listing with new submissions fetched
        listing = lastPage
        return !lastPage.isFinished
      },
      keepPreviousData: true,
      enabled: snoowrap.getClient() !== null,
      refetchOnWindowFocus: true,
      structuralSharing: false
    }
  )

  const onClickNotification =
    (notification: PrivateMessage | Comment) => () => {
      let pm = notification as PrivateMessage
      if (pm.name.indexOf('t4_') > -1) {
        history.push(`message/${pm.id}`)
      } else {
        history.push(pm.context)
      }
      snoowrap
        .getClient()!
        .markMessagesAsRead([pm])
        .then(() => {
          listing = undefined
          queryClient.invalidateQueries(notificationCountKey)
          queryClient.invalidateQueries(notificationsKey)
        })
    }

  const virtualRef = useRef<VirtualListHandle>(null)

  if (isLoading) {
    return (
      <Container>
        <Content>
          <Header>Inbox</Header>
          {Array(15).fill(0).map(LoadingRow)}
        </Content>
      </Container>
    )
  }
  return (
    <Container>
      <Content>
        <Header>Inbox</Header>
        {listing && (
          <StyledVirtualList
            ref={virtualRef}
            rows={listing}
            hasMore={!listing.isFinished}
            loadMore={() => {
              fetchNextPage()
            }}
          >
            {({ virtualRow }) => {
              const notification = listing![
                virtualRow.index
              ].toJSON() as PrivateMessage

              const isPrivateMessage = notification.name.indexOf('t4_') > -1
              const subject = isPrivateMessage
                ? notification.subject
                : `${notification.author} on ${notification.subreddit_name_prefixed}`

              return (
                <NotificationRow onClick={onClickNotification(notification)}>
                  <UnreadMarker active={notification.new} />
                  <NotificationContent>
                    <Subject>{subject}</Subject>
                    <Body>{notification.body}</Body>
                  </NotificationContent>
                </NotificationRow>
              )
            }}
          </StyledVirtualList>
        )}
      </Content>
    </Container>
  )
}

export default NotificationCenter
