import Modal, {
  InputWithButton,
  MainHeader,
  ModalButton,
  Option,
  OptionHeader
} from '@components/Modal'
import OptionPicker from '@components/OptionPicker'
import { useApp } from '@contexts/AppContextProvider'
import { BannerType, useBanner } from '@contexts/BannerContext'
import { useSnoowrap } from '@contexts/SnoowrapContext'
import { useQueryParams } from '@hooks/useQueryParams'
import imgur from 'imgur'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import TextareaAutosize from 'react-textarea-autosize'
import { SubmitLinkOptions, SubmitSelfPostOptions } from 'snoowrap'
import styled from 'styled-components'

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const StyledTextarea = styled(TextareaAutosize)`
  border: none;
  width: 100%;
  resize: none;
  outline: none;
  font-size: 13px;
  padding: 10px;
  box-sizing: border-box;
`

const PostButton = styled(ModalButton)`
  margin-top: 36px;
`

enum PostType {
  selfText,
  link,
  image
}

function CreatePost() {
  const imgurClient = new imgur({
    clientId: process.env.REACT_APP_IMGUR_CLIENT_ID
    // clientSecret: process.env.REACT_APP_IMGUR_CLIENT_SECRET,
    // refreshToken: process.env.REACT_APP_IMGUR_CLIENT_REFRESH_TOKEN,
    // accessToken: process.env.REACT_APP_IMGUR_CLIENT_ACCESS_TOKEN
  })
  const app = useApp()
  const queryParams = useQueryParams()
  const snoowrap = useSnoowrap()
  const banner = useBanner()
  const history = useHistory()

  const [postType, setPostType] = useState(PostType.selfText)

  const [formData, setFormData] = useState<
    SubmitSelfPostOptions | SubmitLinkOptions
  >({} as SubmitSelfPostOptions)

  const updateForm = (form: any) => {
    setFormData({
      ...formData,
      ...form
    })
  }

  const handleChange = (e: any) => {
    updateForm({ [e.target.name]: e.target.value })
  }

  // deep link example (not really needed but nice for future).
  // useful for testing at least
  useEffect(() => {
    if (queryParams.get('action') === 'create-post') {
      app.showCreatePost(true)

      if (!!queryParams.get('type')) {
        switch (queryParams.get('type')) {
          case 'feedback':
            updateForm({ title: '[Feedback]', subredditName: 'reditr' })
            break
          case 'bug':
            updateForm({ title: '[Bug]', subredditName: 'reditr' })
            break
          default:
            break
        }
      }
    }
  }, [app, queryParams])

  const onClickBackground = (e: any) => {
    if (e.target.id === 'background') {
      // if modal was triggered by deep link, we need to clear it
      history.push({
        search: ''
      })

      // need to fire this after history is cleared
      setTimeout(() => {
        app.showCreatePost(false)
        setFormData({} as SubmitSelfPostOptions)
      }, 0)
    }
  }

  const imageInputRef = useRef<HTMLInputElement>(null)

  const onSubmit = async (e: any) => {
    e.preventDefault()

    const { title, subredditName } = formData as SubmitSelfPostOptions
    const { url } = formData as SubmitLinkOptions
    if (title && subredditName && postType === PostType.selfText) {
      snoowrap
        .getClient()!
        .submitSelfpost(formData)
        .then(submission => submission.fetch())
        .then(submission => {
          banner.showBanner({
            message: 'Submitted a post!',
            type: BannerType.success
          })
          app.showCreatePost(false)
          setFormData({} as SubmitSelfPostOptions)
          history.push(submission.permalink)
        })
    } else if (title && subredditName && postType === PostType.image) {
      const uploadFile = async (file: File) => {
        const upload = await imgurClient.upload({
          image: file as any
        })
        // figure out error state for this
        return upload.data.deletehash!
      }

      const files = imageInputRef.current!.files!
      const uploadHashes = await Promise.all(Array.from(files).map(uploadFile))

      if (uploadHashes && uploadHashes.length === files.length) {
        // create an album with the image
        const album = await imgurClient.createAlbum({
          deletehashes: uploadHashes
        })

        let imageLinkPost = {
          ...formData,
          url: `https://imgur.com/a/${album.data.id}`
        }

        snoowrap
          .getClient()!
          .submitLink(imageLinkPost as SubmitLinkOptions)
          .then(submission => submission.fetch())
          .then(submission => {
            banner.showBanner({
              message: 'Submitted a post!',
              type: BannerType.success
            })
            app.showCreatePost(false)
            setFormData({} as SubmitSelfPostOptions)
            history.push(submission.permalink)
          })
      }
    } else if (title && subredditName && url && postType === PostType.link) {
      snoowrap
        .getClient()!
        .submitLink(formData as SubmitLinkOptions)
        .then(submission => submission.fetch())
        .then(submission => {
          banner.showBanner({
            message: 'Submitted a post!',
            type: BannerType.success
          })
          app.showCreatePost(false)
          setFormData({} as SubmitSelfPostOptions)
          history.push(submission.permalink)
        })
    }
  }

  return (
    <Modal
      show={app.isShowingCreatePost}
      id="background"
      onClick={onClickBackground}
    >
      <MainHeader>Create Post</MainHeader>

      <Form onSubmit={onSubmit}>
        <Option>
          <OptionHeader>Type</OptionHeader>
          <OptionPicker
            options={[
              { text: 'Text', id: PostType.selfText },
              { text: 'Link', id: PostType.link },
              { text: 'Image', id: PostType.image }
            ]}
            selectedId={postType}
            onChange={(changed, _) => setPostType(changed)}
          />
        </Option>

        <Option>
          <OptionHeader>Subreddit</OptionHeader>
          <InputWithButton>
            <input
              name="subredditName"
              onChange={handleChange}
              placeholder="pics"
              value={formData.subredditName}
            />
          </InputWithButton>
        </Option>

        <Option>
          <OptionHeader>Title</OptionHeader>
          <InputWithButton>
            <input
              name="title"
              onChange={handleChange}
              placeholder="Write something clever here..."
              value={formData.title}
            />
          </InputWithButton>
        </Option>

        {postType === PostType.image && (
          <Option>
            <OptionHeader>Image</OptionHeader>
            <InputWithButton>
              <input
                type="file"
                accept="image/*"
                multiple
                ref={imageInputRef}
              />
            </InputWithButton>
          </Option>
        )}

        {postType === PostType.link && (
          <Option>
            <OptionHeader>Link</OptionHeader>
            <InputWithButton>
              <input name="url" onChange={handleChange} placeholder="URL" />
            </InputWithButton>
          </Option>
        )}

        {postType === PostType.selfText && (
          <Option>
            <OptionHeader>Text</OptionHeader>
            <StyledTextarea
              name="text"
              onChange={handleChange}
              placeholder="Submission Content"
            />
          </Option>
        )}

        <PostButton type="submit">Post</PostButton>
      </Form>
    </Modal>
  )
}

export default CreatePost
