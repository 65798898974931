import searchReddit from '@api/searchReddit'
import useDebounce from '@hooks/debounce'
import Skeleton from 'react-loading-skeleton'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { useSessionStorage } from 'react-use'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  right: 0;
  background-color: ${props => props.theme.global.backgroundColor};
  box-sizing: border-box;
  z-index: 99999;
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`

const SearchInput = styled.input`
  outline: none;
  height: 42px;
  background: ${props => props.theme.global.inputBackgroundColor};
  color: ${props => props.theme.global.inputTextColor};
  border-radius: 30px;
  border: 1px solid ${props => props.theme.global.inputBorderColor};
  margin: 48px auto;
  display: block;
  width: 40%;
  font-size: 24px;
  padding: 8px 24px;
  font-weight: 500;
`

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 48px;

  & > a:first-child {
    border-radius: 8px 8px 0 0;
  }

  & > a:last-child {
    border-radius: 0 0 8px 8px;
  }
`

const Result = styled(Link)`
  min-height: 80px;
  padding: 16px;
  background: ${props => props.theme.search.rowBackgroundColor};
  border-bottom: 1px solid ${props => props.theme.search.rowBorderColor};
  width: clamp(40%, 90%, 1000px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;

  &:hover {
    background: ${props => props.theme.search.rowHighlightColor};
  }

  & > h3 {
    margin: 0 0 8px 0;
    color: ${props => props.theme.global.textColor};
  }

  & > div {
    font-size: 14px;
    font-weight: 500;
  }

  & > p {
    margin: 0;
    padding: 0;
    font-style: italic;
    margin-top: 8px;
  }
`

function LoadingSearch() {
  return (
    <ResultsContainer>
      {Array(5)
        .fill(0)
        .map(() => {
          return (
            <Result to="/search">
              <h3>
                <Skeleton width="400px" />
              </h3>
              <div>
                <Skeleton width="100px" />
              </div>
              <p>
                <Skeleton width="600px" />
              </p>
            </Result>
          )
        })}
    </ResultsContainer>
  )
}

function Search() {
  const [searchTerm, setSearchTerm] = useSessionStorage('search-term', '')
  const debouncedText = useDebounce(searchTerm, 500)

  const { data, isFetching } = useQuery(
    ['search', debouncedText],
    () => {
      return searchReddit(debouncedText)
    },
    {
      enabled: debouncedText.trim() !== ''
    }
  )

  return (
    <Container>
      <Content>
        <SearchInput
          autoFocus
          onChange={e => setSearchTerm(e.target.value)}
          placeholder="Search Reddit..."
          value={searchTerm}
        />
        {isFetching && !data && <LoadingSearch />}
        {searchTerm.trim() !== '' && (
          <ResultsContainer>
            {data &&
              data.map((result: any) => {
                return (
                  <Result
                    to={result.link
                      // strip reddit links
                      .replace(
                        /(http|https):\/\/(www\.|np\.|old\.|beta\.|new\.|)reddit\.com/g,
                        ''
                      )}
                  >
                    <h3>{result.title}</h3>
                    <div>{result.link}</div>
                    <p>{result.snippet}</p>
                  </Result>
                )
              })}
          </ResultsContainer>
        )}
      </Content>
    </Container>
  )
}

export default Search
