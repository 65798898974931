import { useContext, createContext, useState } from 'react'

export enum BannerType {
  general,
  warning,
  error,
  success
}

export interface Message {
  id: number
  message: string
  type: BannerType
  timeout: number
}

interface MessageProps {
  message: string
  type?: BannerType
  timeout?: number
  urgent?: boolean
}

interface BannerClientContext {
  currentBanner: Message | null
  showBanner: (message: MessageProps) => void
  closeBanner: (id: number) => void
}

let currentTimeout: number | undefined
let bannerStack: Message[] = []
function BannerClient(): BannerClientContext {
  const [currentBanner, setCurrentBanner] = useState<Message | null>(null)

  function showBanner({ message, type, timeout, urgent }: MessageProps) {
    const bannerType = type ?? BannerType.general
    const bannerTimeout = timeout ?? 5000
    const banner: Message = {
      id: Date.now(),
      message,
      type: bannerType,
      timeout: bannerTimeout
    }
    // if its urgent we clear the stack and immediately show this one
    if (urgent) {
      clearTimeout(currentTimeout)
      bannerStack = [banner]
    } else {
      // don't add duplicate messages
      const hasMessageAlready = bannerStack.some(existingBanner => {
        return existingBanner.message === message
      })
      if (!hasMessageAlready && currentBanner?.message !== message) {
        bannerStack = [...bannerStack, banner]
      }
    }
    showNextBanner()
  }

  const showNextBanner = () => {
    if (currentBanner !== null) return
    if (bannerStack.length > 0) {
      const [nextBanner, ...rest] = bannerStack
      setCurrentBanner(nextBanner)
      bannerStack = rest
      currentTimeout = window.setTimeout(() => {
        clearTimeout(currentTimeout)
        setCurrentBanner(null)
        showNextBanner()
      }, nextBanner.timeout)
    }
  }

  const closeBanner = (id: number) => {
    if (id === currentBanner?.id) {
      setCurrentBanner(null)
    }
    showNextBanner()
  }

  return { showBanner, currentBanner, closeBanner }
}

export const BannerContext = createContext<BannerClientContext>(
  {} as BannerClientContext
)

export function BannerProvider({ children }: ReactChildrenProps) {
  const banner = BannerClient()
  return (
    <BannerContext.Provider value={banner}>{children}</BannerContext.Provider>
  )
}

export function useBanner() {
  return useContext(BannerContext)
}
