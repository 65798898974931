import { useEffect, useState } from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import ReactPlayer from 'react-player'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

const ContentContainer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  max-height: 600px;
  background-color: ${props => props.theme.global.backgroundColor};
  color: ${props => props.theme.global.textColor};
 
  & > blockquote {
    border-radius: 8px;
  }

  iframe {
    width: 780px;
    height: 440px;
    border: none;
  }

  & > img {
    max-height: 600px;
    max-width: 100%;
  }

  & > video {
    width: 100%;
    max-height: 600px;
  }
  position: relative;
`

interface BlurBoxProps {
  url: string
  height: number
}

const BlurBox = styled.div<BlurBoxProps>`
  position: absolute;
  background-image: url('${p => p.url}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: ${p => p.height}px;
  width: 100%;
  position: absolute;
  transform: scale(1.2);
  filter: blur(10px);
  overflow: hidden;
`

type GalleryItem = {
  media_id: string
  id: number
}

// TODO: Handle these anys
interface MediaContainerProps {
  secure_media_embed: any
  secure_media: any
  preview: any
  post_hint: string
  url: string
  style?: any
  hideVideo?: boolean
  onLoad?: () => void
  usePreview?: boolean // used for performance
  previewWidth?: number
  previewHeight?: number
  inSubmissionView?: boolean

  // retrieved from toJSON() on Submission
  gallery_data?: any
  media_metadata?: any
}

const imagePreviewCSS = {
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: '50% 50%',
  width: '100%',
  height: '100%',
  transform: 'scale(1)'
}

// TODO: If hiding video, show picture of video
function MediaContainer({
  secure_media_embed,
  secure_media,
  preview,
  post_hint,
  url,
  style,
  hideVideo,
  onLoad,
  gallery_data,
  media_metadata,
  usePreview,
  previewWidth = 200,
  previewHeight = 200,
  inSubmissionView
}: MediaContainerProps) {
  if (
    usePreview &&
    preview &&
    preview.enabled &&
    preview.images.length > 0 &&
    !inSubmissionView
  ) {
    const images = preview.images[0].resolutions
    const middleImageIndex = Math.floor(images.length / 2)
    const image = images[middleImageIndex] && images[middleImageIndex]
    const imageURL = image?.url

    if (imageURL) {
      const projectedHeight = image.height * (previewWidth / image.width)
      const projectedWidth = image.width * (previewHeight / image.height)
      let width = previewWidth
      let height = projectedHeight
      if (previewWidth - projectedWidth > previewHeight - projectedHeight) {
        width = projectedWidth
        height = previewHeight
      }
      const hasBigGap = previewWidth - width > 20 || previewHeight - height > 20
      return (
        <ContentContainer style={{ ...style }}>
          {hasBigGap && <BlurBox url={imageURL} height={height} />}
          <div
            style={{
              ...imagePreviewCSS,
              backgroundImage: `url('${imageURL}')`,
              width: width + 'px',
              height: height + 'px',
              borderRadius: hasBigGap ? 0 : style.borderRadius
            }}
          />
        </ContentContainer>
      )
    }
  }

  const getEmbeddedMedia = (): string | undefined => {
    if (secure_media_embed) {
      return secure_media_embed.content
    }
  }

  const getRedditVideoURL = (): string | undefined => {
    const anyPreview = preview as any
    if (secure_media?.reddit_video) {
      return secure_media.reddit_video.hls_url
    }
    if (anyPreview?.reddit_video_preview) {
      return anyPreview.reddit_video_preview.hls_url
    }
  }

  const mediaHTML = getEmbeddedMedia()
  const redditVideoURL = getRedditVideoURL()

  if (mediaHTML && !hideVideo) {
    return (
      <ContentContainer
        style={style}
        dangerouslySetInnerHTML={{ __html: mediaHTML }}
      />
    )
  }

  if (redditVideoURL && !hideVideo) {
    return (
      <ContentContainer style={style}>
        <ReactPlayer
          url={redditVideoURL}
          controls
          width="100%"
          style={{
            backgroundColor: '#000000'
          }}
          config={{
            file: {
              attributes: {
                preload: 'true'
              },
              hlsOptions: {
                startLevel: -1 // chooses best quality for your bandwidth availability
              }
            }
          }}
        />
      </ContentContainer>
    )
  }

  if (gallery_data && media_metadata && !hideVideo) {
    return (
      <Gallery
        urls={gallery_data.items
          .map(({ media_id }: GalleryItem) => {
            if (media_metadata[media_id].status === 'valid') {
              return media_metadata[media_id].s.u
            } else {
              return null
            }
          })
          .filter((url: any) => !!url)}
      />
    )
  }

  if (post_hint === 'image') {
    return (
      <Zoom>
        <ContentContainer style={style}>
          <img alt="" key={url} src={url} onLoad={onLoad} />
        </ContentContainer>
      </Zoom>
    )
  }

  return null
}

const GalleryContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;

  & > img {
    max-height: 100%;
    width: 100%;
    object-fit: contain;
  }
`

const GalleryButton = styled.div`
  position: absolute;
  top: calc(50% - 24px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50% 50%;
  background: ${props => props.theme.modal.modalButtonBackgroundColor};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  user-select: none;
  z-index: 9999;

  &:hover {
    background: ${props => props.theme.modal.modalButtonHoverBackgroundColor};
  }

  &:active {
    background: ${props => props.theme.modal.modalButtonActiveBackgroundColor};
  }
`

const PreviousButton = styled(GalleryButton)`
  left: 16px;
`

const NextButton = styled(GalleryButton)`
  right: 16px;
`

const PageIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 16px;
  width: 100%;
`

interface PageIndicatorProps {
  active: boolean
}

const PageIndicator = styled.div<PageIndicatorProps>`
  width: 8px;
  height: 8px;
  border-radius: 50% 50%;
  margin: 0 2px;
  background: #ffffff;
  border: 1px solid #989898;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  opacity: ${props => (props.active ? 1 : 0.5)};
`

interface GalleryProps {
  urls: string[]
}

function Gallery({ urls }: GalleryProps) {
  const count = urls.length
  const [currentIndex, setCurrentIndex] = useState(0)
  const location = useLocation()

  useEffect(() => {
    setCurrentIndex(0)
  }, [location])

  const onClickNext = () => {
    if (currentIndex + 1 < count) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const onClickPrevious = () => {
    if (currentIndex - 1 >= 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  return (
    <GalleryContainer>
      {currentIndex > 0 && (
        <PreviousButton onClick={onClickPrevious}>
          <ChevronLeft />
        </PreviousButton>
      )}
      <Zoom>
        <ContentContainer>
          <img alt="" key={urls[currentIndex]} src={urls[currentIndex]} />
        </ContentContainer>
      </Zoom>
      {currentIndex + 1 !== count && (
        <NextButton onClick={onClickNext}>
          <ChevronRight />
        </NextButton>
      )}
      <PageIndicatorContainer>
        {Array(count)
          .fill(null)
          .map((val, index) => (
            <PageIndicator active={currentIndex === index} />
          ))}
      </PageIndicatorContainer>
    </GalleryContainer>
  )
}

export default MediaContainer
