import { useApp } from '@contexts/AppContextProvider'
import { useSnoowrap } from '@contexts/SnoowrapContext'
import { uniqueBy } from '@utilities/arrays'
import { killClickHandler } from '@utilities/events'
import {
  relativeDate,
  replaceRedditLinksWithReditr
} from '@utilities/formatters'
import { SyntheticEvent, useEffect, useState } from 'react'
import { Send } from 'react-feather'
import Skeleton from 'react-loading-skeleton'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import TextareaAutosize from 'react-textarea-autosize'
import { PrivateMessage, ReplyableContent } from 'snoowrap'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  right: 0;
  background-color: ${props => props.theme.global.backgroundColor};
  color: ${props => props.theme.notificationCenter.textColor};
  padding: 64px 16px;
  box-sizing: border-box;
  overflow: auto;
  z-index: 999999;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  border-bottom: 1px solid
    ${props => props.theme.notificationCenter.borderColor};
  margin-bottom: 16px;
`

const Author = styled.span`
  color: ${props => props.theme.notificationCenter.textColor};
  font-size: 14px;
  font-weight: 600;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const Subject = styled.span`
  font-size: 32px;
  font-weight: 600;
  color: ${props => props.theme.notificationCenter.textColor};
`

const Content = styled.div`
  width: clamp(40%, 90%, 1000px);
  background: ${props => props.theme.notificationCenter.backgroundColor};
  border: 1px solid ${props => props.theme.notificationCenter.borderColor};
  border-radius: 6px;
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
  padding: 16px;
`

const ReplyViewContainer = styled.div`
  width: 100%;
  border-top: 1px solid ${props => props.theme.notificationCenter.borderColor};
  margin-top: 16px;
  position: relative;
  display: flex;
  align-items: flex-end;
`

const StyledTextArea = styled(TextareaAutosize)`
  width: 100%;
  border: none;
  font-size: 14px;
  box-sizing: border-box;
  outline: none;
  margin-top: 16px;
  border-radius: 20px;
  background-color: ${props => props.theme.global.inputBackgroundColor};
  color: ${props => props.theme.global.inputTextColor};
  resize: none;
  padding: 10px 16px;
  margin-right: 8px;
`

const SendButton = styled.button`
  background-color: ${props => props.theme.modal.modalButtonBackgroundColor};
  color: ${props => props.theme.modal.modalButtonTextColor};
  border: none;
  height: 40px;
  border-radius: 20px;
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;
  padding: 0 14px 0 12px;

  &:hover {
    background-color: ${props =>
      props.theme.modal.modalButtonHoverBackgroundColor};
  }
  &:active {
    background-color: ${props =>
      props.theme.modal.modalButtonActiveBackgroundColor};
  }
  opacity: ${props => (props.disabled ? '0.5' : '1')};

  display: flex;
  justify-content: center;
  align-items: center;

  /* Icons */
  svg {
    margin: 0 4px 0 0;
  }
`

interface ReplyViewProps {
  privateMessage?: PrivateMessage
  disable?: boolean
  onReply?: (privateMessage: ReplyableContent<PrivateMessage>) => void
}

function ReplyView({
  privateMessage,
  disable = false,
  onReply
}: ReplyViewProps) {
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value)
  }

  const onClickSend = () => {
    setIsLoading(true)
    privateMessage?.reply(message).then(pm => {
      onReply && onReply(pm)
      setMessage('')
      setIsLoading(false)
    })
  }

  return (
    <ReplyViewContainer>
      <StyledTextArea
        autoFocus={true}
        placeholder="Reply here..."
        onChange={onChange}
        value={message}
        disabled={disable || isLoading}
      />
      <SendButton disabled={disable || isLoading || message.trim() === ''} onClick={onClickSend}>
        <Send size={12} /> Send
      </SendButton>
    </ReplyViewContainer>
  )
}

interface PrivateMessageViewerProps {
  id: string
}

function PrivateMessageViewer() {
  const snoowrap = useSnoowrap()
  const { id } = useParams<PrivateMessageViewerProps>()

  const [newReplies, setNewReplies] = useState<PrivateMessage[]>([])

  const { isLoading, data } = useQuery(['message', id], () => {
    return snoowrap.getClient()?.getMessage(id).fetch()
  })

  useEffect(() => {
    data?.markAsRead().then()
  }, [data])

  if (isLoading) {
    return (
      <Container>
        <Content>
          <Header>
            <Subject>
              <Skeleton width="400px" />
            </Subject>
          </Header>
          <Skeleton width="80px" />
          <Skeleton width="200px" />
          <Skeleton width="40px" />
          <Skeleton width="100px" />
          <Skeleton width="80px" />
          <Skeleton width="200px" />
          <Skeleton width="40px" />
          <Skeleton width="100px" />
          <ReplyView disable={true} />
        </Content>
      </Container>
    )
  }

  const flattenPrivateMessages = (
    pm: PrivateMessage,
    collection: PrivateMessage[]
  ) => {
    collection.push(pm)
    pm.replies.forEach(pm => {
      collection = flattenPrivateMessages(pm, collection)
    })
    return collection
  }

  // some error occurred
  if (!data) return null

  const pms = flattenPrivateMessages(data, [])

  const renderPrivateMessages = (
    pms: PrivateMessage[],
    newPMs: PrivateMessage[] = []
  ) => {
    return uniqueBy(pms.concat(newPMs), 'id').map((pm, index) => {
      const previousAuthorName: string | undefined =
        index > 0 ? pms[index - 1].author.toJSON().name : undefined
      return (
        <PrivateMessageContent
          key={index}
          message={pm}
          showAuthor={pm.author.name !== previousAuthorName}
        />
      )
    })
  }

  return (
    <Container>
      <Content>
        <Header>
          <Subject>{data.subject}</Subject>
        </Header>
        {renderPrivateMessages(pms, newReplies)}
        <ReplyView
          privateMessage={data}
          onReply={pm => setNewReplies([...newReplies, pm as PrivateMessage])}
        />
      </Content>
    </Container>
  )
}

const MessageContent = styled.div``

interface PrivateMessageContentProps {
  message: PrivateMessage
  showAuthor?: boolean
}

const PrivateMessageContainer = styled.div`
  margin-top: 16px;
`

const Body = styled.div`
  margin: 8px 0;

  p {
    margin: 0;
  }
`

const TimeAgo = styled.div`
  font-size: 12px;
  color: #666666;
`

function PrivateMessageContent({
  message,
  showAuthor = true
}: PrivateMessageContentProps) {
  const { author, body_html, replies, created_utc } = message
  const app = useApp()

  const onClickAuthor = (authorName: string) => (e: SyntheticEvent) => {
    killClickHandler(e)
    app.showUserModal(true, authorName)
  }

  return (
    <PrivateMessageContainer>
      <MessageContent>
        {showAuthor && (
          <Author onClick={onClickAuthor(author.name)}>{author.name}</Author>
        )}
        <Body
          dangerouslySetInnerHTML={{
            __html: replaceRedditLinksWithReditr(body_html)
          }}
        />
        <TimeAgo>{relativeDate(new Date(created_utc * 1000))}</TimeAgo>
      </MessageContent>
    </PrivateMessageContainer>
  )
}

export default PrivateMessageViewer
