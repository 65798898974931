export function uniqueBy(array: any[], key: string): any[] {
  let history: Record<any, boolean> = {}
  return array.filter(entry => {
    if (history[entry[key]]) {
      return false
    }
    history[entry[key]] = true
    return true
  })
}

export function onlyUnique(value: any, index: number, self: any[]) {
  return self.indexOf(value) === index;
}