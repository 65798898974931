import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useEffect, useState, useReducer, ChangeEvent } from 'react'
import { useServerSync, LoginStatus } from '@contexts/ServerSyncContext'
import { useAuth } from '@contexts/AuthContext'
import {
  UserSet,
  ColumnSet,
  UsernameToColumnsSet,
  parseLegacyData,
  SubredditListInit,
  SetColumnAction,
  SetUsersAction,
  legacyDataToV2
} from './legacyDataHelpers'

const params = new URLSearchParams(window.location.search)

const Container = styled.div`
  overflow-y: scroll;
  height: 100%;
  padding: 10px;
  & button {
    background: black;
    color: white;
    border: none;
    padding: 10px;
    margin: 12px;
    border-radius: 6px;
  }
  & input[type='checkbox'] {
    margin: 10px;
  }
`

const SubredditListContainer = styled.div`
  padding-left: 20px;
`

const Header = styled.span`
  font-weight: bold;
  font-size: 120%;
`

const UncheckAllButton = styled.a`
  padding-left: 10px;
  text-decoration: underline;
  font-size: 80%;
  &:visited {
    text-decoration: none;
  }
`

const UserRowContainer = styled.div`
  margin: 10px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
`

const SubredditList = ({ columns, setColumns }: SubredditListInit) => {
  return (
    <SubredditListContainer>
      {Object.keys(columns).map(column => {
        const id = '' + Math.random()
        const onToggle = (event: ChangeEvent<HTMLInputElement>) => {
          columns[column] = event.target.checked
          setColumns(columns)
        }
        return (
          <div key={id}>
            <input
              checked={columns[column] ?? false}
              type="checkbox"
              id={id}
              name={id}
              onChange={onToggle}
            />
            <label htmlFor={id}>{column}</label>
          </div>
        )
      })}
    </SubredditListContainer>
  )
}

type UserRowInit = {
  username: string
  enabled: boolean
  setEnabled: (enabled: boolean) => void
  columns: ColumnSet
  setColumns: (columns: ColumnSet) => void
}

const UserRow = ({
  username,
  enabled,
  setEnabled,
  columns,
  setColumns
}: UserRowInit) => {
  const toggleHandler = (event: ChangeEvent<HTMLInputElement>) =>
    setEnabled(event.target.checked)
  const [syncAllColumns, setSyncAllColumns] = useState(true)
  const toggleSyncAllColumns = (event: ChangeEvent<HTMLInputElement>) =>
    setSyncAllColumns(event.target.checked)
  useEffect(() => {
    if (syncAllColumns) {
      Object.keys(columns).forEach(column => (columns[column] = true))
      setColumns(columns)
    }
  }, [syncAllColumns])
  const uncheckAll = () => {
    Object.keys(columns).forEach(column => (columns[column] = false))
    setColumns(columns)
  }
  const id = '' + Math.random()
  return (
    <UserRowContainer>
      <input
        checked={enabled}
        type="checkbox"
        id={username}
        name={username}
        onChange={toggleHandler}
      />
      <Header>
        <label htmlFor={username}>{username.replace(/^reddit_/, '')}</label>
      </Header>
      {enabled && (
        <SubredditListContainer>
          <input
            checked={syncAllColumns}
            type="checkbox"
            id={id}
            name={id}
            onChange={toggleSyncAllColumns}
          />
          <label htmlFor={id}>
            <Trans>all columns</Trans>
          </label>
          {!syncAllColumns && (
            <UncheckAllButton href="#" onClick={uncheckAll}>
              <Trans>uncheck all</Trans>
            </UncheckAllButton>
          )}
          {!syncAllColumns && (
            <SubredditList columns={columns} setColumns={setColumns} />
          )}
        </SubredditListContainer>
      )}
    </UserRowContainer>
  )
}

type UserListInit = {
  users: UserSet
  setUsers: (users: SetUsersAction) => void
  columns: UsernameToColumnsSet
  setColumns: (columns: SetColumnAction) => void
}

const UserList = ({ users, setUsers, columns, setColumns }: UserListInit) => {
  const userList = Object.keys(users)
  return (
    <div>
      {!userList.length && <Trans>You have no users to sync!</Trans>}
      {userList.map(username => (
        <UserRow
          username={username}
          enabled={users[username]}
          setEnabled={enabled =>
            setUsers({ type: 'set-user', username, enabled })
          }
          columns={columns[username]}
          setColumns={columns =>
            setColumns({ type: 'set-columns', username, columns })
          }
        />
      ))}
    </div>
  )
}

export default function EmailLogin() {
  const serverSync = useServerSync()
  const { push } = useHistory()
  const auth = useAuth()
  const apiKey = params.get('apiKey') || ''

  // first finish signing in with email
  useEffect(() => {
    if (apiKey === '' || apiKey === null) return
    serverSync.loginManager.finishEmailLinkAuth(apiKey)
  }, [apiKey])

  // now get sync data
  useEffect(() => {
    if (serverSync.loginStatus !== LoginStatus.LoggedIn) return
    serverSync.loginManager.downloadLegacyData()
  }, [serverSync.loginStatus])

  // we need to keep track of users enabled and have a way for UserList to change em
  const [users, setUsers] = useReducer(
    (state: UserSet, action: SetUsersAction): UserSet => {
      switch (action.type) {
        case 'set-all':
          return { ...action.setAll }
        case 'set-user':
          state[action.username] = action.enabled
      }
      return { ...state }
    },
    {} as UserSet
  )

  // we need to keep track of user to columns and have a way to change them
  // as the user unchecks and checks boxes in SubredditList etc
  const [columns, setColumns] = useReducer(
    (
      state: UsernameToColumnsSet,
      action: SetColumnAction
    ): UsernameToColumnsSet => {
      switch (action.type) {
        case 'set-all':
          return { ...action.setAll }
        case 'set-columns':
          state[action.username] = action.columns
      }
      return { ...state }
    },
    {} as UsernameToColumnsSet
  )

  // once we download legacy data extract users and columns from it
  useEffect(() => {
    const { users, columns } = parseLegacyData(serverSync.legacyData)
    setUsers({ type: 'set-all', setAll: users })
    setColumns({ type: 'set-all', setAll: columns })
  }, [serverSync.legacyData])

  const saveAction = () => {
    const v2Data = legacyDataToV2(serverSync.legacyData, users, columns)
    auth.userManager.mergeAccounts(v2Data)
    push('/')
  }

  if (!users) {
    return (
      <Container>
        <Trans>Loading...</Trans>
      </Container>
    )
  }

  return (
    <Container>
      <h1>
        <Trans>Accounts To Import</Trans>
      </h1>
      <UserList
        users={users}
        setUsers={setUsers}
        columns={columns}
        setColumns={setColumns}
      />
      <Link to="/">
        <button>
          <Trans>Cancel</Trans>
        </button>
      </Link>
      <button style={{ float: 'right' }} onClick={saveAction}>
        <Trans>Continue</Trans>
      </button>
    </Container>
  )
}
