import { useAuth, AuthedUser, DefaultAppSettings } from '@contexts/AuthContext'

export type ColumnSet = { [key: string]: boolean }
export type UserSet = { [key: string]: boolean }
export type UsernameToColumnsSet = { [key: string]: ColumnSet }

export type SetColumnAction = 
  | { type: 'set-columns', username: string, columns: ColumnSet }
  | { type: 'set-all', setAll: UsernameToColumnsSet }

export type SetUsersAction = 
  | { type: 'set-user', username: string, enabled: boolean }
  | { type: 'set-all', setAll: UserSet }

export type SubredditListInit = {
  columns: ColumnSet
  setColumns: (columns: ColumnSet) => void
}

export interface LegacyColumn {
  url: string
  title: string
}

export function parseLegacyData(legacyData: any | null): { users: UserSet, columns: UsernameToColumnsSet } {
    // ... extract the reddit users out of it
  const users = Object.keys(legacyData?.users ?? [])
  const initialUsers = users.reduce((hash: UserSet, username: string): UserSet => {
    hash[username] = true
    return hash
  }, {} as UserSet)
  // ... and extract the columns for those users too
  const initialColumns = users.reduce((hash: UsernameToColumnsSet, username: string): UsernameToColumnsSet => {
    hash[username] = legacyData[`${username}_columns`].reduce((hash: ColumnSet, col: LegacyColumn) => {
      hash[col.url] = true
      return hash
    }, {} as ColumnSet)
    return hash
  }, {} as UsernameToColumnsSet)
  return {
    users: initialUsers,
    columns: initialColumns
  }
}

export function legacyDataToV2(legacyData: any | null, users: UserSet, columns: UsernameToColumnsSet) {
  return Object
    .keys(users)
    .filter(username => users[username])
    .map(username => {
      return {
        redditAccount: legacyData[username],
        columns: Object
          .keys(columns[username])
          .filter(col => columns[username][col])
          .filter(Boolean)
          .map(str => (str as any).split('r/').pop().replace('/', ''))
          .filter(str => str.length)
          .map(subreddit => ({ subreddit })),
        refreshKey: legacyData[`${username}_refreshkey`] || '',
        settings: DefaultAppSettings
      } as AuthedUser
    })
}
