import { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { useAuth } from '@contexts/AuthContext'
import { useSnoowrap } from '@contexts/SnoowrapContext'
import { useQueryClient } from '@tanstack/react-query'

function Logout() {
  const auth = useAuth()
  const snoowrap = useSnoowrap()
  const queryClient = useQueryClient()

  useEffect(() => {
    const logout = async () => {
      await auth.userManager.logout()
      snoowrap.clear()
      queryClient.clear()
    }
    logout()
  })

  return <Redirect to="/login" />
}

export default Logout
