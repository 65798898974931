import styled from 'styled-components'

import { useAuth } from '@contexts/AuthContext'

const ModalTitle = styled.h1`
  text-align: center;
  color: ${props => props.theme.global.textColor};
`

const Options = styled.div`
  display: flex;
  width: 100%;
`

interface OptionContainerProps {
  selected: boolean
}

const OptionContainer = styled.div<OptionContainerProps>`
  width: calc(50% - 12px);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  cursor: pointer;
  opacity: ${props => (props.selected ? 1 : 0.5)};

  &:hover {
    opacity: 1;
  }

  &:first-child {
    margin-right: 12px;
  }

  &:last-child {
    margin-left: 12px;
  }
`

const Option = styled.div`
  background: ${props => props.theme.modal.sectionBackgroundColor};
  border-radius: 16px;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  overflow: hidden;
`

const OptionTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-top: 16px;
  color: ${props => props.theme.global.textColor};
`

const StreamOption = styled(Option)`
  flex-direction: column;
`

const Post = styled.div`
  width: 80%;
  border-radius: 8px;
  background-color: ${props => props.theme.modal.modalButtonBackgroundColor};
  height: 100px;
  margin-top: 16px;
  flex-shrink: 0;
`

const Column = styled.div`
  background-color: ${props => props.theme.modal.modalButtonBackgroundColor};
  width: 80px;
  margin-left: 16px;
  flex-shrink: 0;
  border-radius: 8px;
  height: 90%;
`

const Note = styled.div`
  font-size: 12px;
  text-align: center;
  margin: 16px 0;
  font-style: italic;
  color: ${props => props.theme.global.textColor};
`

function StreamMode() {
  const auth = useAuth()

  const setIsStreamMode = (newValue: boolean) => () => {
    auth.userManager.updateSetting('isStreamMode', newValue)
  }

  const isUsingStreamMode = auth.currentUser.settings.isStreamMode

  return (
    <>
      <ModalTitle>Feed or Column Mode?</ModalTitle>
      <Options>
        <OptionContainer
          onClick={setIsStreamMode(true)}
          selected={isUsingStreamMode}
        >
          <StreamOption>
            <Post />
            <Post />
            <Post />
            <Post />
          </StreamOption>
          <OptionTitle>Feed</OptionTitle>
        </OptionContainer>
        <OptionContainer
          onClick={setIsStreamMode(false)}
          selected={!isUsingStreamMode}
        >
          <Option>
            <Column />
            <Column />
            <Column />
            <Column />
          </Option>
          <OptionTitle>Column</OptionTitle>
        </OptionContainer>
      </Options>
      <Note>Note: This can be changed later in Settings.</Note>
    </>
  )
}

export default StreamMode
