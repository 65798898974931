import Modal, { ModalButton } from '@components/Modal'
import React, { SyntheticEvent, useState } from 'react'
import { CornerDownLeft } from 'react-feather'
import { PrivateMessage, ReplyableContent, Submission, Comment } from 'snoowrap'
import styled from 'styled-components'
import TextareaAutosize from 'react-textarea-autosize'

// no idea why "| any" is required here!!!
export type ReplyModalDismissType = (
  e?: SyntheticEvent | any,
  newComment?: ReplyableContent<Comment | Submission | PrivateMessage>
) => void

interface ReplyModalProps {
  content: ReplyableContent<Comment | Submission | PrivateMessage>
  dismiss: ReplyModalDismissType
}

const StyledTextArea = styled(TextareaAutosize)`
  width: 100%;
  min-height: 200px;
  border: none;
  font-size: 18px;
  resize: none;
  padding: 8px;
  box-sizing: border-box;
  outline: none;
  margin-top: 24px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: ${props => props.theme.global.inputBackgroundColor};
  color: ${props => props.theme.global.inputTextColor};
  border: 1px solid ${props => props.theme.global.inputBorderColor};
`

export function ReplyModal({ content, dismiss }: ReplyModalProps) {
  const [replyText, setReplyText] = useState('')

  const onReplyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReplyText(e.target.value)
  }

  const onClickReply = () => {
    content.reply(replyText).then(newComment => {
      dismiss(undefined, newComment)
    })
  }

  return (
    <Modal show={true} onClick={(e: SyntheticEvent) => dismiss(e, undefined)}>
      <StyledTextArea
        placeholder="Enter your reply here..."
        value={replyText}
        onChange={onReplyChange}
        autoFocus
      />
      <ModalButton disabled={replyText.trim() === ''} onClick={onClickReply}>
        Reply <CornerDownLeft size={16} strokeWidth={2.5} />
      </ModalButton>
    </Modal>
  )
}
