import Modal, { ModalButton } from '@components/Modal'
import { useApp } from '@contexts/AppContextProvider'
import { useSnoowrap } from '@contexts/SnoowrapContext'
import { Link, MessageCircle } from 'react-feather'
import { useQuery } from '@tanstack/react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const ContentContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
`

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 24px;
`

const Avatar = styled.img`
  border-radius: 16px;
  box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.1);
`

const Username = styled.h2`
  font-weight: 500;
  margin-bottom: 8px;
`

const KarmaContainer = styled.div`
  font-size: 16px;
`

const TrophyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0;
  width: 60%;
  justify-content: center;
  flex-wrap: wrap;

  & > img {
    margin: 4px;
    width: 71px;
    height: 71px;
  }
`

function UserProfile() {
  const app = useApp()
  const snoowrap = useSnoowrap()
  const history = useHistory()

  const user = app.userModalUser // modal will only appear if this shows

  const onClickBackground = (e: any) => {
    if (e.target.id === 'background') {
      app.showUserModal(false, '')
    }
  }

  const { data: userInfo } = useQuery(
    ['userinfo', user],
    () => {
      return snoowrap.getClient()!.getUser(user).fetch()
    },
    {
      enabled: snoowrap.getClient() !== null && user !== ''
    }
  )

  const { data: trophyData } = useQuery(
    ['userinfo', user, 'trophies'],
    () => {
      return userInfo?.getTrophies()
    },
    {
      enabled: !!userInfo
    }
  )

  const onClickViewSbmissions = () => {
    history.push(`/user/${user}`)
    app.showUserModal(false, '')
  }

  if (!!app.userModalUser) {
    return (
      <Modal
        show={app.isShowingUserModal && user !== ''}
        id="background"
        onClick={onClickBackground}
      >
        <ContentContainer>
          <UserInfoContainer>
            <Avatar src={userInfo?.icon_img} />
            <Username>{userInfo?.name ?? user}</Username>
            <KarmaContainer>
              <Link size={12} /> {userInfo?.link_karma} -{' '}
              <MessageCircle size={12} /> {userInfo?.comment_karma}
            </KarmaContainer>
            <TrophyContainer>
              {trophyData?.trophies.map((trophy: any) => {
                return <img title={trophy.name} src={trophy.icon_70} />
              })}
            </TrophyContainer>
            <ModalButton onClick={onClickViewSbmissions}>
              View Submissions
            </ModalButton>
          </UserInfoContainer>
        </ContentContainer>
      </Modal>
    )
  }
  return null
}

export default UserProfile
