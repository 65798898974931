import { initializeApp } from '@firebase/app'
import {
  getAuth,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  User
} from '@firebase/auth'
import { getAnalytics } from 'firebase/analytics'

const config = {
  apiKey: 'AIzaSyDYIVkue_oVQNWk1rYrO7yErfWEVCITZfQ',
  authDomain: 'reditr-2.firebaseapp.com',
  projectId: 'reditr-2',
  storageBucket: 'reditr-2.appspot.com',
  messagingSenderId: '824255702958',
  appId: '1:824255702958:web:3c0b562a0853541ef34a3f',
  measurementId: 'G-HS3TFP8SGG'
}

const app = initializeApp(config)
const analytics = getAnalytics(app)

export default {
  async startEmailLinkAuth(emailAddress: string) {
    const actionCodeSettings = {
      url:
        window.location.protocol + '//' + window.location.host + '/email-login',
      handleCodeInApp: true
    }
    const auth = getAuth()
    await sendSignInLinkToEmail(auth, emailAddress, actionCodeSettings)
    localStorage.setItem('firebaseEmailSignin', emailAddress)
  },

  async finishEmailLinkAuth(apiKey: string) {
    const auth = getAuth()
    const emailAddress = localStorage.getItem('firebaseEmailSignin') || ''
    const result = await signInWithEmailLink(
      auth,
      emailAddress,
      window.location.href
    )
    console.log('RRRRRRR', result)
  },

  observeCurrentUser(callback: (user: User) => void): Function {
    return onAuthStateChanged(getAuth(), callback as any)
  }
}
