import styled from 'styled-components'

const Container = styled.div<any>`
  cursor: pointer;
  display: inline-block;
  background-color: ${props => props.theme.toggle.backgroundColor};
  width: 50px;
  height: 30px;
  border-radius: 15px;
  transition: all 500ms;
  &.on .button {
    transform: translateX(21px);
  }
  &.on {
    background-color: ${props => props.theme.toggle.buttonActiveColor};
  }
  user-select: none !important;
`

const Button = styled.div`
  background-color: ${props => props.theme.toggle.buttonColor};
  width: 28px;
  height: 28px;
  border-radius: 15px;
  transition: all 500ms;
  margin-top: 1px;
  transform: translateX(1px);
`

type ToggleProps = {
  on: boolean
  onChange: (newValue: boolean) => void
}

export default function Toggle(props: ToggleProps) {
  return (
    <Container
      className={props.on ? 'on toggle' : 'off toggle'}
      onClick={props.onChange.bind(null, !props.on)}
    >
      <Button className="button" />
    </Container>
  )
}
