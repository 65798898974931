import AuthedOnly from '@components/AuthedOnly'
import MediaContainer from '@components/MediaContainer'
import { DropdownOptionPicker } from '@components/OptionPicker'
import { ReplyModal, ReplyModalDismissType } from '@components/ReplyModal'
import { ReportModal } from '@components/ReportModal'
import { BannerType, useBanner } from '@contexts/BannerContext'
import { useSnoowrap } from '@contexts/SnoowrapContext'
import { onVoteMutation, VoteDirection } from '@mutations/onVote'
import { getSubmissionKey, getSubredditInfoKey } from '@queries/index'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import blendColors from '@utilities/blendColors'
import { killClickHandler } from '@utilities/events'
import {
  numberShortener,
  relativeDate,
  replaceRedditLinksWithReditr
} from '@utilities/formatters'
import { memo, SyntheticEvent, useEffect, useState } from 'react'
import {
  AlertCircle,
  ArrowDown,
  ArrowUp,
  Compass,
  Flag,
  Lock,
  Save,
  X
} from 'react-feather'
import { Helmet } from 'react-helmet'
import Skeleton from 'react-loading-skeleton'
import { Link, useLocation } from 'react-router-dom'
import { Comment, Listing, Submission, Subreddit } from 'snoowrap'
import styled from 'styled-components'
import Article from './components/Article'
import { CommentView } from './components/CommentView'

const MediaWrapper = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:empty {
    display: none;
  }
`

const Header = styled.div<any>`
  position: ${props => (props.sticky ? 'sticky' : 'relative')};
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;
  box-shadow: rgba(0, 0, 0, ${props => (props.backgroundColor ? 0.03 : 0.07)})
    0px 4px 6px;
  color: ${props =>
    props.backgroundColor
      ? blendColors(
          props.backgroundColor,
          props.theme.submissionView.headerTextColor,
          0.85
        )
      : props.theme.submissionView.headerTextColor} !important;
  background-color: ${props =>
    props.backgroundColor
      ? blendColors(
          props.backgroundColor,
          props.theme.submissionView.headerBackgroundColor
        )
      : props.theme.submissionView.headerBackgroundColor};
  border-bottom: 1px solid
    ${props =>
      props.backgroundColor
        ? blendColors(
            props.backgroundColor,
            props.theme.submissionView.headerBorderColor,
            0.85
          )
        : props.theme.submissionView.headerBorderColor};
  border: 1px solid
    ${props =>
      props.sticky
        ? 'auto'
        : props.backgroundColor
        ? blendColors(
            props.backgroundColor,
            props.theme.submissionView.headerBorderColor,
            0.85
          )
        : props.theme.submissionView.headerBorderColor};
  border-top-left-radius: ${props => (props.sticky ? 0 : 5)}px;
  border-top-right-radius: ${props => (props.sticky ? 0 : 5)}px;
  & a {
    color: inherit;
  }
`

const CloseButton = styled(Link)`
  display: flex;
  cursor: pointer;
  width: 50px;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: absolute;
  top: 0;
  right: 0;

  & > svg {
    font-size: 14px;
    font-weight: bolder;
  }
`

const Title = styled(Link)`
  font-weight: bold;
  font-size: 16px;
  flex-grow: 1;
  padding-right: 46px;
  padding-left: 10px;
  margin-top: 0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const SkeletonTitle = styled(Skeleton)`
  margin-left: 10px;
`

const SubredditSpan = styled.span`
  font-size: 14px;
  font-weight: regular;
`

const SelfText = styled.p`
  padding: 0 16px;
  color: ${props => props.theme.global.textColor};
  font-size: 16px;
  line-height: 36px;
`

const SubmissionCard = styled.div<any>`
  width: clamp(40%, 90%, 1000px);
  border-top-left-radius: ${props => (props.curvedTop ? 5 : 0)}px;
  border-top-right-radius: ${props => (props.curvedTop ? 5 : 0)}px;
  background-color: ${props => props.theme.submissionView.backgroundColor};
  margin-left: auto;
  margin-right: auto;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 4px 6px;

  @media (max-width: 460px) {
    width: 100%;
  }
`

const PillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 12px 16px;

  &:empty {
    display: none;
  }
`

const Pill = styled.div`
  padding: 4px 6px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  height: 10px;
  font-size: 12px;
  border-radius: 6px;
  margin-right: 3px;
  margin-bottom: 2px;
  line-height: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 4px;
  }
`

const NSFWPill = styled(Pill)`
  background-color: pink;
  border-color: #ffb0be;
  color: #ff4747;
`

const LockedPill = styled(Pill)`
  background-color: #d8493e;
  border-color: #c9443a;
  color: #ffffff;
`

const PinnedPill = styled(Pill)`
  background-color: #8bd081;
  border-color: #7faa48;
  color: #ffffff;
`

const SubmissionActionsContainer = styled.div`
  display: flex;
  margin-left: 6px;
`

const Action = styled.div`
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.submissionView.submissionActionColor};
  cursor: pointer;
`

const ReplyButton = styled.button`
  border: none;
  background: transparent;
  outline: none;
  color: ${props => props.theme.submissionView.replyPostColor};
  font-weight: 600;
  padding: 0;
  margin-right: 16px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const OpenInRedditLink = styled.a`
  text-decoration: none;
  margin-left: auto;
  color: ${props => props.theme.global.textColor};
  font-size: 12px;
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 4px;
  }

  &:hover {
    text-decoration: underline;
  }
`

const VoteContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
  padding-left: 16px;
`

interface VoteProps {
  active: boolean | null
}

const UpVote = styled.span<VoteProps>`
  background-color: ${props =>
    props.active ? props.theme.submissionView.upvoteBackground : 'transparent'};
  width: 16px;
  height: 16px;
  color: ${props =>
    props.active ? props.theme.submissionView.upvoteColor : 'auto'};
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all ease-in 0.2s;
  cursor: pointer;
`

interface ScoreProps {
  direction: String | null // up down or null
}

const Score = styled.span<ScoreProps>`
  font-size: 13px;
  font-weight: 500;
  padding-left: 2px;
  padding-right: 2px;
  color: ${props =>
    props.direction === 'up'
      ? props.theme.submissionView.upvoteColor
      : props.direction === 'down'
      ? props.theme.submissionView.downvoteColor
      : 'auto'};
`

const SkeletonScore = styled(Skeleton)`
  margin: 0 2px;
`

const DownVote = styled.span<VoteProps>`
  background-color: ${props =>
    props.active
      ? props.theme.submissionView.downvoteBackground
      : 'transparent'};
  color: ${props =>
    props.active ? props.theme.submissionView.downvoteColor : 'auto'};
  width: 16px;
  height: 16px;
  font-size: 16px;
  margin-bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all ease-in 0.2s;
  cursor: pointer;
`

const SubmissionContentFooter = styled.div`
  display: flex;
  padding: 8px 16px 8px 16px;
`

interface TimeAgoProps {
  isShowingExit: boolean
}

const TimeAgo = styled.span<TimeAgoProps>`
  font-size: 12px;
  margin-right: ${props => (props.isShowingExit ? '46px' : '16px')};
  flex-shrink: 0;
`

const CommentsContainer = styled.div`
  padding: 10px;
  padding-bottom: 1px;
  border-top: 1px solid
    ${props => props.theme.submissionView.commentsContainerBorderColor};
  flex-grow: 1;
`

const EmptyComments = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
  padding-top: 30px;
  padding-bottom: 30px;
  color: ${props => props.theme.global.textColor};
`

enum CommentSort {
  Best = 'confidence',
  Top = 'top',
  New = 'new',
  Controversial = 'controversial',
  Old = 'old',
  QAndA = 'qa'
}

interface CommentsViewProps {
  comments: Comment[] | Listing<Comment>
  newComments: Comment[]
  isFetching: boolean
  maxComments?: number
}

function CommentsView({
  comments,
  newComments,
  isFetching,
  maxComments
}: CommentsViewProps) {
  return (
    <CommentsContainer>
      {newComments.map((newComment, i) => (
        <CommentView
          key={newComment.id}
          comment={newComment}
          className={
            (i === 0 ? 'first ' : ' ') +
            (i === newComments.length - 1 ? 'last' : '')
          }
        />
      ))}
      {!isFetching &&
        comments?.map((comment, i) => {
          return (
            <CommentView
              key={comment.id}
              comment={comment}
              hideReplies={!!maxComments}
              className={
                (i === 0 ? 'first ' : ' ') +
                (i === comments.length - 1 ? 'last' : '')
              }
            />
          )
        })}
      {isFetching && (
        <CommentsLoadingView
          count={maxComments ? maxComments : 6}
          nested={maxComments === 2 ? false : true} // if maxComments === 2 then have no nesting
        />
      )}
      {!isFetching && comments.length === 0 && newComments.length === 0 && (
        <EmptyComments>No comments (yet).</EmptyComments>
      )}
    </CommentsContainer>
  )
}

const MemoCommentsView = memo(CommentsView)

const SkeletonCommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`

const SkeletonComment = styled.div`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`

const SkeletonCommentBody = styled.div`
  margin-top: 8px;

  & > ${SkeletonComment} {
    padding-left: 40px;
    margin-top: 20px;
  }
`

interface CommentsLoadingViewProps {
  count: number
  nested: boolean
}

export function CommentsLoadingView({
  nested,
  count
}: CommentsLoadingViewProps) {
  return (
    <SkeletonCommentsContainer>
      {Array(count)
        .fill(null)
        .map((_value, index) => (
          <SkeletonComment key={index}>
            <Skeleton width="100px" />
            <SkeletonCommentBody>
              <Skeleton count={3} />
              {nested && Math.random() < 0.5 && (
                <SkeletonComment>
                  <Skeleton width="100px" />
                  <SkeletonCommentBody>
                    <Skeleton count={3} />
                    <SkeletonComment>
                      <Skeleton width="100px" />
                      <SkeletonCommentBody>
                        <Skeleton count={3} />
                      </SkeletonCommentBody>
                    </SkeletonComment>
                  </SkeletonCommentBody>
                </SkeletonComment>
              )}
            </SkeletonCommentBody>
          </SkeletonComment>
        ))}
    </SkeletonCommentsContainer>
  )
}

interface SubmissionCardProps {
  submissionID: string
  maxComments?: number
  columnID?: string
  showHelmet?: boolean
  showSubreddit?: boolean
  embedded: boolean
  showExit?: boolean
  sourceQuery?: string | undefined
}

interface LocationState {
  sourceQuery?: string | undefined
}

export function SubmissionCardView({
  submissionID,
  maxComments,
  columnID,
  showHelmet,
  showSubreddit,
  embedded,
  showExit = true,
  sourceQuery
}: SubmissionCardProps) {
  const snoowrap = useSnoowrap()
  const queryClient = useQueryClient()
  const banner = useBanner()
  const location = useLocation<LocationState>()

  const [isReporting, setIsReporting] = useState(false)
  const [isReplying, setIsReplying] = useState(false)
  const [newComments, setNewComments] = useState<Comment[]>([])
  const [commentSort, setCommentSort] = useState(CommentSort.Best)

  const changeCommentSortHandler = (changedId: string, newValue: boolean) => {
    setCommentSort(changedId as CommentSort)
  }

  const baseSubmissionQuery = getSubmissionKey(submissionID)
  const sortedSubmissionQuery = getSubmissionKey(submissionID, commentSort)

  // clear new comments when url location changes
  useEffect(() => {
    setNewComments([])
  }, [location])

  const cachedSubmissionData: Submission | undefined =
    queryClient.getQueryData(baseSubmissionQuery)
  const { isFetching, data: submissionData } = useQuery<Submission>(
    sortedSubmissionQuery,
    () => {
      return snoowrap
        .getClient()!
        .getSubmission(submissionID, commentSort)
        .fetch()
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!snoowrap.getClient(),
      initialData: cachedSubmissionData
    }
  )

  const data = submissionData ?? cachedSubmissionData ?? null

  const sub = data?.subreddit_name_prefixed
  const { data: subredditInfo } = useQuery<Subreddit>(
    getSubredditInfoKey(sub!),
    () => snoowrap.getClient()!.getSubreddit(sub!).fetch(),
    {
      enabled: snoowrap.getClient() !== null && !!sub,
      refetchOnWindowFocus: false
    }
  )

  // store top level submission in cache so the media doesn't get re rendered when
  // switching comment sorting
  queryClient.setQueryData(baseSubmissionQuery, data)

  let comments: Listing<Comment> | Comment[] | undefined =
    submissionData?.comments
  if (comments && maxComments) {
    comments = comments.slice(0, maxComments)
  }

  const { over_18, stickied, locked } = data ?? {}

  const warmSubmissionCache = () => {
    queryClient.setQueryData(sortedSubmissionQuery, data)
    queryClient.invalidateQueries(sortedSubmissionQuery)
  }

  const standardErrorHandling = (e: any) => {
    switch (e.statusCode) {
      case 400:
        banner.showBanner({
          message: 'Unable to vote on this post.',
          type: BannerType.error
        })
        break
      case 429:
        banner.showBanner({
          message: 'You have been rate limited, try again in a few seconds.',
          type: BannerType.error
        })
        break
      default:
        banner.showBanner({
          message: 'Something went wrong with your request.',
          type: BannerType.error
        })
        break
    }

    // reset to older state
    queryClient.setQueryData(sortedSubmissionQuery, data)
  }

  const didClickUpVote = useMutation(
    (likes: boolean | null) => {
      const submission = snoowrap.getClient()!.getSubmission(submissionID)
      return likes === true ? submission.unvote() : submission.upvote()
    },
    {
      // When mutate is called:
      onMutate: onVoteMutation({
        direction: VoteDirection.up,
        content: queryClient.getQueryData([
          'submission',
          submissionID,
          commentSort
        ]) as Submission,
        preVote: async () => {
          // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
          await queryClient.cancelQueries([
            'submission',
            submissionID,
            commentSort
          ])
        },
        postVote: postVoteSubmission => {
          // Optimistically update to the new value
          queryClient.setQueryData(sortedSubmissionQuery, postVoteSubmission)
        }
      }),
      onError: standardErrorHandling
    }
  )

  const didClickDownVote = useMutation(
    (likes: boolean | null) => {
      const submission = snoowrap.getClient()!.getSubmission(submissionID)
      return likes === false ? submission.unvote() : submission.downvote()
    },
    {
      // When mutate is called:
      onMutate: onVoteMutation({
        direction: VoteDirection.down,
        content: queryClient.getQueryData([
          'submission',
          submissionID,
          commentSort
        ]) as Submission,
        preVote: async () => {
          // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
          queryClient.cancelQueries(sortedSubmissionQuery)
        },
        postVote: postVoteSubmission => {
          // Optimistically update to the new value
          queryClient.setQueryData(sortedSubmissionQuery, postVoteSubmission)
        }
      }),
      onError: standardErrorHandling
    }
  )

  const saveMutation = useMutation(() => {
    if (data != null) {
      if (data.saved) {
        data.unsave()
        banner.showBanner({
          message: 'Saved post!',
          type: BannerType.success
        })
      } else {
        data.save()
        banner.showBanner({
          message: 'Unsaved post!',
          type: BannerType.success
        })
      }
    }
    // TODO: Update data object
    return { likes: null, score: 0 } as Submission
  })

  const onClickSave = () => {
    saveMutation.mutate()
  }

  const onClickReply = () => {
    setIsReplying(true)
  }

  const dismissReplyModal: ReplyModalDismissType = (e, newComment): void => {
    if (e != null) {
      killClickHandler(e)
    }
    setIsReplying(false)
    if (!!newComment) {
      setNewComments([newComment as Comment, ...newComments])
    }
  }

  const dismissReportModal = (e: SyntheticEvent) => {
    killClickHandler(e)
    setIsReporting(false)
  }

  const onClickReport = () => {
    setIsReporting(true)
  }

  const { likes, score } = data ?? { likes: null, score: 0 }

  return (
    <SubmissionCard curvedTop={embedded}>
      {showHelmet && (
        <Helmet>
          <title>{data?.title}</title>
        </Helmet>
      )}
      <Header backgroundColor={subredditInfo?.primary_color} sticky={!embedded}>
        <VoteContainer>
          <UpVote
            active={likes}
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              didClickUpVote.mutate(likes)
            }}
          >
            <ArrowUp />
          </UpVote>
          {isFetching && !data ? (
            <SkeletonScore width="32px" />
          ) : (
            <Score direction={likes ? 'up' : likes === false ? 'down' : null}>
              {data ? numberShortener(score, 1) : '--'}
            </Score>
          )}

          <DownVote
            active={likes === false}
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              didClickDownVote.mutate(likes)
            }}
          >
            <ArrowDown />
          </DownVote>
        </VoteContainer>
        {isFetching && !data ? (
          <SkeletonTitle width="360px" />
        ) : (
          <Title
            title={data?.title}
            to={{
              pathname: columnID
                ? `/column/${columnID}${data?.permalink ?? ''}`
                : data?.permalink ?? '',
              state: { sourceQuery }
            }}
            onClick={warmSubmissionCache}
          >
            {data?.title}
            {showSubreddit && data && (
              <SubredditSpan> on {data.subreddit_name_prefixed}</SubredditSpan>
            )}
          </Title>
        )}
        {data && (
          <TimeAgo isShowingExit={showExit}>
            {relativeDate(new Date(data.created_utc * 1000))}
          </TimeAgo>
        )}
        {showExit && (
          <CloseButton
            to={location.state?.sourceQuery ? location.state?.sourceQuery : '/'}
          >
            <X />
          </CloseButton>
        )}
      </Header>
      <PillsContainer>
        {stickied && (
          <PinnedPill>
            <AlertCircle size={12} /> Pinned
          </PinnedPill>
        )}
        {locked && (
          <LockedPill>
            <Lock size={12} /> Locked
          </LockedPill>
        )}
        {over_18 && <NSFWPill>NSFW</NSFWPill>}
      </PillsContainer>
      {data?.selftext_html && (
        <SelfText
          dangerouslySetInnerHTML={{
            __html: replaceRedditLinksWithReditr(data.selftext_html)
          }}
        ></SelfText>
      )}
      {data &&
        data.post_hint === 'link' &&
        Object.keys(data.secure_media_embed).length === 0 && (
          <SelfText>
            <Article url={data.url} />
          </SelfText>
        )}
      {data && (
        <MediaWrapper>
          <MediaContainer {...data} inSubmissionView={true} />
        </MediaWrapper>
      )}
      {!data && (
        <MediaWrapper>
          <Skeleton width="600px" height="400px" />
        </MediaWrapper>
      )}
      {!isFetching && (
        <SubmissionContentFooter>
          <AuthedOnly>
            <ReplyButton onClick={onClickReply}>reply</ReplyButton>
          </AuthedOnly>

          <DropdownOptionPicker
            selectedId={commentSort}
            options={Object.values(CommentSort)}
            onChange={changeCommentSortHandler}
          />

          <AuthedOnly>
            <SubmissionActionsContainer>
              <Action onClick={onClickSave}>
                <Save size={14} />
              </Action>
              <Action onClick={onClickReport}>
                <Flag size={14} />
              </Action>
            </SubmissionActionsContainer>
          </AuthedOnly>

          <OpenInRedditLink
            href={`https://reddit.com${data?.permalink}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Compass size={12} /> Open in Reddit
          </OpenInRedditLink>
        </SubmissionContentFooter>
      )}
      <MemoCommentsView
        isFetching={isFetching}
        maxComments={maxComments}
        newComments={newComments}
        comments={comments ?? []}
      />
      {isReplying && data && (
        <ReplyModal content={data} dismiss={dismissReplyModal} />
      )}
      {isReporting && data && (
        <ReportModal content={data} dismiss={dismissReportModal} />
      )}
    </SubmissionCard>
  )
}
