import { User } from '@firebase/auth'
import { createContext, useContext, useEffect, useReducer } from 'react'
import firebase from './firebase'

type Action =
  | { type: 'login-with-email'; emailAddress: string }
  | { type: 'login-with-email-error'; error: LoginStatus | undefined }
  | { type: 'login-with-email-success' }
  | { type: 'verify-login-with-email' }
  | { type: 'verify-login-with-email-error' }
  | { type: 'verify-login-with-email-success' }
  | { type: 'set-logged-in-user'; user: any }
  | { type: 'set-logged-out-user' }
  | { type: 'set-legacy-data'; data: any }

enum LoginStatus {
  None,
  LoginRequestLoading,
  WaitingForEmail,
  EmailSent,
  EmailVerificationRequestLoading,
  EmailVerified,
  Error,
  ErrorInvalidEmailAddress,
  LoggedIn
}

interface ServerSyncContext {
  loginManager: LoginActions
  firebaseUser: User | null
  loginStatus: LoginStatus
  legacyData: any
}

interface ServerSyncReducerState {
  firebaseUser: User | null
  loginStatus: LoginStatus
  legacyData: any
}

function serverSyncReducer(
  state: ServerSyncReducerState,
  action: Action
): ServerSyncReducerState {
  const oldStateString = JSON.stringify(state)
  let newState = JSON.parse(oldStateString) as ServerSyncReducerState
  switch (action.type) {
    // SCENARIO: User is trying to login with email
    case 'login-with-email':
      newState.loginStatus = LoginStatus.LoginRequestLoading
      break
    case 'login-with-email-success':
      newState.loginStatus = LoginStatus.WaitingForEmail
      break
    case 'login-with-email-error':
      newState.loginStatus = action.error || LoginStatus.Error
      break

    // SCENARIO: User clicked the link to verify their email
    case 'verify-login-with-email':
      newState.loginStatus = LoginStatus.EmailVerificationRequestLoading
      break
    case 'verify-login-with-email-success':
      newState.loginStatus = LoginStatus.EmailVerified
      break
    case 'verify-login-with-email-error':
      newState.loginStatus = LoginStatus.Error
      break
    case 'set-logged-in-user':
      newState.loginStatus = LoginStatus.LoggedIn
      newState.firebaseUser = action.user
      break
    case 'set-logged-out-user':
      newState.loginStatus = LoginStatus.None
      newState.firebaseUser = null
      break

    // SCENARIO: User sync'd with v1 reditr
    case 'set-legacy-data':
      newState.legacyData = action.data
      break
  }

  const newStateString = JSON.stringify(newState)
  return newStateString === oldStateString ? state : newState
}

function validateEmail(email: string): boolean {
  return !!String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

class LoginActions {
  private dispatch: Function
  private serverSyncState: ServerSyncReducerState

  constructor(dispatch: Function, serverSyncState: ServerSyncReducerState) {
    this.dispatch = dispatch
    this.serverSyncState = serverSyncState
  }

  // FIREBASE METHODS

  async loginWithEmail(emailAddress: string) {
    if (!validateEmail(emailAddress)) {
      this.dispatch({
        type: 'login-with-email-error',
        error: LoginStatus.ErrorInvalidEmailAddress
      })
      return
    }
    try {
      this.dispatch({ type: 'login-with-email' })
      await firebase.startEmailLinkAuth(emailAddress)
      this.dispatch({ type: 'login-with-email-success' })
    } catch (error: any) {
      this.dispatch({ type: 'login-with-email-error' })
      console.error('[serverSyncContext]', error.message, error.stack)
    }
  }

  async finishEmailLinkAuth(apiKey: string) {
    try {
      this.dispatch({ type: 'verify-login-with-email' })
      await firebase.finishEmailLinkAuth(apiKey)
      this.dispatch({ type: 'verify-login-with-email-success' })
    } catch (error: any) {
      this.dispatch({ type: 'verify-login-with-email-error' })
      console.error('[serverSyncContext]', error.message, error.stack)
    }
  }

  // LEGACY REDITR METHODS

  async downloadLegacyData() {
    const options = {
      headers: {
        Authorization: `Bearer ${await this.serverSyncState.firebaseUser?.getIdToken()}`
      }
    }
    const data = await fetch(
      `${process.env.REACT_APP_SYNC_SERVER_HOST}/authed/get-legacy-data`,
      options
    ).then(response => response.json())
    this.dispatch({ type: 'set-legacy-data', data })
  }
}

const startingData = {
  firebaseUser: null,
  loginStatus: LoginStatus.None
} as ServerSyncReducerState

function ServerSyncContextBuilder(): ServerSyncContext {
  const [serverSyncState, dispatch] = useReducer(
    serverSyncReducer,
    startingData
  )

  useEffect(() => {
    const deinit = firebase.observeCurrentUser((user: any) => {
      if (user) {
        dispatch({ type: 'set-logged-in-user', user })
      } else {
        dispatch({ type: 'set-logged-out-user' })
      }
    })
    return () => {
      deinit()
    }
  }, [])

  return {
    ...serverSyncState,
    loginManager: new LoginActions(dispatch, serverSyncState)
  }
}

const Context = createContext<ServerSyncContext>({} as ServerSyncContext)

function useServerSync(): ServerSyncContext {
  return useContext(Context)
}

function ServerSyncProvider({ children }: ReactChildrenProps) {
  const context = ServerSyncContextBuilder()
  return <Context.Provider value={context}>{children}</Context.Provider>
}

export { useServerSync, ServerSyncProvider, LoginStatus }
