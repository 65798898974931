import { ThemeSettings } from '@modals/Settings'
import styled from 'styled-components'

const ModalTitle = styled.h1`
  text-align: center;
  color: ${props => props.theme.global.textColor};
`

const Note = styled.div`
  font-size: 12px;
  text-align: center;
  margin: 16px 0;
  font-style: italic;
  color: ${props => props.theme.global.textColor};
`

function Theme() {
  return (
    <>
      <ModalTitle>Theme Settings</ModalTitle>
      <ThemeSettings />
      <Note>Note: This can be changed later in Settings.</Note>
    </>
  )
}

export default Theme
