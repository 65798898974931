import { useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'

const animateInContainer = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0) grayscale(0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px) grayscale(100%);
  }
`

const animateOutContainer = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px) grayscale(100%);
  }
  100% {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0) grayscale(0);
  }
`

const animateIn = keyframes`
  0% { transform: translateY(-1000%) }
  50% { transform: translateY(-1000%) }
  100% { transform: translateY(0) }
`

const animateOut = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-1000%); }
  100% { transform: translateY(-1000%); }
`

export const Toast = styled.div`
  background-color: ${props => props.theme.modal.toastBackgroundColor};
  color: ${props => props.theme.modal.toastTextColor};
  padding: 10px;
  margin: 10px;
  margin-bottom: ${(props: any) => (props.compact ? 0 : 10)}px;
  border-radius: 8px;
  cursor: ${(props: any) => (props.interactive ? 'pointer' : 'auto')};
  &:hover {
    background-color: ${(props: any) =>
      props.interactive
        ? props.theme.modal.toastHighlightedBackgroundColor
        : 'auto'};
  }
  border: ${(props: any) =>
    props.active
      ? `1px solid ${props.theme.modal.toastHighlightedBorderColor}`
      : 'none'};
` as any

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px) grayscale(100%);
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  animation-name: ${animateInContainer};
  animation-duration: 400ms;
  &.animateOut {
    animation-name: ${animateOutContainer};
    animation-duration: 400ms;
    animation-fill-mode: forwards;
  }
`

const ModalContainer = styled.div`
  overflow-y: scroll;
  max-height: calc(100% - 40px);
  width: 100%;
  max-width: 575px;
  max-height: 90%;
  background-color: ${props => props.theme.modal.backgroundColor};
  border-radius: 16px;
  padding: 20px;
  padding-top: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  animation-name: ${animateIn};
  animation-duration: 600ms;
  .animateOut & {
    animation-name: ${animateOut};
    animation-duration: 400ms;
    animation-fill-mode: forwards;
  }
`

export const ModalButton = styled.button`
  background-color: ${props => props.theme.modal.modalButtonBackgroundColor};
  color: ${props => props.theme.modal.modalButtonTextColor};
  border: none;
  padding: 13px;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background-color: ${props =>
      props.theme.modal.modalButtonHoverBackgroundColor};
  }
  &:active {
    background-color: ${props =>
      props.theme.modal.modalButtonActiveBackgroundColor};
  }
  opacity: ${props => (props.disabled ? '0.5' : '1')};

  display: flex;
  justify-content: center;
  align-items: center;

  /* Icons */
  svg {
    margin: 0 4px;
  }
`

const MainFooterContainerStyle = styled.div`
  align-items: center;
  margin-top: 38px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #d6e3f5;
  border-top: 1px solid #c3d2e7;
  text-align: center;
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: rgba(214, 227, 245, 0.8);
  backdrop-filter: blur(10px) grayscale(100%);
  transform: translateX(-20px) translateY(20px);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  width: calc(100% + 40px);
`

const MainFooterStyle = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 545px;
`

export const MainFooter = ({ children }: any) => (
  <MainFooterContainerStyle>
    <MainFooterStyle>{children}</MainFooterStyle>
  </MainFooterContainerStyle>
)

export const MainFooterOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  flex: 1;
  color: #4d5f76;
  & > svg {
    width: 17px;
  }
`

export const InputWithButton = styled.div`
  display: flex;
  & > input {
    flex-grow: 2;
  }
`

export const SaveButton = styled.button`
  width: 70px;
`

export const MainHeader = styled.div`
  font-size: 18px;
  font-weight: 600;
  padding-left: 25px;
  z-index: 2;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.modal.sectionBorderColor};
  color: ${props => props.theme.modal.sectionHeaderColor};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  left: 0;
  width: calc(100% + 15px);
  top: 0;
  transform: translateX(-20px);
  position: sticky;
  background-color: ${props => props.theme.modal.sectionBackgroundColor};
`

interface OptionProps {
  solo?: boolean
}

export const Option = styled.fieldset<OptionProps>`
  background-color: ${props => props.theme.modal.sectionBackgroundColor};
  border: 1px solid ${props => props.theme.modal.sectionBorderColor};
  border-radius: 10px;
  display: block;
  padding: 5px;
  position: relative;
  margin-top: 65px;
  &:first-of-type {
    margin-top: ${props => (props.solo ? 23 : 40)}px;
  }
  & input {
    border: none;
    padding: 10px;
    font-size: 13px;
    background-color: ${props => props.theme.global.inputBackgroundColor};
    color: ${props => props.theme.global.inputTextColor};
    &:focus {
      outline: none;
    }
  }
  & textarea {
    background-color: ${props => props.theme.global.inputBackgroundColor};
    color: ${props => props.theme.global.inputTextColor};
  }
  & button {
    border: none;
    font-size: 13px;
    border-radius: 5px;
    background-color: ${props =>
      props.theme.modal.sectionButtonBackgroundColor};
    color: ${props => props.theme.modal.sectionHeaderColor};
  }
  ${props =>
    props.solo &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
    & > h3 {
      position: relative;
      top: auto;
      left: auto;
      padding: 0px;
      margin: 0px;
      flex: 2;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 12px;
    }
    & .toggle {
      margin-right: 4px;
    }
  `}
`

export const OptionHeader = styled.h3`
  padding: 0px;
  margin: 0px;
  font-weight: 600;
  font-size: 14px;
  display: block;
  position: absolute;
  left: 7px;
  top: -33px;
  text-align: left;
  color: ${props => props.theme.modal.sectionHeaderColor};
`

export const ErrorMsg = styled.div`
  color: #7a2727;
  position: relative;
  font-size: 13px;
  background-color: #ffcaca;
  &::before {
    content: '⛔️';
    position: absolute;
    right: 12px;
    top: 6px;
  }
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px;
`

function Modal({ show, children, ...rest }: any) {
  const timeout = useRef(0 as any)
  const [reallyHide, setReallyHide] = useState(true)
  useEffect(() => {
    clearTimeout(timeout.current)
    if (show) {
      setReallyHide(false)
      return
    }
    timeout.current = setTimeout(() => setReallyHide(true), 500)
    return () => clearTimeout(timeout.current)
  }, [show])

  if (reallyHide) return null

  return (
    <Container {...rest} className={show ? 'show' : 'animateOut'}>
      <ModalContainer onClick={e => e.stopPropagation()}>
        {children}
      </ModalContainer>
    </Container>
  )
}

export default Modal
