import react, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Trans } from 'react-i18next'
import { ExternalLink } from 'react-feather'

const Container = styled.div<any>`
  background-color: ${props => props.theme.submissionView.openArticleButtonBackgroundColor};
  border: 1px solid ${props => props.theme.submissionView.openArticleBorderColor};
  border-radius: 10px;
  margin-top: 10px;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  & img { display: none }
  & .even {
    float: left;
    margin-left: 0;
  }
  & .odd {
    float: right;
    margin-right: 0;
  }
  iframe {
    border: none;
    max-width: 100%;
    border-radius: 5px;
  }
  Header { display: none }
  figure { margin: 0 }
  svg { display: none }

  .open-original {
    cursor: pointer;
    display: flex;
    font-size: ${props => props.theme.defaultFontSize - 1}px;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    padding-top: ${props => props.hasContent ? 10 : 0}px;
    svg {
      display: inline;
      width: 16px;
      padding-right: 5px;
    }
  }
`

const BodyHTML = styled.div`
  max-height: 600px;
  overflow: auto;
`

async function fetchArticle(url: string) {
  return await fetch(`${process.env.REACT_APP_SYNC_SERVER_HOST}/parseArticle?url=${url}`)
    .then(response => response.json())
}

interface ArticleText {
  title: string
  content: string
}

export default react.memo(({ url }: { url: string }) => {
  const [article, setArticle] = useState<ArticleText>({ title: "", content: "" })
  useEffect(() => {
    fetchArticle(url).then(setArticle)
  }, [url])
  let parity = 0
  const content = article.content
    .replace(/<a /g, '<a target="_blank" rel="noopener noreferrer"')
    .replace(/<img/g, () => `<img class="${parity++ % 2 ? 'even' : 'odd'}"`)
    .replace(/advertisement(\.|)/gi, '')
  return <Container hasContent={!!article.content.length}>
    <BodyHTML dangerouslySetInnerHTML={{ __html: content }} />
    <a href={url} target="_blank" rel="noopener noreferrer" className="open-original">
      <ExternalLink/>
      <Trans>Open Original Article</Trans>
    </a>
  </Container>
})