import events from '@analytics/events'
import OAuth from '@api/oauth'
import { ColumnProps } from '@components/Column'
import Modal, { MainHeader, ModalButton, Option } from '@components/Modal'
import OptionPicker from '@components/OptionPicker'
import { FakeAvatar } from '@components/Sidebar'
import { DefaultAppSettings, useAuth } from '@contexts/AuthContext'
import { BannerType, useBanner } from '@contexts/BannerContext'
import { useSnoowrap } from '@contexts/SnoowrapContext'
import { themes, useTheme } from '@contexts/ThemeContext'
import { useQueryClient } from '@tanstack/react-query'
import * as Fathom from 'fathom-client'
import { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { RedditUser } from 'snoowrap'
import styled from 'styled-components'
import { v4 as uuidV4 } from 'uuid'

const AvatarContainer = styled.div`
  height: 40px;
  margin-right: 10px;
  display: flex;
  align-items: center;

  & > img {
    height: 100%;
    object-fit: contain;
    border-radius: 20px;
    margin-right: 10px;
  }
  & > div {
    margin-right: 10px;
  }
`

const LoadingAvatar = styled(Skeleton)`
  height: 40px;
  width: 40px;
  margin-right: 10px;
`

const StyledModalButton = styled(ModalButton)`
  margin-top: 40px;
`

interface ManageUsersProps {
  onDismiss: () => void
  show: boolean
}

function ManageUsers({ onDismiss, show }: ManageUsersProps) {
  const auth = useAuth()
  const { setTheme } = useTheme()
  const banner = useBanner()
  const queryClient = useQueryClient()
  const snoowrap = useSnoowrap()

  const [isAddingUser, setIsAddingUser] = useState<string | null>()

  const removeAccount = (id: string) => () => {
    if (window.confirm('Are you sure you want to remove this account?')) {
      auth.userManager.remove(id)
    }
  }

  const accountRows = auth.users.map(account => {
    const avatarUrl = account.redditAccount?.icon_img
    return {
      text: (
        <AvatarContainer>
          {avatarUrl ? <img alt="" src={avatarUrl} /> : <FakeAvatar />}
          {account.redditAccount?.name ?? 'Anonymous'}
        </AvatarContainer>
      ),
      id: account.redditAccount?.id ?? 'guest',
      removeable: !!account.redditAccount && account.redditAccount.id !== auth.currentUser.redditAccount?.id,
      onRemove: account.redditAccount && removeAccount(account.redditAccount.id)
    }
  })

  const startOAuth = async () => {
    const oauthWindow = window.open('', 'oauthwindow', 'menubar=1,resizable=1')
    if (!oauthWindow) {
      return
    }

    Fathom.trackGoal(events.startAddAccount, 0)
    const { refreshkey, name } = await OAuth.start(oauthWindow)
    setIsAddingUser(name)
    if (refreshkey != null && name) {
      // TODO: Move this to some app warmup helper
      const client = snoowrap.setClient(refreshkey)

      Fathom.trackGoal(events.finishAddAccount, 0)

      // we need to wait a second so that we don't get rate limited
      setTimeout(async () => {
        // get account subreddits to populate subreddits
        const subreddits = client!.getSubscriptions().fetchAll()
        const me = await new Promise(resolve => {
          client!
            .getUser(name)
            .fetch()
            .then(user => {
              resolve(user)
            })
        })

        const columns: ColumnProps[] = await subreddits.map(subreddit => {
          return { id: uuidV4(), subreddit: subreddit.display_name }
        })

        await auth.userManager.addUser({
          refreshKey: refreshkey,
          settings: DefaultAppSettings,
          columns,
          hasSeenNUX: false,
          redditAccount: me as RedditUser
        })
        setIsAddingUser(null)
      }, 1000)
    } else {
      Fathom.trackGoal(events.failedAddAccount, 0)
      setIsAddingUser(null)
    }
  }

  const addingUserRow = {
    text: (
      <AvatarContainer>
        <LoadingAvatar circle />
        Adding {isAddingUser}...
      </AvatarContainer>
    ),
    id: 'adding-user'
  }

  return (
    <Modal onClick={onDismiss} show={show}>
      <MainHeader>Manage Accounts</MainHeader>
      {auth.users.length && (
        <Option solo>
          <OptionPicker
            options={
              isAddingUser ? [...accountRows, addingUserRow] : accountRows
            }
            selectedId={auth.currentUser?.redditAccount?.id ?? 'guest'}
            onChange={(id, _) => {
              let account = auth.users.find(
                user => user.redditAccount?.id === id
              )
              if (!account)
                account = auth.users.find(user => !user.refreshKey.length)!
              auth.userManager.setCurrentUser(account)
              setTheme(
                account.settings.darkModeEnabled
                  ? themes.themeDark
                  : themes.themeDefault
              )
              banner.showBanner({
                message: `Logged in to ${account.redditAccount?.name}!`,
                type: BannerType.success
              })
              queryClient.clear()
              onDismiss()
            }}
          />
        </Option>
      )}
      <StyledModalButton onClick={startOAuth}>Add Account</StyledModalButton>
    </Modal>
  )
}

export default ManageUsers
