import AuthedOnly from '@components/AuthedOnly'
import Modal from '@components/Modal'
import { ReplyModal } from '@components/ReplyModal'
import { ReportModal, ReportModalDismissType } from '@components/ReportModal'
import { useApp } from '@contexts/AppContextProvider'
import { BannerType, useBanner } from '@contexts/BannerContext'
import useLocalStorage from '@hooks/useLocalStorage'
import { getCommentKey } from '@queries/index'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import blendColors from '@utilities/blendColors'
import { killClickHandler } from '@utilities/events'
import {
  numberShortener,
  relativeDate,
  replaceRedditLinksWithReditr
} from '@utilities/formatters'
import copy from 'copy-to-clipboard'
import { SyntheticEvent, useRef, useState } from 'react'
import {
  ArrowDown,
  ArrowUp,
  Code,
  Copy,
  Flag,
  MinusSquare,
  MoreHorizontal,
  PlusSquare,
  Save
} from 'react-feather'
import { useParams } from 'react-router-dom'
import { Comment } from 'snoowrap'
import styled from 'styled-components'

interface ContainerProps {
  focused: boolean
  indent: number
}

const Container = styled.div<ContainerProps>`
  position: relative;
  background-color: ${props =>
    props.focused ? 'rgba(205, 227, 249, 0.5)' : 'transparent'};
  padding: 10px;
  margin: 0;
  padding-right: 0;
  box-sizing: border-box;
  border-left: 2px solid
    ${props =>
      props.theme.submissionView.commentBackgroundColors[
        props.indent % props.theme.submissionView.commentBackgroundColors.length
      ]};
  background-color: ${props =>
    blendColors(
      props.theme.submissionView.commentBackgroundColors[
        props.indent % props.theme.submissionView.commentBackgroundColors.length
      ],
      props.theme.submissionView.commentBaseBackgroundColor,
      0.98
    )};
  color: ${props =>
    blendColors(
      props.theme.submissionView.commentBackgroundColors[
        props.indent % props.theme.submissionView.commentBackgroundColors.length
      ],
      props.theme.submissionView.commentBaseTextColor,
      0.7
    )};
  *::selection {
    color: white;
    background: ${props =>
      blendColors(
        props.theme.submissionView.commentBackgroundColors[
          props.indent %
            props.theme.submissionView.commentBackgroundColors.length
        ],
        props.theme.submissionView.commentBaseTextColor,
        0.4
      )};
  }

  &:hover > div > .comment-options {
    opacity: 1;
  }

  &.last {
    border-bottom-left-radius: 4px;
  }
  &.first {
    border-top-left-radius: 4px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${props =>
      blendColors(
        props.theme.submissionView.commentBackgroundColors[
          props.indent %
            props.theme.submissionView.commentBackgroundColors.length
        ],
        props.theme.submissionView.commentBaseBackgroundColor,
        0.85
      )};
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${props =>
      blendColors(
        props.theme.submissionView.commentBackgroundColors[
          props.indent %
            props.theme.submissionView.commentBackgroundColors.length
        ],
        props.theme.submissionView.commentBaseBackgroundColor,
        0.85
      )};
  }
  ${props =>
    !props.focused
      ? ''
      : `
    border-left: 2px solid ${
      props.theme.submissionView.highlightedCommentBackgroundColor
    };
    background-color: ${blendColors(
      props.theme.submissionView.highlightedCommentBackgroundColor,
      props.theme.submissionView.commentBaseBackgroundColor,
      0.98
    )};
    color: ${blendColors(
      props.theme.submissionView.highlightedCommentBackgroundColor,
      props.theme.submissionView.commentBaseTextColor,
      0.7
    )};
    &:before { background-color: ${blendColors(
      props.theme.submissionView.highlightedCommentBackgroundColor,
      props.theme.submissionView.commentBaseBackgroundColor,
      0.85
    )} }
    &:after { background-color: ${blendColors(
      props.theme.submissionView.highlightedCommentBackgroundColor,
      props.theme.submissionView.commentBaseBackgroundColor,
      0.85
    )} }
    *::selection {
      color: ${props.theme.submissionView.commentBaseTextColor};
      background: ${blendColors(
        props.theme.submissionView.highlightedCommentBackgroundColor,
        props.theme.submissionView.commentBaseTextColor,
        0.4
      )};
    }
  `}
  ${props =>
    props.indent === 0
      ? `
    margin-bottom: 9px;
    border-radius: 4px;
    border-right: 1px solid ${blendColors(
      props.theme.submissionView.commentBackgroundColors[
        props.indent % props.theme.submissionView.commentBackgroundColors.length
      ],
      props.theme.submissionView.commentBaseBackgroundColor,
      0.85
    )};
    &:before { right: 1px }
    &:after { right: 1px }
  `
      : ''}
`

const Header = styled.div`
  display: flex;
  align-items: center;
`

interface AuthorProps {
  isSubmitter: boolean
  isMod: boolean
  indent: number
}

const Author = styled.span<AuthorProps>`
  font-weight: bold;
  margin-left: 6px;
  ${props =>
    !props.isSubmitter && !props.isMod
      ? ''
      : `
    background: ${blendColors(
      props.isMod
        ? props.theme.submissionView.modCommentAuthorBackgroundColor
        : props.theme.submissionView.submitterCommentAuthorBackgroundColor,
      '#000000',
      0.4
    )};
    color: white;
    padding-left: 3px;
    padding-right: 4px;
    border-radius: 4px;
    &::before {
      content: "${props.isSubmitter ? 'OP' : 'MOD'} ";
      font-weight: 400;
      opacity: 0.7;
    }
  `};
  font-size: 12px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const CommentOptions = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  opacity: 0;
  height: 19px;
  user-select: none;
  .save-action::before {
    content: 'save';
  }
  .copy-action::before {
    content: 'copy';
  }
  .report-action::before {
    content: 'report';
  }
  .source-action::before {
    content: 'source';
  }
`

const ReplyButton = styled.button`
  border: none;
  color: inherit;
  background: transparent;
  outline: none;
  font-weight: 600;
  font-size: 12px;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const ShowMoreCommentOptions = styled.div`
  transform: translateY(3px);
  cursor: pointer;
  & svg {
    width: 19px;
    height: 19px;
  }
`

const CommentActionsContainer = styled.div`
  display: flex;
  align-items: center;
`

const Body = styled.div<any>`
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 8px;

  & > p {
    word-break: break-word;
    margin-top: 0;
    padding-top: 0;
  }

  & > p .md > p {
    margin-top: 5px;
  }

  .md p {
    margin-right: 8px;
  }

  &:hover {
    border-left-color: rgba(205, 227, 249, 1);
  }

  & > div {
    margin-left: 4px;
  }

  blockquote {
    border-left: 2px solid
      ${props =>
        blendColors(
          props.theme.submissionView.commentBackgroundColors[
            props.indent %
              props.theme.submissionView.commentBackgroundColors.length
          ],
          props.theme.submissionView.commentBaseTextColor,
          0.4
        )};
    background-color: ${props =>
      blendColors(
        props.theme.submissionView.commentBackgroundColors[
          props.indent %
            props.theme.submissionView.commentBackgroundColors.length
        ],
        props.theme.submissionView.commentBaseBackgroundColor,
        0.9
      )};
    padding: 6px;
    border-radius: 2px;
    margin-left: 10px;
    p {
      margin: 0;
      padding: 0;
    }
  }
`

const ScoreContainer = styled.div<any>`
  display: flex;
  align-items: center;
  border: 1px solid
    ${props => props.theme.submissionView.commentsContainerBorderColor};
  padding: 0 4px;
  border-radius: 4px;
  color: ${props =>
    blendColors(
      props.theme.submissionView.commentBackgroundColors[
        props.indent % props.theme.submissionView.commentBackgroundColors.length
      ],
      props.theme.submissionView.commentBaseTextColor,
      0.4
    )};
  ${props =>
    !props.focused
      ? ''
      : `
    color: ${blendColors(
      props.theme.submissionView.highlightedCommentBackgroundColor,
      props.theme.submissionView.commentBaseTextColor,
      0.4
    )};
  `}
`

interface VoteProps {
  active: boolean | null
}

const UpVote = styled.span<VoteProps>`
  background-color: ${props =>
    props.active ? 'rgba(255, 139, 90, 0.1)' : 'transparent'};
  width: 13px;
  height: 13x;
  color: ${props =>
    props.active ? props.theme.submissionView.upvoteColor : 'auto'};
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all ease-in 0.2s;
  cursor: pointer;
`

const DownVote = styled.span<VoteProps>`
  background-color: ${props =>
    props.active ? 'rgba(38, 37, 123, 0.05)' : 'transparent'};
  color: ${props =>
    props.active ? props.theme.submissionView.downvoteColor : 'auto'};
  width: 13px;
  height: 13px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all ease-in 0.2s;
  cursor: pointer;
`

const Score = styled.div<any>`
  font-size: 12px;
  padding: 0 2px;
  color: ${props =>
    props.upvoted
      ? props.theme.submissionView.upvoteColor
      : props.downvoted
      ? props.theme.submissionView.downvoteColor
      : 'inherit'};
`

const TimeAgo = styled.span`
  font-size: 12px;
  color: #545454;
  margin-left: 4px;
`

const ExpandReplies = styled.div`
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`

interface CommentActionProps {
  active?: boolean
}

const CommentAction = styled.div<CommentActionProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => (props.active ? '#0e0e0e' : 'inherit')};
  cursor: pointer;
  margin-left: 8px;
  margin-top: 2px;

  &:hover {
    color: #0e0e0e;
  }
`

const ToggleComment = styled.span`
  cursor: pointer;
  margin-left: 6px;
  display: flex;
`

interface CommentViewProps {
  comment: Comment
  hideReplies?: boolean
  indent?: number
  className?: string | null
}

enum CommentActionType {
  UpVote,
  DownVote,
  ExpandReplies,
  Save,
  Report
}

type CommentMutationProps = {
  action: CommentActionType
  comment: Comment
}

interface CommentURLParams {
  focusedCommentID?: string
}

function CommentView({
  comment,
  hideReplies,
  indent = 0,
  className = ''
}: CommentViewProps) {
  const app = useApp()
  const banner = useBanner()
  const queryClient = useQueryClient()
  const { focusedCommentID } = useParams<CommentURLParams>()

  const { id } = comment

  const commentQuery = getCommentKey(id)

  const [isExpandingReplies, setIsExpandingReplies] = useState(false)
  const [isReplying, setIsReplying] = useState(false)
  const [isReporting, setIsReporting] = useState(false)
  const [viewSource, setViewSource] = useState(false)
  const [newComments, setNewComments] = useState<Comment[]>([])
  const [hasScrolledToComment, setHasScrolledToComment] = useState(false)
  const [showMoreOptions, setShowMoreOptions] = useState(false)

  const getFreshCommentData: () => Comment = () => {
    return queryClient.getQueryData(commentQuery) ?? comment
  }

  const standardErrorHandling = (e: any) => {
    switch (e.statusCode) {
      case 400:
        banner.showBanner({
          message: 'Error ocurred, try again..',
          type: BannerType.error
        })
        break
      case 429:
        banner.showBanner({
          message: 'You have been rate limited, try again in a few seconds.',
          type: BannerType.error
        })
        break
      default:
        banner.showBanner({
          message: 'Something went wrong with your request.',
          type: BannerType.error
        })
        break
    }

    // reset to older state
    queryClient.setQueryData(commentQuery, comment)
  }

  const commentActionMutation = useMutation(
    ({ action, comment }: CommentMutationProps) => {
      switch (action) {
        case CommentActionType.ExpandReplies:
          return comment.expandReplies()
        case CommentActionType.Save:
          return comment.saved ? comment.unsave() : comment.save()
        case CommentActionType.UpVote:
          return comment.likes === true ? comment.unvote() : comment.upvote()
        case CommentActionType.DownVote:
          return comment.likes === false ? comment.unvote() : comment.downvote()
      }

      return comment
    },
    {
      onMutate: ({ action, comment }: CommentMutationProps) => {
        switch (action) {
          case CommentActionType.Save:
            comment.saved = !comment.saved
            break
          case CommentActionType.UpVote:
            if (comment.likes === true) {
              comment.likes = null
              comment.score -= 1
            } else if (comment.likes === false) {
              comment.likes = true
              comment.score += 2
            } else {
              comment.likes = true
              comment.score += 1
            }
            break
          case CommentActionType.DownVote:
            if (comment.likes === false) {
              comment.likes = null
              comment.score += 1
            } else if (comment.likes === true) {
              comment.likes = true
              comment.score -= 2
            } else {
              comment.likes = false
              comment.score -= 1
            }
        }
        return comment
      },
      onSuccess: newComment => {
        queryClient.setQueryData(commentQuery, newComment)
      },
      onSettled: () => {
        setIsExpandingReplies(false)
      },
      onError: standardErrorHandling
    }
  )

  const onClickMoreReplies = (e: SyntheticEvent) => {
    killClickHandler(e)
    setIsExpandingReplies(true)
    commentActionMutation.mutate({
      action: CommentActionType.ExpandReplies,
      comment: getFreshCommentData()
    })
  }

  const onClickSave = (e: SyntheticEvent) => {
    killClickHandler(e)
    commentActionMutation.mutate({
      action: CommentActionType.Save,
      comment: getFreshCommentData()
    })
    banner.showBanner({
      type: BannerType.success,
      message: 'Comment saved!'
    })
  }

  const onClickCopy = (e: SyntheticEvent) => {
    killClickHandler(e)
    copy(comment.body)
    banner.showBanner({
      type: BannerType.success,
      message: 'Copied comment!'
    })
  }

  const onClickSource = (e: SyntheticEvent) => {
    killClickHandler(e)
    setViewSource(true)
  }

  const onDismissSource = (e: SyntheticEvent) => {
    killClickHandler(e)
    setViewSource(false)
  }

  const onClickReply = (e: SyntheticEvent) => {
    killClickHandler(e)
    setIsReplying(true)
  }

  const onClickAuthor = (author: string) => (e: SyntheticEvent) => {
    killClickHandler(e)
    app.showUserModal(true, author)
  }

  const dismissReplyModal: ReportModalDismissType = (e, newComment): void => {
    if (e != null) {
      killClickHandler(e)
    }
    setIsReplying(false)
    if (!!newComment) {
      setNewComments([newComment as unknown as Comment, ...newComments])
    }
  }

  const onClickReport = (e: SyntheticEvent) => {
    killClickHandler(e)
    setIsReporting(true)
  }

  const dismissReportModal = (e: SyntheticEvent) => {
    killClickHandler(e)
    setIsReporting(false)
  }

  const commentData = commentActionMutation.data ?? comment
  const {
    author,
    is_submitter,
    body_html,
    score,
    likes,
    replies,
    saved,
    distinguished,
    created_utc,
    collapsed
  } = commentData
  const isModOrAdmin = ['moderator', 'admin'].includes(distinguished ?? '')

  const hasMoreReplies = replies && replies.length === 0 && !replies.isFinished

  const [hideComment, setHideComment] = useLocalStorage(
    `comment_hidden-${comment.name}`,
    collapsed,
    1
  )

  // Logic that determines if the comment is focused
  const isFocused = focusedCommentID 
    ? comment.name.indexOf(focusedCommentID) > -1
    : false
  const ref = useRef<HTMLDivElement>(null)
  if (!hasScrolledToComment && isFocused) {
    setHasScrolledToComment(true)
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, 100)
  }
  return (
    <Container
      className={`comment ${className}`}
      indent={indent}
      ref={ref}
      focused={isFocused}
    >
      <Header>
        <ScoreContainer
          indent={indent}
          focused={isFocused}
          onClick={killClickHandler}
        >
          <UpVote
            active={likes}
            onClick={e => {
              killClickHandler(e)
              commentActionMutation.mutate({
                action: CommentActionType.UpVote,
                comment: getFreshCommentData()
              })
            }}
          >
            <ArrowUp />
          </UpVote>
          <Score upvoted={likes} downvoted={likes === false}>
            {numberShortener(score, 1)}
          </Score>
          <DownVote
            active={likes === false}
            onClick={e => {
              killClickHandler(e)
              commentActionMutation.mutate({
                action: CommentActionType.DownVote,
                comment: getFreshCommentData()
              })
            }}
          >
            <ArrowDown />
          </DownVote>
        </ScoreContainer>
        <Author
          indent={indent}
          isSubmitter={is_submitter}
          isMod={isModOrAdmin}
          onClick={onClickAuthor(author.name)}
        >
          {author.name}
        </Author>
        <TimeAgo>{relativeDate(new Date(created_utc * 1000))}</TimeAgo>
        <ToggleComment onClick={() => setHideComment(!hideComment)}>
          {hideComment ? <PlusSquare size={16} /> : <MinusSquare size={16} />}
        </ToggleComment>
      </Header>
      {!hideComment && (
        <Body indent={indent} className="comment-body">
          <CommentOptions className="comment-options">
            {showMoreOptions && (
              <CommentActionsContainer>
                <AuthedOnly>
                  <CommentAction
                    active={saved}
                    onClick={onClickSave}
                    className="hover-tooltip save-action"
                  >
                    <Save size={14} />
                  </CommentAction>
                </AuthedOnly>
                <AuthedOnly>
                  <CommentAction
                    onClick={onClickReport}
                    className="hover-tooltip report-action"
                  >
                    <Flag size={14} />
                  </CommentAction>
                </AuthedOnly>
                <CommentAction
                  onClick={onClickCopy}
                  className="hover-tooltip copy-action"
                >
                  <Copy size={14} />
                </CommentAction>
                <CommentAction
                  onClick={onClickSource}
                  className="hover-tooltip source-action"
                >
                  <Code size={14} />
                </CommentAction>
              </CommentActionsContainer>
            )}
            {!showMoreOptions && (
              <ShowMoreCommentOptions
                onClick={() => setShowMoreOptions(!showMoreOptions)}
              >
                <MoreHorizontal />
              </ShowMoreCommentOptions>
            )}
            <AuthedOnly>
              <ReplyButton onClick={onClickReply}>reply</ReplyButton>
            </AuthedOnly>
          </CommentOptions>
          <p
            dangerouslySetInnerHTML={{
              __html: replaceRedditLinksWithReditr(body_html)
            }}
          />
          {!hideReplies &&
            replies.map((reply, i) => (
              <CommentView
                className={
                  (i === 0 ? 'first ' : ' ') +
                  (i === replies.length - 1 ? 'last' : '')
                }
                indent={indent + 1}
                key={reply.id}
                comment={reply}
              />
            ))}
          {!hideReplies &&
            newComments.map((newComment, i) => (
              <CommentView
                className={
                  (i === 0 ? 'first ' : ' ') +
                  (i === newComments.length - 1 ? 'last' : '')
                }
                indent={indent + 1}
                key={newComment.id}
                comment={newComment}
              />
            ))}
        </Body>
      )}
      {isReplying && (
        <ReplyModal content={comment} dismiss={dismissReplyModal} />
      )}
      {isReporting && (
        <ReportModal content={comment} dismiss={dismissReportModal} />
      )}
      {viewSource && (
        <SourceView text={comment.body} dismiss={onDismissSource} />
      )}
      {hasMoreReplies && (
        <ExpandReplies onClick={onClickMoreReplies}>
          {isExpandingReplies ? 'Loading...' : 'View more replies'}
        </ExpandReplies>
      )}
    </Container>
  )
}

interface SourceViewProps {
  text: string
  dismiss: (e: SyntheticEvent) => void
}

function SourceView({ text, dismiss }: SourceViewProps) {
  return (
    <Modal show={true} onClick={dismiss}>
      <textarea readOnly value={text} />
    </Modal>
  )
}

export { CommentView }
