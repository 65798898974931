import { Column, ColumnProps } from '@components/Column'
import { SubmissionCardView } from '@components/SubmissionCard'
import { useAuth } from '@contexts/AuthContext'
import { useEffect, useRef } from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { v4 as uuidV4 } from 'uuid'

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  right: 0;
  background-color: ${props => props.theme.global.backgroundColor};
  color: ${props => props.theme.column.columnHeaderTextColor};
  z-index: 9999;
`

const SubmissionContent = styled.div`
  flex-grow: 1;
  overflow: auto;
  height: 100vh;
  box-sizing: border-box;
`

const SubmissionContentMobile = styled.div`
  flex-grow: 1;
  overflow: auto;
  height: 100vh;
  overflow-x: hidden;
`

interface SubmissionURLParams {
  subreddit: string
  id: string
  columnID?: string
}

interface LocationState {
  sourceQuery?: string | undefined
}

function SubmissionView() {
  const auth = useAuth()
  const location = useLocation<LocationState>()
  const { subreddit, id, columnID } = useParams<SubmissionURLParams>()

  let columnProps: ColumnProps = {
    id: columnID ?? uuidV4(),
    inSubmissionView: true,
    subreddit,
    activeSubmissionID: id
  }

  // if we have a columnID it means that we're using a custom column like a multi reddit
  if (columnID) {
    columnProps = { ...columnProps, ...auth.columnManager.get(columnID) }
    delete columnProps.subreddit
  }

  useEffect(() => {
    containerRef.current?.scrollTo(0, 0)
  }, [location])

  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <BrowserView>
        <Container>
          <Column {...columnProps} />
          <SubmissionContent ref={containerRef}>
            <SubmissionCardView
              showHelmet={true}
              submissionID={id}
              embedded={false}
            />
          </SubmissionContent>
        </Container>
      </BrowserView>
      <MobileView>
        <Container>
          <SubmissionContentMobile>
            <SubmissionCardView submissionID={id} embedded={false} />
          </SubmissionContentMobile>
        </Container>
      </MobileView>
    </>
  )
}
export default SubmissionView
