import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Check, ChevronDown, Trash } from 'react-feather'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

const Row = styled.div`
  cursor: pointer;
  padding-left: 13px;
  padding-right: 13px;
  position: relative;
  display: flex;
  align-items: center;
  & + & {
    border-top: 1px solid ${props => props.theme.modal.sectionBorderColor};
  }
`

const Title = styled.div`
  padding-top: 18px;
  padding-bottom: 18px;
  text-align: left;
  color: ${props => props.theme.modal.sectionTextColor};
  font-size: 14px;
  font-weight: 600;
  flex-grow: 2;
`

const Selected = styled.div`
  background-color: ${props => props.theme.modal.activeTagBackgroundColor};
  color: ${props => props.theme.modal.activeTagColor};
  float: right;
  padding: 6px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  font-weight: bold;
  .checkmark {
    width: 15px;
    height: 15px;
    transform: translateY(2px);
  }
`

const Removeable = styled(Selected)`
  background-color: ${props => props.theme.modal.destructiveButtonBackground};
  color: ${props => props.theme.modal.destructiveButtonText};
  margin-left: 6px;

  & > svg {
    width: 15px;
    height: 15px;
    transform: translateY(2px);
  }
`

interface OptionPickerOption {
  text: any
  id?: any
  selectedSymbol?: any
  removeable?: boolean
  onRemove?: () => void
}

type OptionPickerProps = {
  multiselect?: boolean
  maxAllowed?: number
  selectedSymbol?: string
  options: Array<OptionPickerOption> | Array<string>
  selectedIds?: Set<any>
  selectedId?: any
  onChange?: (changedId: any, newValue: boolean) => void
}

export default function OptionPicker(props: OptionPickerProps) {
  const selectedIds = props.selectedIds ?? new Set([props.selectedId])
  let options = props.options as Array<OptionPickerOption>
  if (typeof props.options[0] === 'string') {
    options = (props.options as any).map((text: string) => ({ text }))
  }

  const onRemove = (option: OptionPickerOption) => (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    option.onRemove && option.onRemove()
  }

  return (
    <div className="option-picker">
      {options.map(option => (
        <Row
          className="row"
          key={option.id ?? option.text}
          onClick={e => {
            e.preventDefault()
            props.onChange &&
              props.onChange(
                option.id ?? option.text,
                props.selectedIds != null &&
                  !props.selectedIds.has(option.id ?? option.text)
              )
          }}
        >
          <Title className="title">
            <Trans>{option.text}</Trans>
          </Title>
          {(selectedIds == null || selectedIds.has(option.id ?? option.text)) &&
            (option.selectedSymbol ?? props.selectedSymbol ?? (
              <Selected>
                <Check className="checkmark" />
              </Selected>
            ))}
          {option.removeable && (
            <Removeable onClick={onRemove(option)}>
              <Trash />
            </Removeable>
          )}
        </Row>
      ))}
    </div>
  )
}

const DropdownContainer = styled.div<any>`
  position: relative;
  display: inline-block;
  button {
    position: relative;
    cursor: pointer;
    color: ${props => props.theme.dropdown.color};
    background-color: ${props => props.theme.dropdown.backgroundColor};
    border: 1px solid
      ${props =>
        props.animateIn && !props.animateOut
          ? props.theme.dropdown.activeBorderColor
          : props.theme.dropdown.borderColor};
    border-radius: 5px;
    padding: 9px;
    padding-left: 10px;
    padding-right: 30px;
    transition: 200ms ease color, 200ms ease box-shadow;

    .arrow {
      position: absolute;
      width: 17px;
      height: 17px;
      right: 5px;
      transition: 200ms ease transform;
      transform: rotate(
        ${props => (props.animateIn && !props.animateOut ? 180 : 0)}deg
      );
    }
  }
  .options-container {
    position: absolute;
    z-index: 9999;
    margin-left: -50px;
    width: calc(100% + 100px);
    text-align: center;
  }
  .option-picker {
    background-color: ${props => props.theme.dropdown.backgroundColor};
    border: 1px solid ${props => props.theme.dropdown.borderColor};
    border-radius: 7px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.05);
    transition: 200ms ease transform, 200ms ease opacity;
    transform: scale(
      ${props => (props.animateIn && !props.animateOut ? 1 : 0)}
    );
    transform-origin: 50% 0;
    opacity: ${props => (props.animateIn && !props.animateOut ? 1 : 0)};

    .title {
      font-weight: normal;
    }

    .row {
      &:hover {
        background-color: ${props =>
          props.theme.modal.activeItemBackgroundColor};
      }
    }
  }
`

type DropdownOptionPickerProps = {
  placeholder?: string
  multiselect?: boolean
  maxAllowed?: number
  selectedSymbol?: string
  options: Array<OptionPickerOption> | Array<string>
  selectedIds?: Set<any>
  selectedId?: any
  onChange?: (changedId: any, newValue: boolean) => void
}

export function DropdownOptionPicker(props: DropdownOptionPickerProps) {
  const [visible, setVisible] = useState(false)
  const [animateIn, setAnimateIn] = useState(false)
  const [animateOut, setAnimateOut] = useState(false)
  const el = useRef(null)

  // these are to make sure we have a delay before hiding so we can animate out
  // and a slightly deferred animateIn so we can animate in
  useLayoutEffect(() => {
    if (visible) {
      setAnimateOut(false)
      setTimeout(() => setAnimateIn(true), 0)
    } else {
      setTimeout(() => setAnimateIn(false), 200)
      setTimeout(() => setAnimateOut(true), 0)
    }
  }, [visible])

  // close when clicking out
  useEffect(() => {
    function handler(event: any) {
      if (el.current && !(el.current as any).contains(event.target)) {
        setVisible(false)
      }
    }
    document.addEventListener('mousedown', handler)
    return () => document.removeEventListener('mousedown', handler)
  }, [el])

  // hijack the on change function to make sure we close the dropdown
  const newProps = { ...props }
  newProps.onChange = (changedId: any, newValue: boolean) => {
    setVisible(false)
    props.onChange?.(changedId, newValue)
  }

  return (
    <DropdownContainer
      ref={el}
      visible={visible || animateIn}
      animateIn={animateIn}
      animateOut={animateOut}
    >
      <button onClick={() => setVisible(!visible)}>
        {props.selectedId ??
          [...(props.selectedIds ?? ([] as any))][0] ??
          props.placeholder}
        <ChevronDown className="arrow" />
      </button>
      {(visible || animateIn) && (
        <div className="options-container">
          <OptionPicker {...newProps} />
        </div>
      )}
    </DropdownContainer>
  )
}
