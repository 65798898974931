import MediaContainer from '@components/MediaContainer'
import { getSubmissionKey } from '@queries/index'
import { numberShortener, relativeDate } from '@utilities/formatters'
import {
  AlertCircle,
  ArrowDown,
  ArrowUp,
  Link2,
  Lock,
  MessageSquare
} from 'react-feather'
import { useQueryClient } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { Submission } from 'snoowrap'
import styled from 'styled-components'

interface ContainerProps {
  selected: boolean
}

const ContainerPadding = 13
const Container = styled(Link)<ContainerProps>`
  display: flex;
  min-height: 100px;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.column.rowSeparatorColor};
  padding: ${ContainerPadding}px;
  box-sizing: border-box;
  text-decoration: none;
  color: ${props => props.theme.column.columnTextColor} !important;
  background-color: ${props =>
    props.selected
      ? props.theme.column.rowHighlightedBackgroundColor
      : 'transparent'};
  &:hover {
    background-color: ${props =>
      props.theme.column.rowHighlightedBackgroundColor};
  }
  *::selection {
    user-select: none;
  }
`

const Title = styled.span`
  word-wrap: anywhere;
  line-height: 1.3;
  font-weight: 400;
`

const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const PostMetadata = styled.div`
  margin-top: auto;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  color: ${props => props.theme.column.columnTextSecondaryColor};
  padding-top: 8px;
  & .right-side {
    & .comments svg,
    & .score svg {
      width: 13px;
      height: 13px;
      transform: translateY(2px);
    }
    display: flex;
    gap: 5px;
  }
`

const PillsContainer = styled.div``

const Pill = styled.div<any>`
  padding: 2px 3px;
  background-color: ${props => props.theme.pill.backgroundColor};
  border: 1px solid  ${props => props.theme.pill.borderColor};
  height: 10px;
  font-size: 9px;
  float: left;
  border-radius: 3px;
  margin-right: 4px;
  line-height: 10px;
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 4px;
    width: 10px;
    height: 10px;
  }
`

const NSFWPill = styled(Pill)`
  background-color: #ffd6d6;
  border-color: #ffb0be;
  color: #d95050;
`

const LockedPill = styled(Pill)`
  background-color: #d8493e;
  border-color: #c9443a;
  color: #ffffff;
`

const PinnedPill = styled(Pill)`
  background-color: #9bc696;
  border-color: #83ab7e;
  color: #ffffff;
`

interface SubmissionRowProps {
  submission: Submission
  selected: boolean
  columnID: string
  onLoadMedia?: () => void
  useColumnIDForPermalink?: boolean
  columnWidth: number
  showSubreddit?: boolean
  inSubmissionView: boolean
  sourceQuery?: string
}

function SubmissionRow({
  submission,
  selected,
  onLoadMedia,
  useColumnIDForPermalink,
  columnID,
  columnWidth,
  showSubreddit,
  inSubmissionView,
  sourceQuery
}: SubmissionRowProps) {
  const { id, created_utc } = submission

  const queryClient = useQueryClient()

  const submissionQuery = getSubmissionKey(id)

  const {
    permalink,
    title,
    author,
    num_comments,
    score,
    over_18,
    secure_media_embed,
    secure_media,
    preview,
    post_hint,
    url,
    is_self,
    locked,
    stickied,
    subreddit_name_prefixed: subredditNameWithBackslash
  }: Submission = queryClient.getQueryData(submissionQuery) ?? submission

  const { gallery_data } = { ...submission } as any

  const warmSubmissionCache = () => {
    queryClient.setQueryData(submissionQuery, submission)
    queryClient.invalidateQueries(submissionQuery)
  }

  return (
    <Container
      to={{
        pathname: useColumnIDForPermalink
          ? `/column/${columnID}${permalink}`
          : permalink,
        state: { sourceQuery }
      }}
      onClick={warmSubmissionCache}
      key={id}
      selected={selected}
    >
      <PostContainer>
        <Title>
          <PillsContainer>
            {showSubreddit && <Pill>{subredditNameWithBackslash}</Pill>}
            {stickied && (
              <PinnedPill>
                <AlertCircle /> NOTICE
              </PinnedPill>
            )}
            {(locked || false) && (
              <LockedPill>
                <Lock /> LOCKED
              </LockedPill>
            )}
            {over_18 && <NSFWPill noicon>NSFW</NSFWPill>}
            {gallery_data && <Pill noicon>GALLERY</Pill>}
            {post_hint === 'rich:video' && <Pill noicon>VIDEO</Pill>}
            {false && is_self && <Pill noicon>Text</Pill>}
            {post_hint === 'link' && (
              <Pill>
                <Link2 />
                LINK
              </Pill>
            )}
          </PillsContainer>
          {title}
        </Title>
        <MediaContainer
          secure_media_embed={secure_media_embed}
          secure_media={secure_media}
          preview={preview}
          post_hint={post_hint}
          url={url}
          previewWidth={columnWidth - 2 * ContainerPadding}
          previewHeight={220}
          style={{
            borderRadius: '6px',
            overflow: 'hidden'
          }}
          hideVideo
          onLoad={onLoadMedia}
          usePreview
          inSubmissionView={inSubmissionView}
        />
        <PostMetadata>
          <span>
            {relativeDate(new Date(created_utc * 1000))} by {author.name}
          </span>
          <span className="right-side">
            <div className="score">
              <ArrowUp />
              {numberShortener(score)}
              <ArrowDown />
            </div>
            <div className="comments">
              {numberShortener(num_comments)} <MessageSquare />
            </div>
          </span>
        </PostMetadata>
      </PostContainer>
    </Container>
  )
}

export default SubmissionRow
