import { ColumnProps } from '@components/Column'
import { ModalButton } from '@components/Modal'
import { useAuth } from '@contexts/AuthContext'
import { useSnoowrap } from '@contexts/SnoowrapContext'
import useDebounce from '@hooks/debounce'
import { ColumnType } from '@modals/AddColumn'
import { onlyUnique } from '@utilities/arrays'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { v4 as uuidV4 } from 'uuid'

const Container = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${props => props.theme.column.backgroundColor};
  box-sizing: border-box;
  padding: 8px;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin: 6px 0;

  & > h4 {
    margin: 4px 0;
  }
`

const Options = styled.select`
  outline: none;
  background: ${props => props.theme.global.inputBackgroundColor};
  color: ${props => props.theme.global.inputTextColor};
  border: 1px solid ${props => props.theme.global.inputBorderColor};
  font-size: 16px;
  padding: 8px;
  border-radius: 6px;
`

const TextInput = styled.input`
  outline: none;
  background: ${props => props.theme.global.inputBackgroundColor};
  color: ${props => props.theme.global.inputTextColor};
  border: 1px solid ${props => props.theme.global.inputBorderColor};
  font-size: 16px;
  padding: 8px;
  border-radius: 6px;
`

const SubredditSuggestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6px;
`

const SubredditSuggestion = styled.div<any>`
  border: 1px solid ${props => props.theme.column.rowSeparatorColor};
  background-color: ${props =>
    props.selected
      ? props.theme.sidebar.backgroundColor
      : props.theme.column.backgroundColor};
  color: ${props =>
    props.selected ? '#ffffff' : props.theme.global.textColor};
  font-size: 14px;
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 8px;

  &:hover {
    cursor: pointer;
  }
`

const SaveButton = styled(ModalButton)`
  width: 50%;
`

const CancelButton = styled.button`
  background: none;
  outline: 0;
  border: none;
  color: ${props => props.theme.global.textColor};
  width: 50%;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`

interface ManageColumnProps {
  columnID: string
  onDismiss: Function
}

function ManageColumn({ onDismiss, columnID }: ManageColumnProps) {
  const auth = useAuth()
  const snoowrap = useSnoowrap()

  const column = auth.columnManager.get(columnID)

  let titlePlaceholder: string | undefined
  let columnType: ColumnType = ColumnType.Subreddit
  let columnQuery: string | undefined
  if (column?.subreddit || column?.subreddits) {
    columnType = ColumnType.Subreddit
    titlePlaceholder = column?.subreddit ?? column?.subreddits?.join(', ')
    columnQuery = column?.subreddit
  } else if (column?.searchQuery) {
    columnType = ColumnType.Search
    titlePlaceholder = `Search: ${column?.searchQuery}`
    columnQuery = column?.searchQuery
  } else if (column?.user) {
    columnType = ColumnType.User
    titlePlaceholder = column?.user
    columnQuery = column?.user
  }

  let selectedSubs: string[] = []
  if (column && column.subreddits) {
    selectedSubs = column.subreddits
  } else if (column && column.subreddit) {
    selectedSubs = [column.subreddit]
  }

  const [type, setType] = useState<ColumnType>(columnType)
  const [title, setTitle] = useState(column?.customTitle)
  const [query, setQuery] = useState(columnQuery ?? '')
  const [selectedSubreddits, setSelectedSubreddits] =
    useState<string[]>(selectedSubs)
  const [subreddits, setSubreddits] = useState<string[]>(selectedSubs)
  const debouncedText = useDebounce(query, 500)

  useEffect(() => {
    if (type === ColumnType.Subreddit && debouncedText.trim('') !== '') {
      snoowrap
        .getClient()
        ?.searchSubredditNames({ query: debouncedText })
        .then(newSubreddits => {
          setSubreddits(
            selectedSubreddits.concat(newSubreddits).filter(onlyUnique)
          )
        })
    }
  }, [debouncedText, type, snoowrap])

  const getQuery = (type: ColumnType): string | undefined => {
    switch (type) {
      case ColumnType.Search:
        return column?.searchQuery
      case ColumnType.User:
        return column?.user
      case ColumnType.Subreddit:
        return column?.subreddit
    }
  }

  const getPlaceholderQuery = (): string | undefined => {
    switch (type) {
      case ColumnType.Search:
        return 'Enter a query...'
      case ColumnType.User:
        return 'Enter a user...'
      case ColumnType.Subreddit:
        return 'Search subreddits...'
    }
  }

  const onChangeType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newType = e.currentTarget.value as ColumnType
    setType(newType)
    setQuery(getQuery(newType) ?? '')
  }

  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.currentTarget.value)
  }

  const onChangeQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.currentTarget.value)
  }

  const onClickSave = () => {
    let newColumn: ColumnProps = {
      id: uuidV4(),
      customTitle: title
    }
    switch (type) {
      case ColumnType.Search:
        newColumn.searchQuery = query
        break
      case ColumnType.User:
        newColumn.user = query
        break
      case ColumnType.Subreddit:
        if (selectedSubreddits.length === 1) {
          newColumn.subreddit = selectedSubreddits[0]
        } else {
          newColumn.subreddits = selectedSubreddits
        }
        break
    }
    auth.columnManager.update(columnID, newColumn)

    onDismiss()
  }

  const onClickCancel = () => {
    onDismiss()
  }

  const onClickSubreddit = (subreddit: string) => () => {
    if (selectedSubreddits.includes(subreddit)) {
      setSelectedSubreddits(
        selectedSubreddits.filter(
          selectedSubreddit => selectedSubreddit !== subreddit
        )
      )
    } else {
      setSelectedSubreddits([...selectedSubreddits, subreddit])
    }
  }

  const getQueryHeader = (): string => {
    switch (type) {
      case ColumnType.Search:
        return 'Search Query'
      case ColumnType.User:
        return 'User'
      case ColumnType.Subreddit:
        return 'Subreddit(s)'
      default:
        return 'Query'
    }
  }

  const canSave = (): boolean => {
    if (type === ColumnType.Subreddit) {
      return selectedSubreddits.length > 0
    }
    return query.trim() !== ''
  }

  return (
    <Container>
      <Section>
        <h4>Type</h4>
        <Options value={type} onChange={onChangeType}>
          <option value={ColumnType.Subreddit}>{ColumnType.Subreddit}</option>
          <option value={ColumnType.User}>{ColumnType.User}</option>
          <option value={ColumnType.Search}>{ColumnType.Search}</option>
        </Options>
      </Section>
      <Section>
        <h4>Title</h4>
        <TextInput
          placeholder={titlePlaceholder}
          value={title}
          onChange={onChangeTitle}
        />
      </Section>
      <Section>
        <h4>{getQueryHeader()}</h4>
        <TextInput
          placeholder={getPlaceholderQuery()}
          value={query}
          onChange={onChangeQuery}
        />
        {type === ColumnType.Subreddit && (
          <SubredditSuggestionContainer>
            {subreddits.map(subreddit => (
              <SubredditSuggestion
                selected={selectedSubreddits.includes(subreddit)}
                onClick={onClickSubreddit(subreddit)}
              >
                {subreddit}
              </SubredditSuggestion>
            ))}
          </SubredditSuggestionContainer>
        )}
      </Section>
      <ActionContainer>
        <CancelButton onClick={onClickCancel}>Cancel</CancelButton>
        <SaveButton disabled={!canSave()} onClick={onClickSave}>
          Save
        </SaveButton>
      </ActionContainer>
    </Container>
  )
}

export default ManageColumn
