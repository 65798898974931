import {
  CommentsLoadingView,
  SubmissionCardView
} from '@components/SubmissionCard'
import { VirtualList, VirtualListHandle } from '@components/VirtualList'
import { useAuth } from '@contexts/AuthContext'
import { useSnoowrap } from '@contexts/SnoowrapContext'
import { NSFWSetting } from '@modals/Settings'
import { getColumnSubmissionsKey, getSubredditInfoKey } from '@queries/index'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { numberShortener } from '@utilities/formatters'
import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import Skeleton from 'react-loading-skeleton'
import { useLocation, useParams } from 'react-router-dom'
import { Listing, Submission, Subreddit } from 'snoowrap'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${props => props.theme.global.backgroundColor};
`

const StyledVirtualList = styled(VirtualList)`
  margin: 0;
  overflow: auto;
`

const SubmissionContainer = styled.div`
  margin-bottom: 5vh;
`

interface StreamProps {
  subreddit?: string
  user?: string
  columnID?: string
}

function Stream() {
  const snoowrap = useSnoowrap()
  const auth = useAuth()
  const location = useLocation()
  const { subreddit, columnID, user } = useParams<StreamProps>()

  const [sourceQuery, setSourceQuery] = useState<string | undefined>()
  useEffect(() => {
    let source = location.pathname.split('/').slice(0, 3).join('/')
    setSourceQuery(source)
  }, [])

  let query = subreddit ?? user ?? 'frontpage'

  // if we have an column id in the URL it is typically a multireddit
  // Ask Dima why if not sure why, (we dont support reddit's implementation of multireddits)
  if (columnID) {
    query = auth.columnManager.get(columnID)?.subreddits?.join('+') ?? ''
  }

  // get currently displayed listing
  let listing: Listing<Submission> | undefined
  const { fetchNextPage, isLoading, isFetching } = useInfiniteQuery<
    Listing<Submission>
  >(
    getColumnSubmissionsKey(query, 'hot'),
    ({ pageParam }) => {
      const client = snoowrap.getClient()!
      // paginate if we already have a listing
      if (pageParam && listing) {
        return listing.fetchMore({ amount: 25 })
      } else {
        if (query === 'frontpage') {
          return client.getBest()
        } if (!!user) {
          return client.getUser(query!).getSubmissions()
        } else {
          return client.getSubreddit(query!).getHot()
        }
      }
    },
    {
      getNextPageParam: lastPage => {
        // set listing with new submissions fetched
        listing = lastPage
        return !lastPage.isFinished
      },
      keepPreviousData: true,
      enabled: snoowrap.getClient() !== null,
      refetchOnWindowFocus: false,
      structuralSharing: false
    }
  )

  // grab general subreddit info
  const { data: subredditInfo } = useQuery<Subreddit>(
    getSubredditInfoKey(subreddit!),
    () => snoowrap.getClient()!.getSubreddit(subreddit!).fetch(),
    {
      enabled: snoowrap.getClient() !== null && !!subreddit,
      refetchOnWindowFocus: false
    }
  )

  const showSubreddit = !!columnID

  const virtualRef = useRef<VirtualListHandle>(null)

  if (isLoading || !listing) {
    return (
      <Container>
        <Helmet>
          <title>{query}</title>
        </Helmet>
        <LoadingView title={query} />
      </Container>
    )
  }

  return (
    <Container>
      <Helmet>
        <title>{query}</title>
      </Helmet>
      {!isLoading && listing && (
        <StyledVirtualList
          ref={virtualRef}
          rows={listing}
          hasMore={!listing.isFinished}
          loadMore={fetchNextPage}
          virtualListOverrides={{
            paddingStart: 64
          }}
          header={
            <StreamHeader>
              <StreamTitle>{query}</StreamTitle>
              {subredditInfo && (
                <>
                  {subredditInfo?.public_description_html && (
                    <Description
                      dangerouslySetInnerHTML={{
                        __html: subredditInfo.public_description_html
                      }}
                    />
                  )}
                  <OnlineUsers>
                    {subredditInfo?.accounts_active &&
                      numberShortener(subredditInfo.accounts_active, 1)}{' '}
                    users online
                  </OnlineUsers>
                  <Subscribers>
                    {subredditInfo?.accounts_active &&
                      numberShortener(subredditInfo.subscribers, 1)}{' '}
                    subscribers
                  </Subscribers>
                </>
              )}
            </StreamHeader>
          }
        >
          {({ virtualRow }) => {
            const submission = listing![virtualRow.index]

            // NSFW check
            // Should we move this to inside SubmissionCardView?
            if (
              auth.currentUser.settings.nsfwMode === NSFWSetting.Hide &&
              submission.over_18
            ) {
              return null
            }

            return (
              <SubmissionContainer key={submission.id}>
                <SubmissionCardView
                  submissionID={submission.id}
                  maxComments={2}
                  columnID={columnID}
                  showSubreddit={showSubreddit}
                  embedded={true}
                  showExit={false}
                  sourceQuery={sourceQuery}
                />
              </SubmissionContainer>
            )
          }}
        </StyledVirtualList>
      )}
    </Container>
  )
}

const StreamHeader = styled.div`
  width: clamp(40%, 90%, 1000px);
  margin: 0 auto;
  margin-bottom: 36px;
`

const StreamTitle = styled.h1`
  margin: 0;
`

const Description = styled.p``

const Subscribers = styled.span`
  font-size: 16px;
`

const OnlineUsers = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.modal.activeTagColor};
  position: relative;
  padding-left: 14px;
  margin-right: 8px;

  &:before {
    position: absolute;
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    left: 0;
    top: calc(50% - 4px);
    background-color: ${props => props.theme.modal.activeTagColor};
  }
`

const SkeletonContainer = styled.div`
  width: clamp(40%, 90%, 1000px);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  min-height: 30vh;
  background-color: ${props => props.theme.submissionView.backgroundColor};
  margin-left: auto;
  margin-right: auto;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 4px 6px;
  text-align: center;

  @media (max-width: 460px) {
    width: 100%;
  }
`

const SkeletonHeader = styled.div<any>`
  position: ${props => (props.sticky ? 'sticky' : 'relative')};
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 14px;
  box-shadow: rgba(0, 0, 0, ${props => (props.backgroundColor ? 0.03 : 0.07)})
    0px 4px 6px;
  color: ${props => props.theme.submissionView.headerTextColor} !important;
  background-color: ${props =>
    props.theme.submissionView.headerBackgroundColor};
  border-bottom: 1px solid
    ${props => props.theme.submissionView.headerBorderColor};
  border: 1px solid ${props => props.theme.submissionView.headerBorderColor};
  border-top-left-radius: ${props => (props.sticky ? 0 : 5)}px;
  border-top-right-radius: ${props => (props.sticky ? 0 : 5)}px;
  & a {
    color: inherit;
  }
`

const SkeletonCommentsContainer = styled.div`
  padding: 10px;
  padding-bottom: 1px;
  border-top: 1px solid
    ${props => props.theme.submissionView.commentsContainerBorderColor};
  flex-grow: 1;
  margin-top: 12px;
  text-align: left;
`

const SkeletonMedia = styled(Skeleton)`
  margin-top: 38px;
  margin-bottom: 23px;
  position: relative;
`

function SkeletonSubmissionCardView() {
  return (
    <SkeletonContainer>
      <SkeletonHeader>
        <Skeleton width="300px" />
      </SkeletonHeader>
      <SkeletonMedia width="600px" height="400px" />
      <SkeletonCommentsContainer>
        <CommentsLoadingView nested={false} count={2} />
      </SkeletonCommentsContainer>
    </SkeletonContainer>
  )
}

const LoadingContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  padding-top: 64px;
`

interface LoadingViewProps {
  title: string
}

function LoadingView({ title }: LoadingViewProps) {
  return (
    <LoadingContainer>
      <StreamHeader>
        <StreamTitle>{title}</StreamTitle>
      </StreamHeader>
      <SubmissionContainer>
        <SkeletonSubmissionCardView />
      </SubmissionContainer>
      <SubmissionContainer>
        <SkeletonSubmissionCardView />
      </SubmissionContainer>
      <SubmissionContainer>
        <SkeletonSubmissionCardView />
      </SubmissionContainer>
    </LoadingContainer>
  )
}

export default Stream
