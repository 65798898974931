import { useAuth } from '@contexts/AuthContext'
import React, { createContext, useContext, useMemo, useState } from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styled, {
  ThemeProvider as StyledThemedProvider
} from 'styled-components'
import themeDark from './themeDark.json'
import themeDefault from './themeDefault.json'

export const themes: { [key: string]: Theme } = {
  themeDefault,
  themeDark
}

type ThemeProps = { [key: string]: string | number | string[] }
interface Theme {
  name: String
  id: string
  global: ThemeProps
  sidebar: ThemeProps
  column: ThemeProps
  streamView: ThemeProps
  postView: ThemeProps
  settings: ThemeProps
  modal: ThemeProps
  misc: ThemeProps
  submissionView: ThemeProps
  toggle: ThemeProps
  dropdown: ThemeProps
  banner: ThemeProps
  notificationCenter: ThemeProps
  search: ThemeProps
  pill: ThemeProps
}

interface ThemeContextProps {
  theme: Theme
  setTheme: (theme: Theme) => void
}

const ThemeContext = createContext<ThemeContextProps>({} as ThemeContextProps)

export function useTheme() {
  return useContext(ThemeContext)
}

const ThemedGlobalContainer = styled.div`
  color: ${props => props.theme.global.textColor};

  a {
    color: ${props => props.theme.global.linkColor};
  }
  .hover-tooltip {
    position: relative;
  }
  .hover-tooltip::before {
    display: none;
    position: absolute;
    top: -20px;
    font-size: 10px;
    background-color: ${props => props.theme.global.tooltipBackgroundColor};
    color: ${props => props.theme.global.tooltipTextColor};
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 1px;
    padding-bottom: 2px;
    border-radius: 3px;
  }
  .hover-tooltip:hover::before {
    display: block;
  }
`

/**
 * The theme components only imports it's theme CSS-file. These components are lazy
 * loaded, to enable "code splitting" (in order to avoid the themes being bundled together)
 */
const DefaultTheme = React.lazy(() => import('./themes/DefaultTheme'))
const DarkTheme = React.lazy(() => import('./themes/DarkTheme'))

const ThemeSelector: React.FC = ({ children }) => {
  const theme = useTheme()

  return (
    <>
      {/* Conditionally render theme, based on the current client context */}
      <React.Suspense fallback={() => null}>
        {theme.theme.id === 'themeDark' && <DarkTheme />}
        {theme.theme.id === 'themeDefault' && <DefaultTheme />}
      </React.Suspense>
      {/* Render children immediately! */}
      {children}
    </>
  )
}

export function ThemeProvider({ children }: ReactChildrenProps) {
  const auth = useAuth()

  const [theme, setTheme] = useState<Theme>(
    auth.currentUser.settings.darkModeEnabled ? themeDark : themeDefault
  )
  const formattedTheme = useMemo(() => {
    const copy = JSON.parse(JSON.stringify(theme))
    if (auth.currentUser.settings.rainbowComments) {
      copy.submissionView.commentBackgroundColors =
        copy.submissionView.commentBackgroundColorsRainbow
    }
    return copy
  }, [theme, auth.currentUser.settings.rainbowComments])
  const context = { theme: formattedTheme, setTheme }
  return (
    <ThemeContext.Provider value={context}>
      <StyledThemedProvider theme={formattedTheme}>
        <ThemeSelector>
          <ThemedGlobalContainer style={theme.global}>
            <SkeletonTheme
              baseColor={theme.global.skeletonBaseColor as string}
              highlightColor={theme.global.skeletonHighlightColor as string}
            >
              {children}
            </SkeletonTheme>
          </ThemedGlobalContainer>
        </ThemeSelector>
      </StyledThemedProvider>
    </ThemeContext.Provider>
  )
}
