import { useTheme } from '@contexts/ThemeContext'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import styled from 'styled-components'

export const SidebarContainer = styled.div`
  position: absolute;
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${props => props.theme.column.backgroundColor};
  padding: 16px;
  z-index: 1;
  overflow: auto;
`

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    target?: string
  }
}

export const Sidebar = React.memo(function ({
  subreddit
}: {
  subreddit: string
}) {
  const { theme } = useTheme()
  const { data } = useQuery(['sidebar', subreddit], () =>
    fetch(`https://api.reditr.com/sync/?sidebar=${subreddit}`).then(response =>
      response.text()
    )
  )
  const style = `
  <style>
    document, body {
      font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
      padding: 5px;
      color: ${theme.global.textColor};
      ${Object.keys(theme.global)
        .map(prop => {
          const key = prop.replace(
            /([a-z0-9]+)([A-Z]{1})/g,
            (...props) => props[1] + '-' + props[2].toLowerCase()
          )
          return `${key}: ${theme.global[prop]}`
        })
        .join(';')};
      background-color: ${theme.column.backgroundColor};
    }
    a {
      font-weight: bold;
      color: ${theme.global.linkColor} !important;
    }
  </style>`
  const formattedData =
    style +
    (data || '')
      // strip reddit links
      .replace(
        /<a([^>]+)href=('|")(http|https):\/\/(www\.|np\.|old\.|beta\.|new\.|)reddit\.com/g,
        '<a$1href=$2'
      )
      // make external links open new windows and internal links use the app
      .replace(
        /<a([^>]+)href=(?<q>'|")([^"^']+)\k<q>/g,
        (_, space, quote, url) => {
          const external = url.startsWith('http')
          return (
            `<a${space}href=${quote}${url}${quote}` +
            (external
              ? ' target="_blank" rel="noopener noreferrer"'
              : 'target="_parent"')
          )
        }
      )
  return (
    <SidebarContainer
      dangerouslySetInnerHTML={{ __html: formattedData }}
    ></SidebarContainer>
  )
})
