import { useState, useEffect } from 'react'

// localstorage ttl setup

const currentDay = (): number => Math.floor((Date.now() - 1649218333115) / 86400000)

const data = JSON.parse(localStorage.useLocalStorage ?? null) ?? {
	forever: {},
	values: {}
}

function get(key: string): any {
	const pair = data.values[key]
	if (!pair) return
	return pair.value
}

function unset(key: string): any {
	const pair = data.values[key]
	if (!pair || !data[pair.key]) return
	delete data[pair.key][key]
	delete data.values[key]
	setNeedsRebuild()
}

function set(key: string, value: any, daysToLive: number = -1) {
  unset(key)
  const dayKey = daysToLive < 0 ? 'forever' : `${currentDay() + daysToLive}`
  if (!data[dayKey]) data[dayKey] = {}
  data[dayKey][key] = 1
  data.values[key] = { key: dayKey, value }
	setNeedsRebuild()
}

let hasPurgedOld = false
function purgeOld() {
	hasPurgedOld = true
	const today = currentDay()
	for (let key in data) {
		if (key === 'forever' || key === 'values' || Number(key) >= today) continue
    for (let subkey in data[key]) {
    	delete data.values[subkey]
    }
    delete data.values[key]
	}
}

let rebuildTimeout: ReturnType<typeof setTimeout>
function setNeedsRebuild() {
	clearTimeout(rebuildTimeout)
	rebuildTimeout = setTimeout(() => {
		if (!hasPurgedOld) purgeOld()
		localStorage.useLocalStorage = JSON.stringify(data)
	}, 100)
}

// react hookup

const listeners: { [key: string]: { [key: string]: Function } } = {}
let listenerIds = 0

function setValueFor(key: string, value: any, daysToLive: number = -1) {
	if (listeners[key]) {
		for (let subkey in listeners[key]) {
			listeners[key][subkey](value)
		}
	}
	set(key, value, daysToLive)
}

export default function useLocalStorage<Type>(key: string, defaultValue: Type, daysToLive: number = -1): [Type, (value: Type) => void] {
  const [value, setValue] = useState(get(key) ?? defaultValue)
  useEffect(() => {
    if (!listeners[key]) listeners[key] = {}
  	const id = listenerIds
    listenerIds += 1
  	listeners[key][id] = setValue
  	return () => {
  		delete listeners[key][id]
  	}
  }, [])
  return [value, (value: any) => setValueFor(key, value, daysToLive)]
}