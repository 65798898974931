import { ColumnProps } from '@components/Column'
import { NSFWSetting } from '@modals/Settings'
import { createContext, useContext, useReducer } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { RedditUser } from 'snoowrap'
import initialState from './initialState.json'

export interface AuthedUser {
  refreshKey: string
  redditAccount?: RedditUser
  settings: AppSettings
  columns?: ColumnProps[]
  hasSeenNUX: boolean
}

interface AppSettings {
  columnWidth: number
  rainbowComments: boolean
  isStreamMode: boolean
  nsfwMode: NSFWSetting
  darkModeEnabled: boolean
}

export const DefaultAppSettings = {
  columnWidth: 310,
  rainbowComments: false,
  isStreamMode: false,
  nsfwMode: NSFWSetting.Show,
  darkModeEnabled: true
}

interface AuthReducerState {
  users: AuthedUser[]
  currentUser: AuthedUser
}

interface AuthContext {
  users: AuthedUser[]
  currentUser: AuthedUser
  userManager: UserActions
  columnManager: ColumnActions
}

const Context = createContext<AuthContext>({} as AuthContext)

enum Direction {
  Left,
  Right
}

type Action =
  | { type: 'set-current-user'; user: AuthedUser }
  | { type: 'set-reddit-account'; redditAccount: RedditUser }
  | { type: 'add-user'; user: AuthedUser }
  | { type: 'logout' }
  | { type: 'remove', id: string }
  | { type: 'update-settings'; settings: AppSettings }
  | { type: 'add-column'; column: ColumnProps; prepend: boolean }
  | { type: 'remove-column'; columnID: string }
  | { type: 'rename-column'; columnID: string; title: string }
  | { type: 'move-column'; columnID: string; direction: Direction }
  | { type: 'merge-accounts'; accounts: Array<AuthedUser> }
  | { type: 'update-column'; columnID: string; newColumn: ColumnProps }

function authReducer(
  state: AuthReducerState,
  action: Action
): AuthReducerState {
  const oldStateString = JSON.stringify(state)
  let newState = JSON.parse(oldStateString) as AuthReducerState
  switch (action.type) {
    case 'set-current-user':
      newState.users = [
        ...newState.users.filter(
          user =>
            user.redditAccount?.name !==
            newState.currentUser.redditAccount?.name
        ),
        newState.currentUser
      ]
      newState.currentUser = action.user
      break
    case 'add-user':
      newState.users.push(action.user)
      newState.currentUser = action.user
      break
    case 'set-reddit-account':
      // this step should be called to update the current user's me.json information
      // (aka the redditAccount property on our user)
      if (newState.currentUser) {
        newState.currentUser.redditAccount = {
          ...newState.currentUser.redditAccount,
          ...action.redditAccount
        } as RedditUser
        newState.users = newState.users.filter(user => {
          return (
            user.redditAccount?.name !==
              newState.currentUser?.redditAccount?.name &&
            user.refreshKey !== newState.currentUser.refreshKey
          )
        })
        // if anon user set default name
        if (!newState.currentUser.redditAccount.name) {
          newState.currentUser.redditAccount.name = 'Anonymous'
        }
        newState.users.push(newState.currentUser)
      }
      break
    case 'remove':
      newState.users = newState.users.filter(user => {
        return (
          user.redditAccount?.id !== action.id
        )
      })
      break
    case 'logout':
      newState.users = newState.users.filter(user => {
        return (
          user.redditAccount?.name !== newState.currentUser?.redditAccount?.name
        )
      })
      if (newState.users.length > 0) {
        newState.currentUser = newState.users[0]
      } else {
        // TODO: Figure out how to fix type casting enums
        // for the JSON values (string => NSFWSetting)
        // This will also make detecting migration breaks harder
        // so would be nice to fix this asap
        newState = initialState as any
        localStorage.clear()
      }
      break
    case 'update-settings':
      if (newState.currentUser) {
        newState.currentUser.settings = action.settings
        newState.users = newState.users.filter(user => {
          return (
            user.redditAccount?.name !==
            newState.currentUser?.redditAccount?.name
          )
        })
        newState.users.push(newState.currentUser)
      }
      break
    case 'add-column':
      if (newState.currentUser && newState.currentUser.columns) {
        if (action.prepend) {
          newState.currentUser.columns = [action.column].concat(
            newState.currentUser.columns
          )
        } else {
          newState.currentUser?.columns?.push(action.column)
        }
      }
      break
    case 'remove-column':
      if (newState.currentUser) {
        newState.currentUser.columns = newState.currentUser?.columns?.filter(
          column => {
            return column.id !== action.columnID
          }
        )
      }
      break
    case 'rename-column':
      if (newState.currentUser?.columns) {
        const index = newState.currentUser.columns
          .map(column => column.id)
          .indexOf(action.columnID)
        if (!!index) {
          newState.currentUser.columns[index].customTitle = action.title
        }
      }
      break
    case 'update-column':
      if (newState.currentUser?.columns) {
        const index = newState.currentUser.columns
          .map(column => column.id)
          .indexOf(action.columnID)

        if (index > -1) {
          newState.currentUser.columns[index] = action.newColumn
        }
      }
      break
    case 'move-column':
      if (newState.currentUser?.columns) {
        const index = newState.currentUser.columns
          .map(column => column.id)
          .indexOf(action.columnID)

        // stay within bounds
        if (
          (action.direction === Direction.Left && index <= 0) ||
          (action.direction === Direction.Right &&
            index >= newState.currentUser.columns.length - 1)
        ) {
          break
        }

        // this performs the swapping, yes it looks pretty wild thanks to es6
        switch (action.direction) {
          case Direction.Left:
            ;[
              newState.currentUser.columns[index - 1],
              newState.currentUser.columns[index]
            ] = [
              newState.currentUser.columns[index],
              newState.currentUser.columns[index - 1]
            ]
            break
          case Direction.Right:
            ;[
              newState.currentUser.columns[index],
              newState.currentUser.columns[index + 1]
            ] = [
              newState.currentUser.columns[index + 1],
              newState.currentUser.columns[index]
            ]
            break
        }
      }
      console.error('WARNING - unhandled AuthContext case for move-colum')
      break
    case 'merge-accounts':
      // build map of existing accounts
      const accounts = newState.users.reduce((hash, user) => {
        if (user.redditAccount?.name === undefined) return hash
        hash[user.redditAccount.name.toLowerCase()] = user
        return hash
      }, {} as { [key: string]: AuthedUser })
      // for each new user...
      action.accounts.forEach(account => {
        if (!account.redditAccount?.name) return
        const existing = accounts[account.redditAccount.name.toLowerCase()]
        if (!existing) {
          // .. they didnt exist before so add them as a new account
          accounts[account.redditAccount.name.toLowerCase()] = account
        } else {
          // ... they did exist before so merge their columns with the existing account
          const existingColumns =
            existing.columns?.reduce((hash, column) => {
              hash[column.subreddit ?? ''] = true
              return hash
            }, {} as { [key: string]: boolean }) ?? {}
          account.columns
            ?.filter(col => !existingColumns[col.subreddit ?? ''])
            .forEach(column => existing.columns?.push(column))
        }
      })
      newState.users = Object.values(accounts)
      if (!newState.currentUser) newState.currentUser = newState.users[0]
      break
  }

  const newStateString = JSON.stringify(newState)
  return newStateString === oldStateString ? state : newState
}

class UserActions {
  private dispatch: Function
  private authState: AuthReducerState

  constructor(dispatch: Function, authState: AuthReducerState) {
    this.dispatch = dispatch
    this.authState = authState
  }

  setCurrentUser(user: AuthedUser): Promise<void> {
    return new Promise(resolve => {
      this.dispatch({ type: 'set-current-user', user })

      // do async stuff eventually
      resolve()
    })
  }

  updateSettings(settings: AppSettings) {
    this.dispatch({ type: 'update-settings', settings })
  }

  updateSetting(key: string, value: any) {
    let settings = { ...this.authState.currentUser.settings } as any
    settings[key] = value
    this.updateSettings(settings)
  }

  isAuthed(): boolean {
    return !!this.authState.currentUser.refreshKey.length
  }

  setRedditAccount(redditAccount: RedditUser) {
    this.dispatch({ type: 'set-reddit-account', redditAccount })
  }

  remove(id: string) {
    this.dispatch({ type: 'remove', id })
  }

  logout() {
    return new Promise(resolve => {
      this.dispatch({ type: 'logout' })

      // eventually do more async cleanup
      resolve(undefined)
    })
  }

  addUser(user: AuthedUser) {
    this.dispatch({ type: 'add-user', user })
  }

  mergeAccounts(accounts: Array<AuthedUser>) {
    this.dispatch({ type: 'merge-accounts', accounts })
  }
}

class ColumnActions {
  private dispatch: Function
  private authState: AuthReducerState

  constructor(dispatch: Function, authState: AuthReducerState) {
    this.dispatch = dispatch
    this.authState = authState
  }

  add(column: ColumnProps, prepend: boolean = false) {
    this.dispatch({ type: 'add-column', column, prepend })
  }

  rename(columnID: string, title: string) {
    this.dispatch({ type: 'rename-column', columnID, title })
  }

  remove(columnID: string) {
    this.dispatch({ type: 'remove-column', columnID })
  }

  move(columnID: string, direction: Direction) {
    this.dispatch({ type: 'move-column', columnID, direction })
  }

  get(columnID: string): ColumnProps | undefined {
    return this.authState.currentUser.columns?.find(
      column => column.id === columnID
    )
  }

  update(columnID: string, newColumn: ColumnProps) {
    this.dispatch({ type: 'update-column', columnID, newColumn })
  }
}

const startingData =
  JSON.parse(localStorage.getItem('auth') as string) ?? initialState

function AuthUserContextBuilder(): AuthContext {
  const [authState, dispatch] = useReducer(authReducer, startingData)
  localStorage.setItem('auth', JSON.stringify(authState))
  return {
    ...authState,
    userManager: new UserActions(dispatch, authState),
    columnManager: new ColumnActions(dispatch, authState)
  }
}

function useAuth(): AuthContext {
  return useContext(Context)
}

function AuthProvider({ children }: ReactChildrenProps) {
  const authContext = AuthUserContextBuilder()
  return <Context.Provider value={authContext}>{children}</Context.Provider>
}

function PrivateRoute({ children, ...rest }: RouteProps) {
  const auth = useAuth()
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return auth?.userManager.isAuthed() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }}
    />
  )
}

export { Direction, useAuth, AuthProvider, PrivateRoute }
