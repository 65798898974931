import Modal, {
  ErrorMsg,
  InputWithButton,
  MainHeader,
  Option,
  OptionHeader,
  SaveButton
} from '@components/Modal'
import OptionPicker from '@components/OptionPicker'
import Toggle from '@components/Toggle'
import { useApp } from '@contexts/AppContextProvider'
import { useAuth } from '@contexts/AuthContext'
import { LoginStatus, useServerSync } from '@contexts/ServerSyncContext'
import { themes, useTheme } from '@contexts/ThemeContext'
import { useMemo, useRef } from 'react'
import { Loader } from 'react-feather'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const Emoji = styled.span`
  font-size: 20px;
  opacity: 0.4;
`

function SyncSettings() {
  const serverSync = useServerSync()
  const { push } = useHistory()
  const emailRef = useRef(null)
  const app = useApp()
  const save = () => {
    if (!emailRef.current) return
    const email = (emailRef.current! as HTMLInputElement).value
    serverSync.loginManager.loginWithEmail(email)
  }
  const errorInvalidEmail =
    serverSync.loginStatus === LoginStatus.ErrorInvalidEmailAddress
  const emailLoading =
    serverSync.loginStatus === LoginStatus.LoginRequestLoading
  const emailSent = serverSync.loginStatus === LoginStatus.WaitingForEmail
  if (serverSync.loginStatus === LoginStatus.LoggedIn) {
    const changeHandler = (changedId: string, newValue: boolean) => {
      switch (changedId) {
        case 'logout':
          console.log('TODO')
          break
        case 'email':
          break
        case 'sync':
          push('/email-login')
          app.showSettings(false)
          break
      }
    }
    return (
      <OptionPicker
        options={[
          {
            text: serverSync.firebaseUser?.email ?? 'N/A',
            id: 'emailAddress',
            selectedSymbol: <Emoji>👤</Emoji>
          },
          {
            text: 'Sync V1 Data',
            id: 'sync',
            selectedSymbol: <Emoji>☁️</Emoji>
          },
          { text: 'Logout', id: 'logout', selectedSymbol: <Emoji>🚪</Emoji> }
        ]}
        onChange={changeHandler}
      />
    )
  }
  if (emailSent) {
    return (
      <OptionPicker
        options={[
          {
            text: 'Check Your Email!',
            id: '1',
            selectedSymbol: <Emoji>✉️</Emoji>
          }
        ]}
      />
    )
  }
  return (
    <div>
      {errorInvalidEmail && (
        <ErrorMsg>
          <Trans>Invalid email address!</Trans>
        </ErrorMsg>
      )}
      {!emailSent && (
        <InputWithButton>
          <input ref={emailRef} placeholder="Email Address" />
          <SaveButton onClick={save}>
            {emailLoading ? <Loader /> : 'Save'}
          </SaveButton>
        </InputWithButton>
      )}
    </div>
  )
}

const columnOptions = [
  { text: 'Thin', id: '240' },
  { text: 'Default', id: '310' },
  { text: 'Wide', id: '400' }
]

function ColumnSettings() {
  const auth = useAuth()
  const setColumnWidth = (changedId: string, newValue: boolean) => {
    if (auth.currentUser) {
      const width = Number(changedId)
      let settings = { ...auth.currentUser.settings }
      settings.columnWidth = width
      auth.userManager.updateSettings(settings)
    }
  }
  const selectedIds = useMemo(
    () => new Set(['' + (auth.currentUser?.settings.columnWidth ?? 0)]),
    [auth]
  )
  return (
    <Option>
      <OptionHeader>Column Size</OptionHeader>
      <OptionPicker
        options={columnOptions}
        selectedIds={selectedIds}
        onChange={setColumnWidth}
      />
    </Option>
  )
}

const themeOptions = [
  { text: 'Light', id: 'themeDefault' },
  { text: 'Dark', id: 'themeDark' }
]

export function ThemeSettings() {
  const { theme, setTheme } = useTheme()
  const auth = useAuth()

  const onThemeChange = (isDark: boolean) => {
    setTheme(isDark ? themes.themeDark : themes.themeDefault)
    auth.userManager.updateSetting('darkModeEnabled', isDark)
  }

  const onRainbowChange = (newValue: boolean) => {
    auth.userManager.updateSetting('rainbowComments', newValue)
  }

  return (
    <>
      <Option solo>
        <OptionHeader>Dark Mode</OptionHeader>
        <Toggle
          on={auth.currentUser.settings.darkModeEnabled}
          onChange={onThemeChange}
        />
      </Option>
      <Option solo>
        <OptionHeader>Rainbow Comments</OptionHeader>
        <Toggle
          on={auth.currentUser.settings.rainbowComments}
          onChange={onRainbowChange}
        />
      </Option>
    </>
  )
}

function AppSettings() {
  const auth = useAuth()

  const changeAppMode = (newValue: boolean) => {
    auth.userManager.updateSetting('isStreamMode', newValue)
  }

  return (
    <>
      <Option solo>
        <OptionHeader>Feed Mode</OptionHeader>
        <Toggle
          on={auth.currentUser.settings.isStreamMode}
          onChange={changeAppMode}
        />
      </Option>
    </>
  )
}

export enum NSFWSetting {
  Show = 'Show',
  Hide = 'Hide'
  // TODO: Add support to MediaContainer to selectively
  // show NSFW items
  //AlwaysShow = "Always Show"
}

export function NSFWMode() {
  const auth = useAuth()

  const setNSFWMode = (nsfw: NSFWSetting) => {
    auth.userManager.updateSetting('nsfwMode', nsfw)
  }

  const selectedIds = useMemo(
    () =>
      new Set(['' + (auth.currentUser?.settings.nsfwMode ?? NSFWSetting.Show)]),
    [auth]
  )
  return (
    <Option>
      <OptionHeader>NSFW Mode</OptionHeader>
      <OptionPicker
        options={Object.values(NSFWSetting)}
        selectedIds={selectedIds}
        onChange={setNSFWMode}
      />
    </Option>
  )
}

function Settings() {
  const app = useApp()
  const auth = useAuth()
  return (
    <Modal onClick={() => app.showSettings(false)} show={app.isShowingSettings}>
      <MainHeader>Settings</MainHeader>
      <AppSettings />
      <NSFWMode />
      {!auth.currentUser.settings.isStreamMode && <ColumnSettings />}
      <ThemeSettings />
      {/* <Option>
        <OptionHeader>Sync</OptionHeader>
        <SyncSettings />
      </Option> */}
      {/* <MainFooter>
      <MainFooterOption>
        <SettingsIcon />
        <Trans>General</Trans>
      </MainFooterOption>
      <MainFooterOption>
        <Command />
        <Trans>Shortcuts</Trans>
      </MainFooterOption>
      <MainFooterOption>
        <Filter />
        <Trans>Filters</Trans>
      </MainFooterOption>
      <MainFooterOption>
        <Info />
        <Trans>About</Trans>
      </MainFooterOption>
      <MainFooterOption>
        <Package />
        <Trans>Advanced</Trans>
      </MainFooterOption>
    </MainFooter> */}
    </Modal>
  )
}

export default Settings
