// @ts-nocheck
import { useVirtualizer, VirtualItem } from '@tanstack/react-virtual'
import React, {
  ReactNode,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import { Loader as Spinner } from 'react-feather'
import styled, { keyframes } from 'styled-components/macro'

const StyledContainer = styled.div`
  height: 100%;
  flex-grow: 1;

  & > ul {
    padding: 0;
    margin: 0;
  }
`

const StyledParent = styled.div`
  height: 100%;
  overflow: auto;
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`
const PageLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;

  & > svg {
    font-size: 24px;
    animation: ${rotate} 1s linear infinite;
  }
`

// TODO: How does this one work
// Copied from https://codesandbox.io/s/resizeobserver-react-virtual-6131c?file=/src/index.js
// Issue: https://github.com/tannerlinsley/react-virtual/issues/28
const ItemMeasurer = ({ children, measure, tagName, ...restProps }: any) => {
  const roRef = React.useRef(null)
  const elRef = React.useRef(null)

  const measureRef = React.useRef(measure)
  measureRef.current = measure

  const refSetter = React.useCallback(el => {
    const ro = roRef.current

    if (ro !== null && elRef.current !== null) {
      ro.unobserve(elRef.current)
    }

    elRef.current = el

    if (ro !== null && elRef.current !== null) {
      ro.observe(elRef.current)
    }
  }, [])

  React.useLayoutEffect(() => {
    const update = () => {
      measureRef.current(elRef.current)
    }

    // sync measure for initial render ?
    update()

    const ro = roRef.current ? roRef.current : new ResizeObserver(update)

    const el = elRef.current
    if (el !== null) {
      ro.observe(el)
    }
    roRef.current = ro

    return () => {
      ro.disconnect()
    }
  }, [])

  const Tag = tagName

  return (
    <Tag ref={refSetter} {...restProps}>
      {children}
    </Tag>
  )
}

interface VirtualListOverridesProps {
  paddingStart?: number
  paddingEnd?: number
}

type VirtualListProps = {
  className?: string
  children: (props: { virtualRow: VirtualItem }) => ReactNode
  hasMore?: boolean
  topMargin?: number
  rows: any[]
  testId?: string
  loadMore?: () => void
  virtualListOverrides?: VirtualListOverridesProps
  header?: ReactNode
}

export type VirtualListHandle = {
  measure: () => void
  scrollToRow: (index: number) => void
}

function Loader({ loadMore }: { loadMore: () => void }): JSX.Element {
  useEffect(() => {
    loadMore()
  }, [loadMore])

  return (
    <PageLoadingContainer key="page-loading">
      <Spinner />
    </PageLoadingContainer>
  )
}

export const VirtualList = React.forwardRef<
  VirtualListHandle,
  VirtualListProps
>(
  (
    {
      children,
      className,
      rows,
      hasMore = false,
      topMargin = 0,
      loadMore,
      virtualListOverrides,
      header
    },
    ref
  ): JSX.Element | null => {
    const parentRef = useRef<HTMLDivElement>(null)
    const [overscan, setOverscan] = useState<number>(2)
    const [scrollToRowIndex, setScrollToRowIndex] = useState<number | null>(
      null
    )

    const rowVirtualizer = useVirtualizer({
      count: hasMore ? rows.length + 1 : rows.length,
      getScrollElement: () => parentRef.current,
      overscan,
      estimateSize: () => 100,
      ...virtualListOverrides
    })
    const offsetYForIndex = (index: number) =>
      rowVirtualizer.getVirtualItems().find(row => row.index === index)?.start
    useImperativeHandle(ref, () => ({
      measure: () => {
        rowVirtualizer.measure()
      },
      scrollToRow: (index: number) => {
        const offsetY = offsetYForIndex(index)
        if (!offsetY && rows[index]) {
          setOverscan(index + 2) // need to enforce that the cell we're to scroll to is visible!
        }
        setScrollToRowIndex(index)
      }
    }))

    // if we're told to scroll to a cell, do it after we lay things out.
    useLayoutEffect(() => {
      if (scrollToRowIndex === null || scrollToRowIndex === undefined) return
      rowVirtualizer.scrollToIndex(scrollToRowIndex, { align: 'start' })
    }, [scrollToRowIndex])

    if (!rows.length) return null

    return (
      <StyledContainer className={className}>
        <StyledParent ref={parentRef}>
          <div
            style={{
              width: '100%',
              height: `${rowVirtualizer.getTotalSize()}px`,
              position: 'relative'
            }}
          >
            {rowVirtualizer.getVirtualItems().map(virtualRow => (
              <ItemMeasurer
                tagName="div"
                key={virtualRow.index}
                measure={virtualRow.measureElement}
                style={{
                  width: '100%',
                  top: topMargin + 'px',
                  left: 0,
                  position: 'absolute',
                  transform: `translateY(${virtualRow.start}px)`
                }}
              >
                {rows.length === virtualRow.index && loadMore ? (
                  <Loader loadMore={loadMore} />
                ) : (
                  <>
                    {virtualRow.index === 0 && header}
                    {children({ virtualRow })}
                  </>
                )}
              </ItemMeasurer>
            ))}
          </div>
        </StyledParent>
      </StyledContainer>
    )
  }
)
