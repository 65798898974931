import { VirtualList, VirtualListHandle } from '@components/VirtualList'
import { DefaultAppSettings, Direction, useAuth } from '@contexts/AuthContext'
import { useSnoowrap } from '@contexts/SnoowrapContext'
import { NSFWSetting } from '@modals/Settings'
import { getColumnSubmissionsKey, getSubredditInfoKey } from '@queries/index'
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import blendColors from '@utilities/blendColors'
import { useLayoutEffect, useRef, useState } from 'react'
import {
  ArrowLeft,
  ChevronLeft,
  ChevronRight,
  Edit,
  Layout,
  Menu,
  Trash,
  X
} from 'react-feather'
import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router'
import { Listing, Submission, Subreddit } from 'snoowrap'
import styled from 'styled-components'
import ManageColumn from './components/ManageColumn'
import { Sidebar } from './components/Sidebar'
import SubmissionRow from './components/SubmissionRow'

interface ContainerProps {
  isSubmissionView: boolean | null
  columnWidth?: number
}

const StyledVirtualList = styled(VirtualList)`
  margin: 0;
  overflow: auto;
  border-top: none;
  border-left: none;
`

const Container = styled.div<ContainerProps>`
  width: ${props =>
    props.columnWidth
      ? `${props.columnWidth}px`
      : DefaultAppSettings.columnWidth + 'px'};
  flex-shrink: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-right: ${props => (props.isSubmissionView ? '0' : '6px')};
  background-color: ${props => props.theme.column.backgroundColor};
  box-shadow: rgba(0, 0, 0, 0.07) 0px 4px 6px;
  position: relative;
`

interface HeaderProps {
  backgroundColor: string | null
}

const Header = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  box-sizing: border-box;
  font-weight: 600;
  z-index: 2;
  height: 50px;
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
  color: ${props =>
    props.backgroundColor
      ? blendColors(
          props.backgroundColor,
          props.theme.column.columnHeaderTextColor,
          0.85
        )
      : props.theme.column.columnHeaderTextColor};
  position: relative;
  &:after {
    transition: background-color 0.3s, flex-grow 1s;
    background-color: ${props =>
      props.backgroundColor
        ? blendColors(
            props.backgroundColor,
            props.theme.column.columnHeaderBackgroundColor
          )
        : props.theme.column.columnHeaderBackgroundColor};
    border-bottom: 1px solid
      ${props =>
        props.backgroundColor
          ? blendColors(
              props.backgroundColor,
              props.theme.column.columnHeaderBackgroundColor,
              0.85
            )
          : props.theme.column.columnHeaderBackgroundColor};
    box-shadow: rgba(0, 0, 0, 0.06) 0px 7px 15px;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
`

const MenuIconContainer = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  &:hover,
  .showMenu & {
    background-color: ${props =>
      props.backgroundColor
        ? blendColors(
            props.backgroundColor,
            props.theme.column.settingsButtonHoverBackgroundColor,
            0.85
          )
        : props.theme.column.settingsButtonHoverBackgroundColor};
  }
  & svg {
    transition: transform 200ms;
    transition-delay: 200ms;
  }
  .showingMenu & svg {
    transform: scale(0);
    transition-delay: 0ms;
  }
  .showingMenu & {
    z-index: 0;
  }
`

const ColumnIcon = styled.img`
  position: absolute;
  width: 18px;
  height: 18px;
  object-fit: contain;
  border-radius: 50% 50%;
  margin-right: 6px;
  transition: all 200ms;
  transition-delay: 200ms;
  .showingMenu & {
    transform: scale(0);
    opacity: 0;
    margin-right: 0;
    transition-delay: 0;
  }
`

enum ColumnSortType {
  Hot = 'hot',
  Top = 'top',
  Rising = 'rising',
  New = 'new',
  Controversial = 'controversial'
}

export interface ColumnProps {
  id: string
  customTitle?: string
  subreddit?: string
  searchQuery?: string
  user?: string
  activeSubmissionID?: string | null
  inSubmissionView?: boolean
  subreddits?: string[]
}

export function Column({
  id,
  subreddit,
  searchQuery,
  user,
  activeSubmissionID,
  inSubmissionView,
  subreddits,
  customTitle
}: ColumnProps) {
  const snoowrap = useSnoowrap()
  const history = useHistory()
  const auth = useAuth()
  const queryClient = useQueryClient()

  const [sort, setSort] = useState(ColumnSortType.Hot)
  const [showSidebar, setShowSidebar] = useState(false)
  const [showEditOptions, setShowEditOptions] = useState(false)

  const onClickHeader = () => {
    // TODO: Add support for using a multireddit url
    if (subreddits) {
      history.push(`/column/${id}`)
    } else if (subreddit) {
      history.push(`/r/${subreddit}`)
    }
  }

  const title =
    subreddit ?? subreddits?.join(',') ?? user ?? `Search: ${searchQuery}` ?? ''

  const columnQueryKey = getColumnSubmissionsKey(title, sort)

  let headerOptions: ColumnHeaderProps = {
    id,
    title: customTitle ?? title,
    onClick: onClickHeader,
    hideControls: inSubmissionView ?? false,
    backgroundColor: null,
    onSortChange: sort => {
      setSort(sort)
      queryClient.invalidateQueries(columnQueryKey)
    },
    subreddit,
    setShowSidebar,
    showSidebar,
    setShowEditOptions
  }

  // grab general subreddit info
  const { data: subredditInfo } = useQuery<Subreddit>(
    getSubredditInfoKey(subreddit!),
    () => snoowrap.getClient()!.getSubreddit(subreddit!).fetch(),
    {
      enabled: snoowrap.getClient() !== null && !!subreddit,
      refetchOnWindowFocus: false
    }
  )

  if (subredditInfo) {
    headerOptions.title = customTitle ?? subredditInfo.display_name
    headerOptions.iconURL = subredditInfo.community_icon
    headerOptions.backgroundColor = subredditInfo.primary_color
  }

  // get currently displayed listing
  let listing: Listing<Submission> | undefined
  const { fetchNextPage, isLoading, isFetching } = useInfiniteQuery<
    Listing<Submission>
  >(
    columnQueryKey,
    ({ pageParam }) => {
      const client = snoowrap.getClient()!

      // paginate if we already have a listing
      if (pageParam && listing) {
        return listing.fetchMore({ amount: 25 })
      } else if (subreddit || subreddits) {
        const subredditQuery = subreddit ?? subreddits?.join('+') ?? ''
        switch (sort) {
          case ColumnSortType.Hot:
            return client.getSubreddit(subredditQuery).getHot()
          case ColumnSortType.New:
            return client.getSubreddit(subredditQuery).getNew()
          case ColumnSortType.Controversial:
            return client.getSubreddit(subredditQuery).getControversial()
          case ColumnSortType.Rising:
            return client.getSubreddit(subredditQuery).getRising()
          case ColumnSortType.Top:
            return client.getSubreddit(subredditQuery).getTop()
        }
      } else if (searchQuery) {
        return client.search({ query: searchQuery ?? '' })
      } else {
        return client.getUser(user!).getSubmissions() // TODO: add support for comments
      }
    },
    {
      getNextPageParam: lastPage => {
        // set listing with new submissions fetched
        listing = lastPage
        return !lastPage.isFinished
      },
      keepPreviousData: true,
      enabled: snoowrap.getClient() !== null,
      refetchOnWindowFocus: false,
      structuralSharing: false
    }
  )

  const virtualRef = useRef<VirtualListHandle>(null)

  // if in submission mode, try and make sure the selected post is visible
  useLayoutEffect(() => {
    if (!inSubmissionView || !activeSubmissionID) return
    const rowIndex =
      listing?.findIndex(row => row.id === activeSubmissionID) ?? -1
    if (rowIndex !== -1) {
      setTimeout(() => virtualRef.current?.scrollToRow(rowIndex), 0)
    }
  }, [inSubmissionView, activeSubmissionID, listing])

  const showSubreddit = !!subreddits || !!searchQuery || !!user

  return (
    <Container
      isSubmissionView={inSubmissionView || null}
      columnWidth={auth.currentUser?.settings.columnWidth}
    >
      <ColumnHeader {...headerOptions} />
      {showSidebar && subreddit && <Sidebar subreddit={subreddit} />}
      {isFetching && !listing && <ColumnLoadingView />}
      {!isLoading && listing && (
        <StyledVirtualList
          ref={virtualRef}
          rows={listing}
          hasMore={!listing.isFinished}
          loadMore={fetchNextPage}
        >
          {({ virtualRow }) => {
            const submission = listing![virtualRow.index]

            // NSFW check
            if (
              auth.currentUser.settings.nsfwMode === NSFWSetting.Hide &&
              submission.over_18
            ) {
              return null
            }

            return (
              <SubmissionRow
                columnWidth={auth.currentUser?.settings.columnWidth}
                key={submission.id}
                selected={submission.id === activeSubmissionID}
                submission={submission}
                useColumnIDForPermalink={!!subreddits}
                columnID={id}
                showSubreddit={showSubreddit}
                inSubmissionView={false}
              />
            )
          }}
        </StyledVirtualList>
      )}
      {showEditOptions && (
        <ManageColumn
          columnID={id}
          onDismiss={() => setShowEditOptions(false)}
        />
      )}
    </Container>
  )
}

const SkeletonColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const SkeletonContainer = styled.div`
  padding: 16px;
  min-height: 80px;
  border-bottom: 1px solid ${props => props.theme.column.rowSeparatorColor};
`

const SkeletonMedia = styled(Skeleton)`
  height: 120px;
  margin-top: 20px;
`

function ColumnLoadingView() {
  return (
    <SkeletonColumn>
      {Array(10)
        .fill(null)
        .map((value, index) => (
          <SkeletonContainer key={index}>
            <Skeleton />
            <Skeleton width="50%" />
            <SkeletonMedia />
          </SkeletonContainer>
        ))}
    </SkeletonColumn>
  )
}

interface ColumnHeaderProps {
  id: string
  title: string
  iconURL?: string
  onClick?: () => void
  hideControls: boolean
  backgroundColor: string | null
  onSortChange: (sort: ColumnSortType) => void
  subreddit?: string
  setShowSidebar: Function
  showSidebar: boolean
  setShowEditOptions: Function
}

const Button = styled.div<any>`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  &:hover {
    background-color: ${props =>
      props.backgroundColor
        ? blendColors(
            props.backgroundColor,
            props.theme.column.settingsButtonHoverBackgroundColor,
            0.85
          )
        : props.theme.column.settingsButtonHoverBackgroundColor};
  }
  & > i {
    color: ${props => props.theme.global.textColor};
    font-size: 14px;
    font-weight: bolder;
  }
`

const ButtonLeft = styled(Button)`
  position: absolute;
  left: -15px;
  top: 0;
  bottom: 0;
  width: 50px;
  opacity: 0;
  transition: left 200ms, opacity 200ms;
  transition-delay: 0;
  .showingMenu & {
    left: 0;
    opacity: 1;
    transition-delay: 200ms;
  }
`

const ButtonRight = styled(Button)`
  position: absolute;
  right: -15px;
  top: 0;
  bottom: 0;
  width: 50px;
  opacity: 0;
  transition: right 200ms, opacity 200ms;
  transition-delay: 0;
  .showingMenu & {
    transition-delay: 200ms;
    right: 0;
    opacity: 1;
    z-index: 2;
  }
`

const LeftOfTitle = styled.div<any>`
  min-width: ${p => (p.offsetForIcon ? 27 : 0)}px;
  transition: flex-grow 400ms;
  flex-grow: 0;
  .showingMenu & {
    transition: flex-grow 700ms;
    flex-grow: 0.5;
  }
`

const RightOfTitle = styled.div`
  transition: flex-grow 400ms;
  flex-grow: 0;
  transition-delay: 0ms;
  .showingMenu & {
    transition: flex-grow 700ms;
    transition-delay: 0;
    flex-grow: 0.5;
  }
`

const Title = styled.div`
  flex: 1;
  max-width: calc(100% - 25px);
  overflow: hidden;
  text-overflow: ellipsis;
`

const MenuOptions = styled.div<HeaderProps>`
  position: absolute;
  left: 0;
  right: 0;
  top: -200px;
  z-index: -2;
  opacity: 0;
  transition: all 400ms;
  box-shadow: inset rgba(0, 0, 0, 0.06) 0px -7px 15px;
  background-color: ${props =>
    props.backgroundColor
      ? blendColors(
          props.backgroundColor,
          props.theme.column.columnHeaderBackgroundColor
        )
      : props.theme.column.columnHeaderBackgroundColor};
  border-bottom: 1px solid
    ${props =>
      props.backgroundColor
        ? blendColors(
            props.backgroundColor,
            props.theme.column.columnHeaderBackgroundColor,
            0.85
          )
        : props.theme.column.columnHeaderBackgroundColor};
  .showingMenu & {
    top: 50px;
    opacity: 1;
    transition-delay: 100ms;
    padding-top: 10px;
    padding-bottom: 5px;
  }
  & > div,
  & > button {
    display: flex;
    text-align: left;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: normal;
    text-align: left;
    font-size: ${p => p.theme.defaultFontSize};
    position: relative;
    &:first-of-type:after {
      display: none;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 10px;
      right: 10px;
      height: 1px;
      background-color: ${props =>
        props.backgroundColor
          ? blendColors(
              props.backgroundColor,
              props.theme.column.columnHeaderBorderColor
            )
          : props.theme.column.columnHeaderBorderColor};
    }
  }
  & > div > span {
    flex: 2;
  }
  & > div > svg {
    width: 17px;
  }
  & select {
    border-radius: 5px;
    border: 1px solid
      ${props =>
        props.backgroundColor
          ? blendColors(
              props.backgroundColor,
              props.theme.column.columnHeaderTextColor,
              0.8
            )
          : props.theme.column.columnHeaderTextColor};
  }
`

function ColumnHeader({
  id,
  title,
  iconURL,
  onClick,
  hideControls,
  onSortChange,
  backgroundColor,
  subreddit,
  setShowSidebar,
  showSidebar,
  setShowEditOptions
}: ColumnHeaderProps) {
  const auth = useAuth()

  const [sort, setSort] = useState(ColumnSortType.Hot)
  const [showMenu, setShowMenu] = useState(false)

  const onRemove: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void = e => {
    e.stopPropagation()

    if (window.confirm('Are you sure want to remove the column?')) {
      auth.columnManager.remove(id)
    }
  }

  const move =
    (direction: Direction) =>
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation()
      auth.columnManager.move(id, direction)
    }

  return (
    <Header
      backgroundColor={backgroundColor}
      className={showMenu && !showSidebar ? 'showingMenu' : ''}
    >
      {iconURL && <ColumnIcon src={iconURL} />}
      <LeftOfTitle offsetForIcon={iconURL != null && iconURL.length > 0}>
        {!hideControls && (
          <ButtonLeft
            backgroundColor={backgroundColor}
            onClick={move(Direction.Left)}
          >
            <ChevronLeft />
          </ButtonLeft>
        )}
      </LeftOfTitle>
      <Title onClick={onClick}>{title}</Title>
      <RightOfTitle>
        {!hideControls && (
          <ButtonRight
            backgroundColor={backgroundColor}
            onClick={move(Direction.Right)}
          >
            <ChevronRight />
          </ButtonRight>
        )}
      </RightOfTitle>
      {!hideControls && (
        <MenuIconContainer
          backgroundColor={backgroundColor}
          onClick={() => setShowMenu(true)}
          className="menu"
        >
          {showSidebar ? (
            <ArrowLeft onClick={() => setShowSidebar(false)} />
          ) : (
            <Menu />
          )}
        </MenuIconContainer>
      )}
      {!hideControls && (
        <MenuOptions backgroundColor={backgroundColor}>
          <div>
            <span>Column sort</span>
            <select
              value={sort}
              onChange={e => {
                const newSort = e.target.value as ColumnSortType
                setSort(newSort)
                onSortChange(newSort)
              }}
              onClick={e => [e.stopPropagation()]}
            >
              {Object.values(ColumnSortType).map(type => (
                <option key={type}>{type}</option>
              ))}
            </select>
          </div>
          {subreddit && (
            <Button
              backgroundColor={backgroundColor}
              onClick={() => setShowSidebar(true)}
            >
              <span>View sidebar</span>
              <Layout />
            </Button>
          )}
          <Button
            backgroundColor={backgroundColor}
            onClick={() => {
              setShowEditOptions(true)
              setShowMenu(false)
            }}
          >
            <span>Edit Column</span>
            <Edit />
          </Button>
          <Button backgroundColor={backgroundColor} onClick={onRemove}>
            <span>Delete column</span>
            <Trash />
          </Button>
          <Button
            backgroundColor={backgroundColor}
            onClick={() => setShowMenu(false)}
          >
            <span>Close menu</span>
            <X />
          </Button>
        </MenuOptions>
      )}
    </Header>
  )
}
