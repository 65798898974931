interface OAuthUser {
  refreshKey: string
}

interface RedditAuthResponse {
  refreshkey?: string
  status?: string
  name?: string
}

class OAuth {
  async start(oAuthWin: Window) {
    const identification = await fetch(
      'https://reditr.com/api/sync/?oauth='
    ).then(r => r.text())

    oAuthWin.location.href = 'https://reditr.com/api/sync/?oauth=' + identification

    return new Promise((resolve: (user: RedditAuthResponse) => void) => {
      // interval to check when window is closed so we can perform more actions
      const checkLoginInterval = setInterval(() => {
        // if window is closed, hit up reditr.com to see if oauth was successful
        if (
          !oAuthWin ||
          oAuthWin.closed ||
          typeof oAuthWin.closed == 'undefined'
        ) {
          clearInterval(checkLoginInterval)
          this.complete(identification).then(resolve)
        }
      }, 500)
    })
  }

  complete(identification: string) {
    return fetch(
      `https://reditr.com/api/sync/?oauth=${identification}&revive=true`
    ).then(r => r.json())
  }

  getAccessToken(user: OAuthUser) {
    return new Promise((resolve, reject) => {
      if (!user.refreshKey) {
        reject()
        return
      }

      fetch(
        `https://reditr.com/api/sync/?getAccessToken&oauth=${user.refreshKey}`
      )
        .then(r => r.json())
        .then(data => {
          resolve(data.accesstoken)
        })
    })
  }
}

export default new OAuth()
