import Modal, { ModalButton } from '@components/Modal'
import { SyntheticEvent, useState } from 'react'
import { CornerDownLeft } from 'react-feather'
import { PrivateMessage, ReplyableContent, Submission, Comment } from 'snoowrap'
import styled from 'styled-components'

const StyledTextArea = styled.textarea`
  width: 100%;
  min-height: 200px;
  border: none;
  font-size: 18px;
  resize: vertical;
  padding: 8px;
  box-sizing: border-box;
  outline: none;
  margin-top: 24px;
  margin-bottom: 24px;
  border-radius: 8px;
`

// no idea why "| any" is required here!!!
export type ReportModalDismissType = (
  e?: SyntheticEvent | any,
  newComment?: ReplyableContent<Comment | Submission | PrivateMessage>
) => void

interface ReportModalProps {
  content: ReplyableContent<Comment | Submission | PrivateMessage>
  dismiss: ReportModalDismissType
}

export function ReportModal({ content, dismiss }: ReportModalProps) {
  const [text, setText] = useState('')

  const onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value)
  }

  const onClickReport = () => {
    content.report({ reason: text }).then(content => {
      dismiss(null, content)
    })
  }
  return (
    <Modal show={true} onClick={dismiss}>
      <StyledTextArea
        placeholder="What is your reason?"
        value={text}
        onChange={onTextChange}
      />
      <ModalButton onClick={onClickReport}>
        Report <CornerDownLeft size={16} strokeWidth={2.5} />
      </ModalButton>
    </Modal>
  )
}
