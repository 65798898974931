import AuthedOnly from '@components/AuthedOnly'
import Modal from '@components/Modal'
import { useApp } from '@contexts/AppContextProvider'
import { useAuth } from '@contexts/AuthContext'
import { useSnoowrap } from '@contexts/SnoowrapContext'
import logo from '@images/logo.png'
import ManageUsers from '@modals/ManageUsers'
import { notificationCountKey } from '@queries/index'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import {
  Activity,
  AlertOctagon,
  Bell,
  Box,
  Globe,
  HelpCircle,
  LogOut,
  PlusSquare,
  Search,
  Send,
  Settings
} from 'react-feather'
import { Link, useHistory } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'

const Container = styled.div`
  background-color: ${p => p.theme.sidebar.backgroundColor};
  width: 60px;
  height: 100vh;
  flex-shrink: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: ${p => p.theme.sidebar.iconInactiveColor};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  z-index: 10;
  z-index: 99999999;
  svg {
    width: 16px;
    height: 16px;
  }
  a,
  a:visited,
  a:hover,
  a:active {
    color: inherit;
  }
  .nav-options > *,
  .account-options > a {
    justify-content: center;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 12px;
    text-align: center;
    background-color: ${props => props.theme.sidebar.iconBackgroundColor};
    margin-bottom: 10px;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    &:hover {
      background-color: ${props =>
        props.theme.sidebar.iconHoverBackgroundColor};
      color: white;
    }
  }

  .account-options {
    margin-top: auto;
  }
`

const LogoLink = styled(Link)`
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  margin-bottom: 15px;
  margin-top: 5px;

  & > img {
    width: 100%;
    object-fit: contain;
  }
`

const BaseButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #efefef;
  }

  & > i {
    font-size: 32px;
  }
`

const RedBorder = styled.div`
  border-radius: 50%;
  border: 2px solid red;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BaseLink = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  position: relative;

  &:hover {
    color: #efefef;
  }

  & > i {
    font-size: 32px;
  }
`

interface NavItemProps {
  text: string
}

const NavItem = styled.div<NavItemProps>`
  width: 100%;
  display: flex;
  position: relative;

  &:hover {
    &:after {
      content: '${props => props.text}';
      left: 100%;
      position: absolute;
      background: ${props => props.theme.dropdown.backgroundColor};
      color: ${props => props.theme.dropdown.color};
      height: 18px;
      top: calc(50% - 14px);
      padding: 6px;
      border-radius: 4px;
      right: -110px;
      margin-left: 8px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
    }
  }
`

const LogoutLink = styled(Link)`
  width: 100%;
  font-size: 32px;
  box-sizing: border-box;
  padding: 12px;
  text-align: center;
`

const Avatar = styled.div`
  width: 100%;
  padding: 12px;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;

  & > img {
    width: 100%;
    border-radius: 6px;
  }
`

const redPurpleFadeAvatar = keyframes`
 0% { background-color: #8a5ea0; border 2px solid #624a70; }
 50% { background-color: #dd5252; border 2px solid #b54141; }
 100% { background-color: #8a5ea0; border 2px solid #624a70; }
`

const AvatarWoosh = keyframes`
 0% { transform: rotate(0deg); }
 25% { transform: rotate(0deg); }
 75% { transform: rotate(400deg); }
 78% { transform: rotate(365deg); }
 100% { transform: rotate(365deg); }
`

export const FakeAvatar = styled.div`
  background-color: #8a5ea0;
  border 2px solid #624a70;
  width: 35px;
  height: 35px;
  border-radius: 35px;
  color: #ffbfbf;
  position: relative;
  animation-name: ${redPurpleFadeAvatar};
  animation-duration: 60s;
  animation-iteration-count: infinite;
  &::before {
    content: "👨🏼‍🦳";
    text-align: center;
    left: 0;
    right: 0;
    top: 2px;
    bottom: 0;
    position: absolute;
    font-size: 25px;
  }
  &:hover {
    background-color: #ed8a12;
    border: 2px solid #ba7323;
    animation-name: none;
  }
  &:hover::before {
    animation-name: ${AvatarWoosh};
    animation-timing-function: ease-in-out;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
`

function Sidebar() {
  const app = useApp()
  const auth = useAuth()
  const snoowrap = useSnoowrap()

  const [showManageUsers, setShowManageUsers] = useState(false)
  const [showHelp, setShowHelp] = useState(false)

  const onClickCreatePost = () => {
    app.showCreatePost(true)
  }

  const onClickSettings = () => {
    app.showSettings(true)
  }

  const onClickAddColumn = () => {
    app.showAddColumn(true)
  }

  const onClickAvatar = () => {
    setShowManageUsers(true)
  }

  const onClickHelp = () => {
    setShowHelp(true)
  }

  const avatarUrl = auth.currentUser?.redditAccount?.icon_img
  const { data: messages } = useQuery(notificationCountKey, () => {
    return snoowrap.getClient()?.getUnreadMessages()
  })

  const unreadCount = messages ? messages.length : 0

  return (
    <Container>
      <LogoLink to="/">
        <img
          alt="Reditr Logo, small alien space ship in the shape of a saucer"
          src={logo}
        />
      </LogoLink>
      <div className="nav-options">
        <AuthedOnly>
          <NavItem text="Notifications">
            <BaseLink to="/notifications">
              {unreadCount === 0 ? (
                <Bell />
              ) : (
                <RedBorder>{unreadCount}</RedBorder>
              )}
            </BaseLink>
          </NavItem>
        </AuthedOnly>
        <NavItem text="r/all">
          <BaseLink to="/r/all">
            <Globe />
          </BaseLink>
        </NavItem>
        <NavItem text="r/popular">
          <BaseLink to="/r/popular">
            <Activity />
          </BaseLink>
        </NavItem>
        <NavItem text="Search">
          <BaseLink to="/search">
            <Search />
          </BaseLink>
        </NavItem>
        {!auth.currentUser.settings.isStreamMode && (
          <NavItem text="Add Column">
            <BaseButton onClick={onClickAddColumn}>
              <PlusSquare />
            </BaseButton>
          </NavItem>
        )}
        <AuthedOnly>
          <NavItem text="Create Post">
            <BaseButton onClick={onClickCreatePost}>
              <Send />
            </BaseButton>
          </NavItem>
        </AuthedOnly>
        <NavItem text="Settings">
          <BaseButton onClick={onClickSettings}>
            <Settings />
          </BaseButton>
        </NavItem>
        <NavItem text="Help">
          <BaseButton onClick={onClickHelp}>
            <HelpCircle />
          </BaseButton>
        </NavItem>
      </div>
      <div className="account-options">
        {auth.currentUser.redditAccount && (
          <>
            <NavItem text="Accounts">
              <Avatar>
                {avatarUrl ? (
                  <img
                    alt="User Avatar"
                    src={avatarUrl}
                    onClick={onClickAvatar}
                  />
                ) : (
                  <FakeAvatar onClick={onClickAvatar} />
                )}
              </Avatar>
            </NavItem>
            <NavItem text="Logout">
              <LogoutLink to="/logout">
                <LogOut />
              </LogoutLink>
            </NavItem>
          </>
        )}
      </div>
      <ManageUsers
        onDismiss={() => setShowManageUsers(false)}
        show={showManageUsers}
      />
      <HelpModal isShowing={showHelp} onDismiss={() => setShowHelp(false)} />
    </Container>
  )
}

interface HelpModalProps {
  onDismiss: () => void
  isShowing: boolean
}

const HelpContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 20px;
`

const HelpOption = styled.div`
  background: ${props => props.theme.modal.modalButtonBackgroundColor};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  flex-grow: 1;
  width: 50%;
  color: ${props => props.theme.global.textColor};
  padding: 36px;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.modal.modalButtonHoverBackgroundColor};
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  & > h3 {
    margin: 0;
    margin-top: 16px;
  }

  & > svg {
    height: 48px;
    width: 48px;
  }
`

function HelpModal({ onDismiss, isShowing }: HelpModalProps) {
  const history = useHistory()

  const onClickBackground = (e: any) => {
    if (e.target.id === 'background') {
      onDismiss()
    }
  }

  const onClickFeedback = () => {
    onDismiss()
    history.push({
      search: '?action=create-post&type=feedback'
    })
  }

  const onClickBug = () => {
    onDismiss()
    history.push({
      search: '?action=create-post&type=bug'
    })
  }

  return (
    <Modal show={isShowing} id="background" onClick={onClickBackground}>
      <HelpContainer>
        <HelpOption onClick={onClickFeedback}>
          <Box size={48} />
          <h3>Feature Request</h3>
        </HelpOption>
        <HelpOption onClick={onClickBug}>
          <AlertOctagon size={48} />
          <h3>Report Bug</h3>
        </HelpOption>
      </HelpContainer>
    </Modal>
  )
}

export default Sidebar
