import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// en-US
import commonEN from '@locales/en/common.json'

// fr
import commonFR from '@locales/fr/common.json'

const resources = {
  en: {
    common: commonEN
  },
  fr: {
    common: commonFR
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .init({
    resources,
    // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage

    // Set default namespace
    defaultNS: 'common',

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
