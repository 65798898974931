import { ModalButton } from '@components/Modal'
import { useAuth } from '@contexts/AuthContext'
import { useSnoowrap } from '@contexts/SnoowrapContext'
import logo from '@images/logo.png'
import { useState } from 'react'
import styled from 'styled-components'
import { v4 as uuidV4 } from 'uuid'
import * as Fathom from 'fathom-client'
import events from '@analytics/events'

const ModalTitle = styled.h1`
  text-align: center;
  color: ${props => props.theme.global.textColor};
`

const Note = styled.div`
  font-size: 15px;
  text-align: center;
  margin: 16px 0;
  color: ${props => props.theme.global.textColor};
`

const SubscribeButton = styled(ModalButton)`
  width: 60%;
  font-size: 18px;
  margin: 0 auto;
  background-color: ${props => props.theme.global.inputBackgroundColor};
  border: 1px solid ${props => props.theme.global.inputBorderColor};
  color: ${props => props.theme.global.textColor};

  & > img {
    width: 36px;
    margin-right: 12px;
  }

  &:disabled {
    background-color: ${props => props.theme.modal.activeTagBackgroundColor};
    border: 1px solid ${props => props.theme.modal.activeTagColor};
  }
`

function SubscribeToReditr() {
  const auth = useAuth()
  const snoowrap = useSnoowrap()
  const [subbed, setSubbed] = useState(false)

  const onSubscribe = () => {
    setSubbed(true)
    snoowrap.getClient()?.getSubreddit('reditr').subscribe().then()
    auth.columnManager.add(
      {
        id: uuidV4(),
        subreddit: 'reditr'
      },
      true
    )
    Fathom.trackGoal(events.subbedToReditrFromNUX, 0)
  }

  return (
    <>
      <ModalTitle>Follow Us!</ModalTitle>
      <SubscribeButton onClick={onSubscribe} disabled={subbed}>
        <img src={logo} />
        {subbed ? 'Subscribed!' : 'Subscribe to /r/reditr'}
      </SubscribeButton>
      <Note>
        Subscribe to our subreddit to get the latest news about the app!
      </Note>
    </>
  )
}

export default SubscribeToReditr
