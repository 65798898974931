import styled from 'styled-components'

const ModalTitle = styled.h1`
  text-align: center;
  color: ${props => props.theme.global.textColor};
`

const ModalIconLarge = styled.div`
  font-size: 90px;
  text-align: center;
`

const Paragraph = styled.p`
  text-align: center;
  font-size: 14px;
  color: ${props => props.theme.global.textColor};
`

function Welcome() {
  return (
    <>
      <ModalTitle>Welcome to the new Reditr!</ModalTitle>
      <ModalIconLarge>🎉</ModalIconLarge>
      <Paragraph>
        We built Reditr in 2012 hoping to bring a brand new experience to using
        Reddit and over the following few years we spent time refining the
        experience to a point where we were supporting almost +400k users!
      </Paragraph>
      <Paragraph>
        To celebrate our 10 year anniversary, we decided to rebuild Reditr using
        the latest tech available to provide a more modern experience with
        better performance, new features and an overall better experience.
      </Paragraph>
      <Paragraph>
        If you have any requests please submit them to our subreddit /r/reditr.
        More features are coming and we hope you enjoy Reditr 2.0!
      </Paragraph>
    </>
  )
}

export default Welcome
