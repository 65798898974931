import styled, { keyframes } from 'styled-components'
import { useBanner, Message, BannerType } from '@contexts/BannerContext'
import { useState } from 'react'
import { XCircle } from 'react-feather'
import { useTheme } from '@contexts/ThemeContext'

interface BannerContainerProps {
  textColor: string
  backgroundColor: string
  isMounted: boolean
}

const slideIn = keyframes`
  from {
    bottom: -100px;
    opacity: 0;
  }

  to {
    bottom: 14px;
    opacity: 1;
  }
`

const slideOut = keyframes`
  from {
    bottom: 14px;
    opacity: 1;
  }

  to {
    bottom: -100px;
    opacity: 0;
  }
`

const BannerContainer = styled.div<BannerContainerProps>`
  display: flex;
  align-items: center;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  z-index: 9999;
  width: 300px;;
  padding: 16px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  left: calc(50% - 120px);
  user-select: none;
  margin-right: 8px;
  animation: ${props => (props.isMounted ? slideIn : slideOut)} 0.5s ease-in
    forwards;
`

const Text = styled.span`
  padding-right: 16px;
`

interface CloseButtonProps {
  color: string
}

const CloseButton = styled.div<CloseButtonProps>`
  cursor: pointer;
  color: ${props => props.color};
  margin-left: auto;
  font-size: 12px;
  font-weight: bold;
`

interface BannerProps {
  banner: Message
}

let closeBannerTimeout: number | undefined

function Banner({ banner }: BannerProps) {
  const { id, message, type, timeout } = banner
  const [mounted, setMounted] = useState(true)
  const { theme } = useTheme()

  closeBannerTimeout = window.setTimeout(() => setMounted(false), 0.9 * timeout)

  const bannerContext = useBanner()
  const closeBanner = () => {
    clearTimeout(closeBannerTimeout)
    setMounted(false)
    setTimeout(() => bannerContext.closeBanner(id), 500)
  }

  var textColor = '#000000'
  var backgroundColor = '#eeeeee'
  switch (type) {
    case BannerType.warning:
      textColor = theme.banner.warningTextColor as string
      backgroundColor = theme.banner.warningBackgroundColor as string
      break
    case BannerType.error:
      textColor = theme.banner.errorTextColor as string
      backgroundColor = theme.banner.errorBackgroundColor as string
      break
    case BannerType.success:
      textColor = theme.banner.successTextColor as string
      backgroundColor = theme.banner.successBackgroundColor as string
      break
    default:
      break
  }

  return (
    <BannerContainer
      isMounted={mounted}
      textColor={textColor}
      backgroundColor={backgroundColor}
    >
      <Text>{message}</Text>
      <CloseButton onClick={closeBanner} color={textColor}>
        <XCircle size={16} />
      </CloseButton>
    </BannerContainer>
  )
}

export default Banner
