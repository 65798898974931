import Banner from '@components/Banner'
import EmailLogin from '@components/EmailLogin'
import RedditBanner from '@components/RedditBanner'
import Sidebar from '@components/Sidebar'
import {
  AppClient,
  AppContextProvider,
  useApp
} from '@contexts/AppContextProvider'
import { useAuth } from '@contexts/AuthContext'
import { BannerContext } from '@contexts/BannerContext'
import AddColumn from '@modals/AddColumn'
import CreatePost from '@modals/CreatePost'
import NUX from '@modals/NUX'
import Settings from '@modals/Settings'
import UserProfile from '@modals/UserProfile'
import Home from '@screens/Home'
import Login from '@screens/Login'
import Logout from '@screens/Logout'
import NotificationCenter from '@screens/NotificationCenter'
import PrivateMessageViewer from '@screens/PrivateMessageViewer'
import Search from '@screens/Search'
import Stream from '@screens/Stream'
import SubmissionView from '@screens/SubmissionView'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { addHotkey } from '@utilities/hotkeys'
import * as Fathom from 'fathom-client'
import { useEffect } from 'react'
import { isBrowser } from 'react-device-detect'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation
} from 'react-router-dom'
import styled from 'styled-components'

const queryClient = new QueryClient()

const RootContainer = styled.div`
  overflow: hidden;
  height: 100vh;
`

const MainContainer = styled.div`
  display: flex;
  height: 100%;
`

const ContentContainer = styled.div`
  overflow: hidden;
  position: relative;
  flex-grow: 1;
`

function createHotkeys(app: AppClient) {
  addHotkey('cmd+g,ctrl+g', () => {
    app.showSettings(true)
  })

  addHotkey('cmd+y,ctrl+y', () => {
    app.showCreatePost(true)
  })
}

function Routes() {
  const auth = useAuth()
  const app = useApp()
  const location = useLocation()

  useEffect(() => {
    createHotkeys(app)
  }, [])

  useEffect(() => {
    Fathom.trackPageview()
  }, [location])

  return (
    <>
      <Route path="/">
        {auth.currentUser.settings.isStreamMode ? <Stream /> : <Home />}
      </Route>
      <Switch>
        <Route path="/r/:subreddit">
          <Stream />
        </Route>
        <Route path="/u/:user">
          <Stream />
        </Route>
        <Route path="/user/:user">
          <Stream />
        </Route>
        <Route path="/column/:columnID">
          <Stream />
        </Route>
      </Switch>
      <Switch>
        {/* Regular columns */}
        <Route path="/r/:subreddit/comments/:id/:title/:focusedCommentID">
          <SubmissionView />
        </Route>
        <Route path="/r/:subreddit/comments/:id">
          <SubmissionView />
        </Route>

        {/* Custom columns */}
        <Route path="/column/:columnID/r/:subreddit/comments/:id/:title/:focusedCommentID">
          <SubmissionView />
        </Route>
        <Route path="/column/:columnID/r/:subreddit/comments/:id">
          <SubmissionView />
        </Route>
      </Switch>
      <Route path="/notifications">
        <NotificationCenter />
      </Route>
      <Route path="/message/:id">
        <PrivateMessageViewer />
      </Route>
      <Route path="/search">
        <Search />
      </Route>
    </>
  )
}

function App() {
  Fathom.load('JDSANAZQ')

  return (
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        {/* {shouldExtractLegacyData() && <LegacyLocalStorageExtractor />} */}
        <Router>
          <RootContainer>
            <Switch>
              {/* Account Management */}
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/logout" component={Logout} />
              <Route path="/email-login" component={EmailLogin} />
              {/* Main App View/Routes */}
              <Route path="/">
                <MainContainer>
                  {isBrowser && <Sidebar />}
                  <ContentContainer>
                    <Routes />
                  </ContentContainer>
                </MainContainer>
              </Route>
            </Switch>
          </RootContainer>

          {/* Modals */}
          <CreatePost />
          <Settings />
          <AddColumn />
          <NUX />
          <UserProfile />

          {/* Reddit is Down Banner */}
          <RedditBanner />
          <BannerContext.Consumer>
            {context =>
              context.currentBanner && <Banner banner={context.currentBanner} />
            }
          </BannerContext.Consumer>
        </Router>
      </AppContextProvider>
    </QueryClientProvider>
  )
}

export default App
