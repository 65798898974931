import Modal, { ModalButton } from '@components/Modal'
import { useAuth } from '@contexts/AuthContext'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import NSFW from './components/NSFW'
import StreamMode from './components/StreamMode'
import Theme from './components/Theme'
import Welcome from './components/Welcome'
import SubscribeToReditr from './components/SubscribeToReditr'

const AuthedNUXSteps = [
  {
    component: Welcome,
    buttonTitle: 'Get Started'
  },
  {
    component: Theme,
    buttonTitle: 'Next'
  },
  {
    component: StreamMode,
    buttonTitle: 'Next'
  },
  {
    component: NSFW,
    buttonTitle: 'Next'
  },
  {
    component: SubscribeToReditr,
    buttonTitle: 'Start Browsing!'
  }
]

const AnonNUXSteps = [
  {
    component: Welcome,
    buttonTitle: 'Get Started'
  },
  {
    component: Theme,
    buttonTitle: 'Next'
  },
  {
    component: StreamMode,
    buttonTitle: 'Next'
  },
  {
    component: NSFW,
    buttonTitle: 'Done'
  }
]

// NUX = New User Exprience
function NUX() {
  const auth = useAuth()
  const location = useLocation()

  const NUXSteps = auth.userManager.isAuthed() ? AuthedNUXSteps : AnonNUXSteps

  const [nuxStepIndex, setNUXStepIndex] = useState(0)

  const onClickNext = () => {
    if (nuxStepIndex < NUXSteps.length - 1) {
      setNUXStepIndex(nuxStepIndex + 1)
    } else if (nuxStepIndex === NUXSteps.length - 1) {
      auth.userManager.setCurrentUser({ ...auth.currentUser, hasSeenNUX: true })
      setNUXStepIndex(0)
    }
  }

  const onClickBackground = (e: any) => {
    if (e.target.id === 'background') {
      auth.userManager.setCurrentUser({ ...auth.currentUser, hasSeenNUX: true })
      setNUXStepIndex(1)
    }
  }

  const step = NUXSteps[nuxStepIndex]

  return (
    <Modal
      show={!auth.currentUser.hasSeenNUX && location.pathname === '/'}
      id="background"
      onClick={onClickBackground}
    >
      <step.component />
      <ModalButton onClick={onClickNext}>{step.buttonTitle}</ModalButton>
    </Modal>
  )
}

export default NUX
