import { ColumnProps } from '@components/Column'
import Modal from '@components/Modal'
import { useAuth } from '@contexts/AuthContext'
import { getSubredditInfoKey } from '@queries/index'
import { useQueryClient } from '@tanstack/react-query'
import blendColors from '@utilities/blendColors'
import { useState } from 'react'
import { Subreddit } from 'snoowrap'
import styled from 'styled-components'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
`

const ColumnsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;
  gap: 8px;
  max-height: 600px;
  overflow: auto;
  margin-top: 8px;

  @media (max-width: 480px) {
    left: 50%;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`

const ColumnItem = styled.div<any>`
  background-color: ${props =>
    props.backgroundColor
      ? blendColors(
          props.backgroundColor,
          props.theme.column.columnHeaderBackgroundColor
        )
      : props.theme.column.columnHeaderBackgroundColor};
  border: 1px solid
    ${props =>
      props.backgroundColor
        ? blendColors(
            props.backgroundColor,
            props.theme.column.columnHeaderBackgroundColor,
            0.85
          )
        : props.theme.column.columnHeaderBackgroundColor};
  color: ${props => props.theme.column.columnHeaderTextColor};
  border-radius: 6px;
  padding: 8px;
  text-align: center;
  font-weight: 600;
  text-overflow: ellipsis;

  & > span {
    overflow: hidden;
    width: 100%;
    display: block;
    text-overflow: ellipsis;
  }

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.global.textColor};
  }
`

const SearchInput = styled.input`
  outline: none;
  background: ${props => props.theme.global.inputBackgroundColor};
  color: ${props => props.theme.global.inputTextColor};
  border: 1px solid ${props => props.theme.global.inputBorderColor};
  font-size: 16px;
  padding: 8px;
  border-radius: 6px;
  width: 100%;
  box-sizing: border-box;
`

interface GoToColumnModalProps {
  show: boolean
  onDismiss: () => void
  onSelectColumn: (index: number) => void
}

function GoToColumnModal({
  onDismiss,
  show,
  onSelectColumn
}: GoToColumnModalProps) {
  const auth = useAuth()
  const queryClient = useQueryClient()
  const columns = (auth.currentUser.columns ?? []).map((column, index) => {
    return { index, column }
  })

  const [searchInput, setSearchInput] = useState('')

  const onClickBackground = (e: any) => {
    if (e.target.id === 'background') {
      onDismiss()
    }
  }

  const renderColumn =
    (index: number) =>
    ({
      subreddit,
      subreddits,
      user,
      searchQuery,
      customTitle,
      id
    }: ColumnProps) => {
      const title =
        customTitle ??
        subreddit ??
        subreddits?.join(',') ??
        user ??
        `Search: ${searchQuery}` ??
        ''

      let backgroundColor = null
      if (subreddit) {
        const subredditInfo: Subreddit | undefined = queryClient.getQueryData(
          getSubredditInfoKey(subreddit)
        )
        if (subredditInfo) {
          backgroundColor = subredditInfo.primary_color
        }
      }

      const onClickColumn = () => {
        onSelectColumn(index)
        setSearchInput('')
        onDismiss()
      }

      return (
        <ColumnItem
          title={title}
          backgroundColor={backgroundColor}
          onClick={onClickColumn}
          key={id}
        >
          <span>{title}</span>
        </ColumnItem>
      )
    }

  const filterColumn = ({
    subreddit,
    subreddits,
    user,
    searchQuery,
    customTitle
  }: ColumnProps) => {
    const title =
      customTitle ??
      subreddit ??
      subreddits?.join(',') ??
      user ??
      `Search: ${searchQuery}` ??
      ''
    if (searchInput.trim() === '') return true
    return title.toLowerCase().indexOf(searchInput.toLocaleLowerCase()) > -1
  }

  const filteredColumns = columns.filter(({ column }) => filterColumn(column))

  return (
    <Modal show={show} id="background" onClick={onClickBackground}>
      <ContentContainer>
        <SearchInput
          autoFocus
          placeholder="Search columns..."
          value={searchInput}
          onChange={e => setSearchInput(e.currentTarget.value)}
          onKeyUp={e => {
            if (e.key === 'Enter' && filteredColumns.length > 0) {
              onSelectColumn(filteredColumns[0].index)
              setSearchInput('')
              onDismiss()
            }
          }}
        />
        <ColumnsContainer>
          {filteredColumns.map(({ column, index }) =>
            renderColumn(index)(column)
          )}
        </ColumnsContainer>
      </ContentContainer>
    </Modal>
  )
}

export default GoToColumnModal
